@font-face {
    font-family: "72";
    src: url(../../fonts/72-Regular.woff2) format("woff2"),url(../../fonts/72-Regular.woff) format("woff");
    font-weight: 400;
    font-style: normal
}

@font-face {
    font-family: "72";
    src: url(../../fonts/72-Italic.woff2) format("woff2"),url(../../fonts/72-Italic.woff) format("woff");
    font-weight: 400;
    font-style: italic
}

@font-face {
    font-family: "72";
    src: url(../../fonts/72-Light.woff2) format("woff2"),url(../../fonts/72-Light.woff) format("woff");
    font-weight: 300;
    font-style: normal
}

@font-face {
    font-family: "72";
    src: url(../../fonts/72-Semibold.woff2) format("woff2"),url(../../fonts/72-Semibold.woff) format("woff");
    font-weight: 600;
    font-style: normal
}

@font-face {
    font-family: "72";
    src: url(../../fonts/72-Bold.woff2) format("woff2"),url(../../fonts/72-Bold.woff) format("woff");
    font-weight: 700;
    font-style: normal
}

@font-face {
    font-family: "72";
    src: url(../../fonts/72-BoldItalic.woff2) format("woff2"),url(../../fonts/72-BoldItalic.woff) format("woff");
    font-weight: 700;
    font-style: italic
}

$font-standard: "'72', 'Helvetica Neue', Helvetica, Arial, #{$font-sans-serif}";

// variables from npm package
:root {
  --font-serif: #{$font-serif};
  --font-sans-serif: #{$font-sans-serif};
  --font-standard: #{$font-standard};
  --qualtrics-icons: 'qualtrics-icons', 'engagor-icons', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  --font-monospace: #{$font-monospace};
  --font-icons: 'qualtrics-icons', 'engagor-icons', 'Helvetica Neue', Helvetica, Arial, sans-serif;;

  /* optional fonts */
  --font-verdana: #{$font-verdana};
  --font-tahoma: #{$font-tahoma};
  --font-garamond: #{$font-garamond};
  --font-georgia: #{$font-georgia};
  --font-times-new-roman: #{$font-times-new-roman};
  --font-trebuchet: #{$font-trebuchet};

  /* new font sizes */
  --font-size-0_675rem: 0.675rem; // 10px
  --font-size-0_75rem: 0.75rem; // 12px
  --font-size-0_875rem: 0.875rem; // 14px
  --font-size-default: 1rem; // 16px
  --font-size-1_125rem: 1.125rem; // 18px
  --font-size-1_25rem: 1.25rem; // 20px
  --font-size-1_375rem: 1.375rem; // 22px
  --font-size-1_5rem: 1.5rem; // 24px
  --font-size-1_675rem: 1.675rem; // 26px
  --font-size-1_75rem: 1.75rem; // 28px
  --font-size-1_875rem: 1.875rem; // 30px
  --font-size-2rem: 2rem; // 32px
  --font-size-2_125rem: 2.125rem; // 34px
  --font-size-2_25rem: 2.25rem; // 36px
  --font-size-2_375rem: 2.375rem; // 38px
  --font-size-2_5rem: 2.5rem; // 40px
  --font-size-3rem: 3rem; // 48px
  --font-size-3_125rem: 3.125rem; // 50px

}
/* Override unified-ui classes */
.clarabridge-icons,
.font-icons {
  font-family: var(--font-icons) !important;
}



/* font sizes */

$basic-sizes: (var(--font-size-0_675rem), var(--font-size-0_75rem), var(--font-size-0_875rem), var(--font-size-default), var(--font-size-1_125rem));

$small-modifier: .65em;


@each $size in $basic-sizes {
  .font-size-#{index($basic-sizes, $size)} { font-size:$size !important; }
}

$font-size-1: nth($basic-sizes, 1);
$font-size-2: nth($basic-sizes, 2);
$font-size-3: nth($basic-sizes, 3);
$font-size-4: nth($basic-sizes, 4);
$font-size-5: nth($basic-sizes, 5);

$display-fonts: (
  sm: ( font-size: var(--font-size-1_5rem), font-weight:400, letter-spacing: 0.5px), // 24
  md: ( font-size: var(--font-size-1_875rem), font-weight:300, letter-spacing: 0.25px), // 30
  lg: ( font-size: var(--font-size-2_375rem), font-weight:400, letter-spacing: 0), // 38
  xl: ( font-size: var(--font-size-3_125rem), font-weight:600, letter-spacing: 0) // 50
);


@each $size, $unused in $display-fonts {
  .display-#{$size} {
    font-size: map-get(map-get($display-fonts, $size), font-size);
    font-weight: map-get(map-get($display-fonts, $size), font-weight);
    letter-spacing: map-get(map-get($display-fonts, $size), letter-spacing);
  }
}

$display-sm-size: map-get(map-get($display-fonts, sm), font-size);
$display-sm-weight: map-get(map-get($display-fonts, sm), font-weight);
$display-sm-spacing: map-get(map-get($display-fonts, sm), letter-spacing);

$display-md-size: map-get(map-get($display-fonts, md), font-size);
$display-md-weight: map-get(map-get($display-fonts, md), font-weight);
$display-md-spacing: map-get(map-get($display-fonts, md), letter-spacing);

$display-lg-size: map-get(map-get($display-fonts, lg), font-size);
$display-lg-weight: map-get(map-get($display-fonts, lg), font-weight);
$display-lg-spacing: map-get(map-get($display-fonts, lg), letter-spacing);

$display-xl-size: map-get(map-get($display-fonts, xl), font-size);
$display-xl-weight: map-get(map-get($display-fonts, xl), font-weight);
$display-xl-spacing: map-get(map-get($display-fonts, xl), letter-spacing);



$header-fonts: (
  h1: ( font-size: var(--font-size-1_5rem), font-weight:$display-sm-weight, letter-spacing: $display-sm-spacing),
  h2: ( font-size: var(--font-size-1_125rem), font-weight:400, letter-spacing: 0.3px),
  h3: ( font-size: var(--font-size-default), font-weight:700, letter-spacing: .3px),
  h4: ( font-size: var(--font-size-0_75rem), font-weight:500, letter-spacing: .3px),
  h5: ( font-size: var(--font-size-0_875rem), font-weight:400, letter-spacing: 1px),
);

%small-override {
    color: inherit;
    font-size: $small-modifier;
    font-weight:inherit;
    line-height: inherit;
}
small, .small { @extend %small-override; }

@each $size, $trash in $header-fonts {
  #{$size}, .#{$size} {
    font-size: map-get(map-get($header-fonts, $size), font-size);
    font-weight: map-get(map-get($header-fonts, $size), font-weight);
    letter-spacing: map-get(map-get($header-fonts, $size), letter-spacing);
    small, .small { @extend %small-override; }
  }
}


$default-font-size: var(--font-size-default);
$default-line-height: 1.5;
$default-font-weight: 400;
$subheading-size: $font-size-2;
$caption-size: $font-size-1;


.default-font { font-size: var(--default-font-size); }




$dropdown-item-target-height: 32px;
$dropdown-line-height: 16px;
$dropdown-searchbox-font-size: var(--input-font-size);
$dropdown-menu-item-color: var(--gray-900);
$dropdown-menu-divider-color: var(--gray-200);

.text-0_75rem {
  font-size: var(--font-size-0_75rem); // 12px
}
.text-0_875rem {
  font-size: var(--font-size-0_875rem); // 14px
}
.text-1rem {
  font-size: var(--font-size-default); // 16px
}
.text-default {
  font-size: var(--font-size-default); // 16px
}
.text-1_125rem {
  font-size: var(--font-size-1_125rem); // 18px
}
.text-1_25rem {
  font-size: var(--font-size-1_25rem); // 20px
}
.text-1_375rem {
  font-size: var(--font-size-1_375rem); // 22px
}
.text-1_5rem {
  font-size: var(--font-size-1_5rem); // 24px
}
.text-1_675rem {
  font-size: var(--font-size-1_675rem); // 26px
}
.text-1_75rem {
  font-size: var(--font-size-1_75rem); // 28px
}
.text-1_875rem {
  font-size: var(--font-size-1_875rem); // 30px
}
.text-2rem {
  font-size: var(--font-size-2rem); // 32px
}
.text-2_125rem {
  font-size: var(--font-size-2_125rem); // 34px
}
.text-2_25rem {
  font-size: var(--font-size-2_25rem); // 36px
}
.text-2_375rem {
  font-size: var(--font-size-2_375rem); // 38px
}
.text-2_5rem {
  font-size: var(--font-size-2_5rem); // 40px
}
.text-3rem {
  font-size: var(--font-size-3rem); // 44px
}
.text-3_125rem {
  font-size: var(--font-size-3_125rem); // 50px
}










h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6,
.display-sm, .display-md, .display-lg, .display-xl { line-height: $default-line-height; }


