
.br-text-settings .text-area {
	border: 1px solid $input-inactive-border-color;
}

.br-widget-view .view-text { overflow:auto; }



.CONTENT.br-widget-dialog .modal-dialog {
	width: 70%;
}

.br-widget-box {
	.view-text, .view-label {
		border-color: var(--gray-600);
	}
}

.br-widget-box.CONTENT widget-content .view-text div,
.br-widget-box.CONTENT widget-content .view-text span,
.br-widget-box.CONTENT widget-content .view-text p {
	font-family: inherit;
	font-size: inherit;
	font-style: inherit;
	font-weight: inherit;
}



.view-text h6, .view-label h6, .font-xxx-small-scale {font-size:0.73vw;}
.view-text h5, .view-label h5, .font-xx-small-scale {font-size:0.86vw;}
.view-text h4, .view-label h4, .font-x-small-scale {font-size:1.06vw;}
.view-text h3, .view-label h3, .font-small-scale {font-size:1.26vw;}
.view-text h2, .view-label h2, .font-medium-scale {font-size:1.6vw;}
.view-text h1, .view-label h1, .font-large-scale {font-size:2.13vw;}
.font-x-large-scale {font-size:3.2vw;}

.mobile-layout {
	.view-text h6, .view-label h6, .font-xxx-small-scale {font-size:1.22vw;}
	.view-text h5, .view-label h5, .font-xx-small-scale {font-size:1.44vw;}
	.view-text h4, .view-label h4, .font-x-small-scale {font-size:1.77vw;}
	.view-text h3, .view-label h3, .font-small-scale {font-size:2.11vw;}
	.view-text h2, .view-label h2, .font-medium-scale {font-size:2.67vw;}
	.view-text h1, .view-label h1, .font-large-scale {font-size:3.56vw;}
	.font-x-large-scale {font-size:5.33vw;}
}

span.font-xxx-small-scale {font-size:0.73vw !important;}
span.font-xx-small-scale {font-size:0.86vw !important;}
span.font-x-small-scale {font-size:1.06vw !important;}
span.font-small-scale {font-size:1.26vw !important;}
span.font-medium-scale {font-size:1.6vw !important;}
span.font-large-scale {font-size:2.13vw !important;}
span.font-x-large-scale {font-size:3.2vw !important;}

.mobile-layout {
	span.font-xxx-small-scale {font-size:1.22vw !important;}
	span.font-xx-small-scale {font-size:1.44vw !important;}
	span.font-x-small-scale {font-size:1.77vw !important;}
	span.font-small-scale {font-size:2.11vw !important;}
	span.font-medium-scale {font-size:2.67vw !important;}
	span.font-large-scale {font-size:3.56vw !important;}
	span.font-x-large-scale {font-size:5.33vw !important;}
}


