command-palette {
    .command-palette__key-hint { right: $spacing-3; top: calc(50% - .5em); }
    .command-palette__list-wrapper { z-index: 1050; }
    .command-palette__list-item + .command-palette__list-item { border-top-style: solid; border-top-width: 1px; }
    
    ul {
        max-width: 1000px;
    }

    &.ctrl-down:not(.shift-down) {
        .ctrl-name { display: flex }
        .ctrl-name ~ .basic-name { display: none; }
    }
    &.shift-down:not(.ctrl-down) {
        .shift-name { display: flex }
        .shift-name ~ .basic-name { display: none; }
    }
    &.ctrl-down.shift-down {
        .ctrl-shift-name { display: flex }
        .ctrl-shift-name ~ .basic-name { display: none; }
    }

    .ctrl-shift-name, .ctrl-name, .shift-name { display: none; }
}