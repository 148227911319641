.priority {
	display: inline-flex;
	line-height: 1;
	margin-right: $spacing-2;
	position: relative;

	.priority-base {
		color: var(--gray-400);
		position: relative;
	}

	.priority-level {
		position: absolute;
		top: 0;
		left: 0;
	}
}
