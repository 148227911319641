
.ma-create-modal .contract-licenses { margin-left: 0;}
.ma-details-modal .form-horizontal input[type="checkbox"] {display:inline;}




.br-org-edit, .br-org-upload {
	background:none !important;
	border:none;
	padding:0!important;
	cursor: pointer;

	&[disabled], &[disabled]:hover { color: var(--gray-900);}
}

.br-filters {
	display: inline-block;
	vertical-align: middle;
}

.br-nodes-chosen * { display: inline-block; }

.nodes th {
	text-overflow: ellipsis;
}



.td-inline-hidden-overflow {
	max-width: 0;

	&, > * {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
}

.edit-block {

	> * {
		display: inline-block;
		vertical-align: top;
	}

	.navigator-edit-block {
		border-right: 2px solid var(--gray-600);
	}

	.navigator-scroll-edit-block {
		height: 350px;
		overflow-y: auto;
		position: relative;
		padding-top: $spacing-3;
		padding-bottom: $spacing-3;
	}
}

.structure-edit-block {
	display: flex;
	align-items: center;
	justify-content: space-between;
	min-height: $input-height;
	padding:0 $spacing-3;
	margin-bottom: $spacing-2;
}

#password-policy {
	$field-width: 60px;

	.form-control{
		float: left;
		width: $field-width;
		margin-top: $spacing-3;
		padding: var(--input-padding);
		text-align:center;
	}

	.control-label {
		display: block;
		text-align: left;
	}

	.help-block, .control-label {
		margin-left: #{$field-width + $spacing-4};
	}
}

.ma-management-screen {
	padding-left: 24px;
	padding-right: 24px;
}

.ma-management-screen>.tab-content>.colorpicker.dropdown {
	margin-top: -165px;
}

.br-engagor-toggle {
	left: 15px;
}

.br-org-upload-button { position:relative; }
.br-org-upload-button > .br-upload-file { width:100%; height:100%; z-index:1; visibility:hidden; }


.table.cx-ma-contracts-table th{
	text-transform: capitalize;
	vertical-align: bottom;
}



/* case mgt */
button.block, input[type="search"].block {margin-right:$double-padding;}
.case-mgmt-attributes-sel input[type="checkbox"] {line-height:inherit; vertical-align:middle; margin:0 3px 0 0;}

/* need to adjust css so these doesn't have to be so specific */
.case-mgmt-attributes-list,select.case-mgmt-attributes-list  { border:1px solid var(--gray-600); padding:5px; height:300px; overflow-y:scroll;}
.tab-management-screen .tab-content ul.case-mgmt-config-col { overflow-y:auto;}

.case-mgmt-selected-attr { border:1px solid var(--gray-600); }

.case-mgmt-config-col, select.case-mgmt-config-col { height:220px; overflow-y:scroll; padding:5px; width:100%;  }

.case-attr-swap-btns {padding-top:170px;}

.case-mgmt-config-overflow > .panel-group {max-height:500px; overflow-y:scroll; padding:15px; }





.jms-status-table { font-size:var(--font-size-default);}




.mobile-layout {
	.case-mgmt-attributes-list,select.case-mgmt-attributes-list {height:15em;}
}

.subscription-template-note {
	font-size: var(--font-size-0_875rem);
	color: var(--danger-600);
}


.contract-licenses > div { height:50px; }

.meaningful-silence-message {
	padding-left: 15px;
	padding-right: 15px;
}

// failed user upload users list
.hierarchy-error-users {
	th, td {
		width: 300px;

		&:first-child { width: 250px;}
	}
}

.link-sharing-settings {
	.time-to-live-configuration {
		margin-left: 80px;

		.time-to-live-input {
			text-align: right;
			width: 55px;
		}
	}

}

.widget-cache-settings {
	input.form-control {
		display: inline-block;
		width: 75px;
	}

	.error-block {
		color: var(--danger-600);
	}
}

.cx-contract-modal {

	.cx-contract-input {
		width: calc(50% - 8px);
	}

	.alert-danger {
		width: 100%;
	}
}

.beta-features-checklist-grid {
	max-height: 400px;
	overflow-y: auto;

	.slick-cell {
		align-items:center;
		display: flex;
		line-height: 1.5 !important;
	}
}

.workspace-selector {
	.form-group {
		margin-right: 0px;
		margin-left: -8px;
	}
}
