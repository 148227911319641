words-selection {
	> div { 
		display: flex;
		align-items: flex-start;
	}

	.filter-rule-values, .host {		
		display: flex;
		flex: 1;
		flex-direction: column;		
	}
}