%flex-align-center {
	display: flex;
	align-items:center;

	* { margin-bottom:0; margin-top:0; }
}

.scorecard-dialog {
	.hierarchy-item {
		@extend %flex-align-center;
	}

	.br-hierarchy-node-name {
		@include flex-fill;
		word-break:break-all;
		white-space:initial;
	}

	.searchable-hierarchy-list { height: auto;}

	.hierarchy-select-wrapper {
		min-height: 96px;
	}

	div.q-icon-warning:before {
		margin-right: $spacing-2;
		color: var(--success-800);
	}
}

.cell-remove {
	cursor: pointer;
}

.scorecard-scoring-dialog {
	input {
		margin:0;

		&[disabled] {
			background-color: transparent;
			color: var(--gray-900);
			text-align: center;
			cursor: not-allowed;
		}
	}

	.scorecard-column-wrapper {
		display:flex;
		justify-content: space-between;
		.scorecard-presence-column-wrapper, .searchable-list, .search-list-dropdown {
			min-width: 140px;
		}
	}

	.scorecard-column {
		@extend %flex-align-center;
		justify-content: center;
		width: 113px;
	}

	.scorecard-column-impact-rank {
		@extend %flex-align-center;
		justify-content: center;
		width: 120px !important;
	}

	.scorecard-weight-footer {
		min-width: 230px;
		justify-content: space-between;
	}

	.scoring-matrix-numeric-input {
		width: 90px;
	}

	.scoring-matrix-name-column {
		@include flex-fill;
		word-break: break-all;
	}

	.scoring-entry {
		padding: $spacing-2 0;
		@extend %flex-align-center;
		border-bottom: var(--gray-200) 2px solid;
	}

	.scoring-matrix-weight-footer-top {
		padding-top: $spacing-3;
		border-top: 1px solid var(--gray-900);
		@extend %flex-align-center;
	}

	.scoring-matrix-body {
		max-height: calc(100vh - 400px);
		overflow-y: scroll;
	}

	.standalone-scoring {
		@extend %flex-align-center;
		margin-bottom: $spacing-3;
		justify-content: flex-end;

		label {
			text-align: right;
			width: 113px;
		}
	}
}

.scoring-entry search-list { display: flex; }

.scorecard-preview-result {
	.row {
		padding: $spacing-3 0;
	}
}

.DEFINITION_TABLE {
	.slick-row.non-score {
		opacity: .5;
	}

	.slick-cell {
		border-bottom: var(--gray-400) 1px solid !important;
		padding-bottom: 3px !important; // to compensate border
	}
}

.modal.scorecard-editor-wizard, .modal-backdrop.scorecard-editor-wizard {
	z-index: 1030 !important;
}
