// only until unified-angular gets updated to reflect new icon set
[class*="en-icon"] {
    &:before {
        text-rendering:optimizeLegibility;
        -moz-osx-font-smoothing:grayscale;
        -webkit-font-smoothing:antialiased;
        font-smoothing:antialiased;
        display:inline-block;
        font-family:qualtrics-icons!important;
        font-style:normal;
        font-variant:normal;
        font-weight:400;
        line-height:inherit;
        text-decoration:inherit;
        text-transform:none
    }
    font-size: var(--icon-font-size);
}
.en-icon-drag:before { content:"\f190" }
.en-icon-angle-bottom:before { content: "\f117" }
.en-icon-layer:before { content:"\f1d1" }
