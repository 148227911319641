$visualization-width: 90px;
$transcript-piece-background-color: var(--gray-200);
$player-background: var(--gray-700);



conversation {
	background: inherit;

	audio { display: none; } // don't show the default audio player
	.audio-transcript {
		max-height: calc(100% - 90px); overflow:auto;

		&.hide-scroll {
			-ms-overflow-style: none;  /* Internet Explorer 10+ */
			scrollbar-width: none;  /* Firefox */
		}

		&.hide-scroll::-webkit-scrollbar {
			display: none;  /* Safari and Chrome */
		}
	}

	.audio-viz-wrap {
		background: inherit;
		display:flex;
		overflow: hidden;
		flex: 1 1 100%;
		justify-content:space-between;
		position:relative;

		.audio-transcript {
			max-height:none;
			overflow:auto;
			width: calc(100% - #{$visualization-width + $standard-grid-padding});
		}

		.fake-scroll {
			overflow-y: scroll;

			.inner-block {
				width: 1px;
			}
		}
	}

	.widget-image-export & .fake-scroll {
		display: none;
	}

	#audio-visualization {
		float:left;
		width:$visualization-width;

		.current-sentence-band { opacity:.3; }
	}
}

conversation, .spine-tooltip { @include break-word; }

.playback-options { line-height:1; }
.playback-speed { display: inline-block; width: 30px; }

transcript-piece {
	$transcript-piece-padding: 10px; /* padding inside of each block of the transcript */

	cursor:pointer;
	width:90%;


	%paragraph-to-edges {
		margin-left:-1 * $transcript-piece-padding;
		margin-right:-1 * $transcript-piece-padding;
		padding-right: $transcript-piece-padding;
		padding-left: $transcript-piece-padding;
	}

	> div {
		background: $transcript-piece-background-color;
		border-radius: $standard-border-radius;
		margin: 0 0 $standard-grid-padding;
		padding:7px $transcript-piece-padding;
		width: 90%;

		&.is-playing p:not(.speaker-label) {
			background: var(--action-300);
			@extend %paragraph-to-edges;
		}
	}

	&.selected-sentence p:not(.speaker-label) {
		border-left:4px solid var(--default-primary-color);
		padding-left:$standard-grid-padding;
		@extend %paragraph-to-edges;
	}

	&.client-transcript > div { float:right; }
	&.unknown-transcript > div { float:right; }
	&.same-channel-as-last > div { margin-top: -1 * ($standard-grid-padding + $standard-border-radius); }
	&.same-channel-as-last .speaker-label { display:none; }

	.timestamp {
		border-left: 1px solid var(--gray-600);
		margin-left: $standard-grid-padding;
		padding-left: $standard-grid-padding;
	}

	&.hidden-transcript * { display:none; }


	p:empty, div:empty { display: none; }

}

.voice-silence-item {
	text-align:center;
	padding: 3px 3px 18px;
	font-style:italic;
}

.label-bubble {
	background: var(--gray-200);
	border-radius: 1em;
	display:inline-block;
	font-size: .75em;
	margin-bottom:.5em;
	margin-right: .5em;
	padding: 2px .75em;
	user-select:none;
	white-space:nowrap;

	&.strong-positive { background: $sentiment-strong-positive; color:var(--white); }
	&.positive { background: $sentiment-positive; color:var(--white); }
	&.neutral { background: $sentiment-neutral; }
	&.negative { background: $sentiment-negative; color:var(--white); }
	&.strong-negative { background: $sentiment-strong-negative; color:var(--white); }
}




/* mediaelementplayer overwrites */


.mejs__container { background: $player-background; border-radius: $standard-border-radius; width:100% !important; }
.mejs__controls, .mejs__mediaelement, .mejs__controls:not([style*='display: none']) { background: none }
.mejs__time-loaded { background: var(--gray-600); }
.mejs__time-current { background: var(--gray-500); }
.mejs__time-handle { background: var(--default-primary-color); border-width:3px; border-radius: $standard-border-radius; height:20px; margin-top:-4px; width:3px; }


.mejs__time-rail:hover .mejs__time-handle-content { display:none; }



$agent-icon-color: var(--blue-800);
$client-icon-color: var(--gray-900);
$bot-icon-color: var(--aqua-600);

.sentence-icon {
	&.q-icon-conversation-customer-circle { color: $client-icon-color; }
	&.q-icon-conversation-agent-circle { color: $agent-icon-color; }
	&.q-icon-robot { color: $bot-icon-color; }
}

.spine-tooltip {
	color: var(--gray-900);
	border: none;
	border-radius:$dropdown-border-radius;
	box-shadow: $dropdown-box-shadow;
	padding: $spacing-4;
	font-size: var(--font-size-0_875rem);
	line-height: 1.5;
	min-width: 200px;
	max-width: 300px;
	position: absolute;
	background: var(--white);
	z-index:1051; // need to layer above modal

	.call-icon {
		display: flex;
		height: $spacing-5;
		width: $spacing-5;
		align-items: center;
		justify-content: center;
		border-radius: $standard-border-radius;
		font-size: var(--font-size-2rem); // getting this back to 2 rem as it looks too big
		margin-bottom: 4px;
		margin-right: 8px;
		&.channel-agent { color: $agent-icon-color; }
		&.channel-client { color: $client-icon-color }
		&.channel-unknown { color: $client-icon-color }
		&.channel-bot { color: $bot-icon-color }
		.q-icon-conversation-silence {
			font-size: var(--font-size-1_675rem);
		}
	}

	.tooltip-header {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		font-size: var(--font-size-default);
		font-weight: 600;
		margin: 0 $spacing-3;

		.tooltip-channel {
			@include flex-fill;
			margin-left: $spacing-3;
			margin-right: $spacing-3;
		}
		&::before {
			content: " ";
			position: absolute;
			right: 100%;
			border-width: 6px;
			border-style: solid;
			border-color: transparent var(--white) transparent transparent;
		}
	}

	.tooltip-content {
		color: var(--gray-900);
		border-radius:$dropdown-border-radius;
		padding: $spacing-3;
		margin-top: $spacing-3;

		&.channel-agent {
			background-color: var(--bg-color-agent);
		}
		&.channel-client {
			background-color: var(--bg-color-client);
		}
		&.channel-bot {
			background-color: var(--bg-color-bot);
		}
		&.channel-unknown {
			background-color: var(--bg-color-client);
		}

		.tooltip-enrichments {
			margin-top: $spacing-4;
		}

		.tooltip-enrichment {
			margin-top: $spacing-3;
			margin-right: $spacing-3;

			.icon-highlight {
				margin-right: $spacing-2;
				padding-top: 1px;
				background-color: var(--white);
			}
			.emoticon-pill {
				font-size: var(--font-size-1_375rem);
			}
		}

		.tooltip-pills {
			margin-top: $spacing-3;

			.label-bubble {
				background-color: var(--gray-200);
				height: 40px;
			}
		}
	}

	.metric-identifier {
		align-items: center;
		display: flex;
		background: var(--gray-200);
		border-radius: $spacing-2;
		height: 1.5rem;
		margin-bottom: $spacing-2;
		padding: $spacing-2 $spacing-3 $spacing-2 2rem;
		position: relative;

		> [class*="q-icon"] {
			position: absolute;
			left: 8px;
		}
	}
}

// need the span piece of this selector to increase specificity
// can remove after old style is no longer supported
.spine-tooltip, conversation, feedback-sentence, twitter-message, tuning-suggestion-summary {
	span.label-bubble, button.label-bubble {
		border: 1px solid var(--gray-400);
		background: var(--white);
		height: $spacing-4;
		font-size: var(--font-size-default); // making it larger and bolder as part of beta site ui updates
		font-weight: 700;
		letter-spacing: 0.3px;
		@include flex-center;
		@include align-center;
		@include flex-inline;
		margin-bottom: $spacing-2;
	}
	chat-message span.label-bubble {
		margin-bottom: 0;
	}
}


@media screen\0 {
	.spine-update #audio-visualization.has-scroll > svg, .topics-count {
		min-width: 120px;
		width: 120px;
		&.secondary-tracks-1 { min-width: 126px; width: 126px; }
		&.secondary-tracks-2 { min-width: 153px; width: 153px; }
		&.secondary-tracks-3 { min-width: 169px; width: 169px; }
		&.secondary-tracks-4 { min-width: 191px; width: 191px; }
	}
}

@-moz-document url-prefix() {
	.spine-update #audio-visualization.has-scroll:after {
		content: ' ';
		visibility: hidden;
		overflow-y: scroll;
		scrollbar-width: thin;
	}
}

.spine-update {
	.mejs__container { background: none; }
	.mejs__time { color: var(--gray-900); }

	.mejs__time-total {
		background: var(--gray-200);
		margin-top: 7px;
	}
	.mejs__time-loaded { background: var(--gray-400); }
	.mejs__time-current { background: var(--gray-600); }


	.mejs__pause, .mejs__play, .mejs__mute, .mejs__unmute, .mejs__replay {
		> button {
			$icon-size: var(--font-size-1_675rem);
			background: none;
			height: $icon-size;
			display: flex;
			line-height: 1;
			width: $icon-size;

			&:after {
				font-family: var(--qualtrics-icons);
				font-size: $icon-size;

				color: var(--gray-400);
			}
		}
	}
	.mejs__play>button:after { content: "\f1a9" }
	.mejs__pause>button:after { content: "\f1a2" }
	.mejs__mute>button:after { content: "\f273" }
	.mejs__unmute>button:after { content: "\f274" }
	.mejs__replay>button {
		transform: scaleX(-1);

		&:after { content: "\f207"; }
	}
	.mejs__time-buffering, .mejs__time-current,
	.mejs__time-float-corner, .mejs__time-float-current, .mejs__time-hovered,
	.mejs__time-loaded, .mejs__time-marker, .mejs__time-total { height: 8px;}
	.mejs__time-handle { margin-top: -5px; }

	.mejs__time-float {
		color: var(--gray-900);
		background:var(--gray-200);
		border-color:var(--gray-600);
	}

	.mejs__time-float-corner {
		border-color:var(--gray-600) transparent transparent;;
	}

	.mejs__time-float-corner::before {
		content: ' ';
		border-style: solid;
		border-width: 5px 5px 0 5px;
		border-color: var(--gray-200) transparent transparent transparent;
		right: calc(50% - 5px);
		bottom: calc(100% + 1px);
		position: absolute;
	}

	.audio-transcript {
		flex: 1;
		width: auto !important;
	}

	#audio-visualization {
		width: auto !important;
	}

	#audio-visualization svg, .topics-count {
		min-width: 104px;
		width: 104px;

		&.secondary-tracks-1 { min-width: 110px; width: 110px; }
		&.secondary-tracks-2 { min-width: 137px; width: 137px; }
		&.secondary-tracks-3 { min-width: 153px; width: 153px; }
		&.secondary-tracks-4 { min-width: 175px; width: 175px; }
	}

	.spine-wrapper {
		align-items: flex-start;
		display: flex;
		flex-direction: column;
		flex: 0 1 auto;
		z-index: 2;
	}

	#audio-visualization {
		display: flex;
		overflow-y: auto;
		overflow-x: hidden;
		flex: 1 1 100%;
	}

	.can-scroll-up {
		box-shadow: inset 0px 4px 4px -4px rgba(0, 0, 0, .5);
	}

	.can-scroll-down {
		box-shadow: inset 0px -4px 4px -4px rgba(0, 0, 0, .5);
	}

	.can-scroll-up.can-scroll-down {
		box-shadow:
			inset 0px 4px 4px -4px rgba(0, 0, 0, .5),
			inset 0px -4px 4px -4px rgba(0, 0, 0, .5);
	}

	spine-header { display: flex; }

	.audio-transcript {
		&.can-scroll-up, &.can-scroll-down { box-shadow: none; }
	}
}

// layer shadows over container so that they overlap container's content
.scroll-up-shadow, .scroll-down-shadow {
	position: absolute;
	height: 4px;
	width: 100%;
	z-index: 1;
}

.can-scroll-up ~ .scroll-up-shadow {
	box-shadow: inset 0px 4px 4px -4px rgba(0, 0, 0, .5);
	top: 0;
}

.can-scroll-down ~ .scroll-down-shadow {
	box-shadow: inset 0px -4px 4px -4px rgba(0, 0, 0, .5);
	bottom: 0;
}


spine-header {
	display: none;
	align-items: center;
	background: var(--gray-200);
	font-size: var(--font-size-0_75rem);
	height: $spacing-5;

	div {
		align-items: center;
		display: flex;
		height: 100%;
	}
	.participant-lane {
		padding: 0 $spacing-3;
		width: 90px;
	}
	.single-lane {
		border-left: 1px solid var(--white);
		justify-content: center;
		width: 21px;
	}
}


.hide-sentiment-formatting:not(.use-pills) {
	.voice-sentiment-icon, .voice-ease-icon, .voice-emotion-icon, .enrichment-pill {
		display: none !important;
	}
}

.hide-sentence-text voice-sentence {
	display: none !important;
}

.hide-ease .voice-ease-icon {
	display: none !important;
}

.hide-topics:not(.use-pills) .sentence-topics {
	display: none !important;
}

.topics-count {
	position: absolute;
	width: 100%;
	background: var(--white);
	opacity: 0.8;
	color: var(--gray-900);
	height: 25px;
	padding: 2px;
	display: flex;
	justify-content: space-around;

	i { color: var(--gray-1000); }

	&.above-topics-count {
		top: 24px;
	}
	&.below-topics-count {
		bottom: 0;
	}
}
