.floating-widget {
    right: auto;
    position: fixed;
    transition: none;
    z-index: 2000;
    @include box-shadow($standard-box-shadow);
}

.floating-widget [floatable-handle] {
    cursor: move;
}