date-range, date-point {
	.ngb-dp-navigation-select > .custom-select {
		font-weight: 600;
	}

	.ngb-dp-weekday {
		width: 3rem;
		font-weight: 600;
	}

	.cx-date-filter-test-wrap{
		.ngb-dp-weekday {
			margin-top: -10px;
		}
		.test-result {
			min-height: 100px;
		}
 	} 

	.ngb-dp-day {
		padding: 1px;
		width: 3rem;
		.date-point-day {
			width: 3rem;
			height: 100%;
			border-radius: 2px;
			box-shadow: none;
			font-size: var(--font-size-default);
			padding: 5px 10px;
			&.outside {
				opacity: 0.5;
			}
		}
	}

	[hidden] {
		display: none !important;
	}
}