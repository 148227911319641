.br-share-dialog.br-mobile .modal-body {
    overflow: visible;
}


.br-share-table{
    border-bottom: solid var(--gray-400) 1px;
    overflow-x: hidden;
    overflow-y:auto;
}



.br-share-dialog:not(.br-mobile), alert-sharing {
    .br-share-table .dropdown-menu { position:fixed; }
}

.br-mobile .br-share-table {
    overflow: visible;
}


%user-icon-spacing {
    display: inline-block;
    text-align:center;
    width: 15px;
}


.user-icon-spacing { @extend %user-icon-spacing; }
.email-input-wrap li i { @extend %user-icon-spacing; }


.user-info {
    align-items: center;
    border-top: solid var(--gray-400) 1px;
    display: flex;
    padding: $spacing-2 $spacing-5;

    &.even {
        background-color: $table-alternate-row-color;
    }

    >i {
        margin-right: $spacing-4;
        @extend %user-icon-spacing;
    }

    p { margin: 0; }

    .user-email-id {
        overflow:hidden;
        padding-right:$spacing-3;
        text-overflow:ellipsis;
        white-space:nowrap;
        width: 55%;

        &.br-share-inner {
            padding-left: $spacing-7;
        }
    }
}


@media screen and ( max-width : 767px) {
    .email-input-wrap{
        margin-bottom: 10px;
    }

    .email-input-wrap input{
        width: 100%;
    }

    .user-info {
        .user-email-id{
            width: 82%;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }

        .user-email-id.br-share-inner {
            padding-left: 20px;
        }

        .user-remove{
            top: -25px;
            position: relative;
            left: 8px;
        }
    }

    .br-share-table.br-share-users {
        max-height: inherit;
    }

    .notify-email-box{
        float: left;
    }
}

@media (min-width: 992px) {
    .br-share-dialog .modal-dialog {
        width: 900px;
    }
}
[uib-typeahead-popup].dropdown-menu{
    max-height:300px;
    overflow-y:auto;
    max-width:650px;
}




.books-sharee-item-popover {
    table {
        width: auto;
    }
    td {
        width: auto;
    }
    .list-item {
        margin-left: 5px;
        font-size: var(--font-size-default);
    }
}
