.cb-overlay {
	position: absolute;
	top: -1px;
	left: -1px;
	bottom: -1px;
	transform: none;
	right: -1px;
}

.cb-linking-overlay {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 9999;

	&.linking-source {
		background-color: var(--black-50-percent);
		background: url(data:;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAACCAYAAABytg0kAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAgY0hSTQAAeiYAAICEAAD6AAAAgOgAAHUwAADqYAAAOpgAABdwnLpRPAAAABl0RVh0U29mdHdhcmUAUGFpbnQuTkVUIHYzLjUuNUmK/OAAAAATSURBVBhXY2RgYNgHxGAAYuwDAA78AjwwRoQYAAAAAElFTkSuQmCC) repeat scroll transparent \9; /* ie fallback png background image */
		color: $white;
		text-align: center;
		border:1px solid var(--gray-1000);
	}
	.linking-target {
		background-color: var(--gray-600);
		opacity: 0.0;
		z-index: 999;
		color: $white;
		text-align: center;
		width: 100%;
		height: 100%;
		cursor: pointer;

		&:hover, &.linked {
			background-color: var(--gray-600);
			opacity: 0.8;
			border:1px solid var(--gray-1000);
		}
		&.linking-disabled:hover {
			opacity: 0.5;
		}

		.q-icon-check {
			color: $white;
		}

		span {
			position: relative;
			top: calc(50% - 100px);
			font-size: 8.75rem;
		}

		&.linking-disabled {
			cursor: default;
		}
	}
	.linked .q-icon-check {
		color: var(--gray-900);
	}
}

.linked-widget-parent, .linked-widget-child {
	// override more specific widget border styles
	.br-widget-box.CB { border: 1px solid var(--blue-800) !important; }
}

.zoom-75 .cb-linking-overlay .linking-target span {
	top: calc(50% - 75px);
	font-size: 6.25rem;
}
.zoom-50 .cb-linking-overlay .linking-target span {
	top: calc(50% - 50px);
	font-size: 4.375rem;
}

$highlight-opacity: 0.2;
.gridster-widget.point-selection, .global-other-widget-row .point-selection {
	.highcharts-series {
		rect, text, path, circle {
			&:not([selected]) {
				opacity: $highlight-opacity;
			}
		}
	}
	.highcharts-markers {
		path:not([selected]) {
			opacity: $highlight-opacity;
		}
	}
	.grid-canvas .slick-row:not(.active) {
		opacity: $highlight-opacity;
	}
	.preview-tabe-view:not(.audit-mode) {
		preview-table-component .cb-table-row,
		preview-bubbles .feedback-preview-bubble-item {
			&:not([selected]) {
				opacity: $highlight-opacity;
			}
		}
	}
	.tree-container {
		.tree-node, .tree-links {
			&:not([selected]) {
				opacity: $highlight-opacity;
			}
		}
	}
}

.br-widget-point-selection-warning {
	visibility: hidden;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.gridster-widget.point-selection-warning {
	.br-widget-controller {
		visibility: hidden;
	}

	.br-widget-point-selection-warning {
		visibility: visible;
	}

	.br-widget-total {
		visibility: hidden;
	}
}
