.labeled-rule {
	margin-bottom: 0;
	padding: $spacing-4 0;
	position: relative;

	hr {
		margin: 0 $spacing-5;
		border-color: var(--gray-600);
	}

	div {
		padding: 0 (2 * $spacing-6);
		line-height: 1;
		position: absolute;
		top: calc(50% - .5em);
		width: 100%;
	}

	&.text-center div { text-align:center; }
	&.text-right div { text-align:right; }

	label {
		padding: 0 $spacing-4;
	}
}
