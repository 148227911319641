.loader {
    overflow: hidden !important;
    margin: 30px auto;
    font-size: 0.375rem; //5px --> 6px in rem;
    position: relative !important;
    text-indent: -9999em; -ms-transform: translateZ(0); transform: translateZ(0);
    -webkit-animation: load8 2.1s infinite linear;
    animation: load8 2.1s infinite linear;

    &, &:after {
        border-radius: 50%;
        height: 10em;
        width: 10em;
    }

    &.small {
        border-width:.8em;
        margin: 6px auto;

        &, &:after {
            height: 4em;
            width: 4em;
        }
    }

    .large & {
        border-width:2em !important;
        margin: 60px auto;

        &, &:after {
            height: 30em;
            width: 30em;
        }
    }

    &.button {
        border-width:.6em;
        margin: 10px auto;

        &, &:after {
            height: 3em;
            width: 3em;
        }
    }
}

.cg-busy-animation {
	display: flex;
	align-items: center;
	justify-content: center;
}

@-webkit-keyframes load8 {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@keyframes load8 {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
