.ma-management-screen.audit {
	height:calc(100% - 35px);

	.br-list-box > div,
	.tab-content,
	.tab-pane.active,
	.tab-pane.active > .row,
	.br-log-entries-ctrl-tab,
	.br-log-panel,
	.br-log-list  {
		display: flex;
		flex: 1;
		flex-direction:column;
	}
}

.audit-details-panel {
	white-space: pre;
	overflow: auto;
}
// .br-log-entries-ctrl-tab {
// 	position:absolute;
// 	bottom:10px;
// 	top:40px;
// 	left:30px;
// 	right:30px;
// }