
/* template selection list  */

	.templates-list-wrap {
		[cg-busy] { min-height:35px; }
		> .box { padding:$spacing-5; }
	}

	.template-preview-wrap { min-height:250px; padding:0 0 20px; }

	.template-preview {
		border:1px solid var(--gray-600);
		border-radius:5px;
		height:160px;
		margin-bottom:15px;
		width:100%;

		> .preview-img {
			background-position:center -50px;
			height:100%;
			left: 0;
			opacity: .3;
			position: absolute;
			top: 0;
			width:100%;
			z-index:1;
		}

		> h4 {
			margin:0;
			position:absolute;
			top:calc(25% - 1em);
			width:100%;
			z-index:3;
		}
	}


	.template-plus {
		position: absolute;
		top:calc(50% - (65px/2));
		width:100%;
		z-index:2;

		> i {
			font-size:4.0625rem;
			opacity:.5;
			transition: opacity $css-animation-duration;

			.template-preview:hover & { opacity:.7 }
		}
	}



	.templates-info {
		background: none;
	    border: none;
	    bottom:0;
	    opacity: 0.5;
	    outline: none;
	    position:absolute;
	    right:5px;
	}


	.template-modal-preview-empty {
		border:1px solid var(--gray-600);
		line-height:300px;
		font-size:5rem;
		width:125px;
	}

	.template-modal-preview { max-height:600px; max-width:80%; }


.btn-template-widget{
	height:45px;
	&:active {box-shadow:none;}
}



.template-warning {
	color: var(--danger-600);
}

.template-warning ul {
	list-style: disc;
}

.template-placeholder-item {
	margin-bottom: 5px !important;
}

.template-placeholder-group-title {
	margin-top: 15px !important;
	margin-bottom: 5px !important;
}

.template-placeholder-display-name-input {
	width: 100%;
}

.template-placeholder-list {
	overflow-x: hidden;
	overflow-y: auto;

	max-height: 100px;
}

.template-placeholder-list.fill-list {
	.displayName {
		font-size: var(--font-size-0_875rem);
		margin-top: 5px;
	}
}

.template-placeholder-replacement-menu {
	width: 300px !important;
	max-width: 500px !important;
	z-index: 10000;

	input.search-box {
		width: 100%;
	}
}

.wide-popover {
	max-width: 600px !important;
}
