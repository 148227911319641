$access-column-width: 225px;
$owner-column-width: 250px;

$share-row-height: 38px;

.share-row {
	align-items: center;
	border-top: 1px solid var(--gray-400);
    display: flex;
    padding: $spacing-3 $spacing-2;
	@include flex-justify-separate;

	.name-column {
		display: flex;
		flex: 4;
		padding-right: $spacing-4;
		@include align-center;

		> [class*="q-icon"] {
			display: flex;
			justify-content: center;
			flex: 0 0 $spacing-6;
		}
	}

	.access-column, .preview-as-column {
		display: flex;
		flex: 1;
		@include flex-justify-center;
		@include align-center;
	}

	&.share-delete {
		color: var(--gray-400);
	}
}

.share-object-row {
	.name-column { flex: 1 1 calc(100% - #{$owner-column-width}); }

	.owner-column {
		display: flex;
		flex: 1 1 $owner-column-width;
		@include flex-justify-separate;
		@include align-center;
		max-width: $owner-column-width;

		> span {
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
}

share-table, object-share-table, indirect-share-table {
	display: flex;
	flex-direction: column;
	max-height: $share-row-height * 6;
	overflow: auto;
	position: relative;

	.modal-dialog & .dropdown-menu {
		position: fixed;
	}
	.no-modal-sharing & {
		max-height: none;
		overflow: visible;
	}

	&.loading { min-height: 120px; }
	&.saving { overflow:hidden }

	panel-body > & {
		margin-top: -1 * $spacing-4;
		margin-right: -1 * $spacing-4;
		padding-right: $spacing-4;
	}
}
