.upgrade-management .alert {
	margin-bottom: 0;
}

.accordion {
	.card {
		border: 1px solid var(--gray-200);
	}
	.card+.card {
		margin-top: 5px;
	}
	.card-header {
		border-color: $standard-border-color;
		color: var(--gray-900);
		padding: $spacing-3 $spacing-4;

		.btn-link, .btn-link:focus:hover {
			text-transform: none;
			background-color: transparent;
			border: none;
			box-shadow: none;
		}
		.btn-link {
			color: inherit;
			width: 100%;
			overflow: hidden;
		}
		.btn-link[disabled] {
			background-color: transparent;
		}
	}
	.bg-default {
		background: var(--gray-200);
	}

	.card-body {
		padding: $spacing-3;
	}
	.account-name {
		padding-left: $spacing-4;
		padding-right: $spacing-3;
		line-height: $button-height;
	}
}
