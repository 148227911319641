$alternate-item-color: var(--gray-200);
$sentence-list-primary-bg: $white;
$sentence-border-color: var(--gray-300);
$doc-explorer-light-gray: var(--gray-200);
$side-panel-subsection-divider-color: var(--gray-400);

$panel-padding: $spacing-4;

/* only use this wrapper for items that overwrite default doc explorer styles */
document-previewer {
	height:100%;
	display:flex;
	flex-direction: row;
	width:100%;

	.main-panel { overflow:auto; }

	.dex-list-group {
		height:100%;
		margin-bottom:0;
		overflow:auto;

		&.with-pagination { height: calc(100% - 67px); }

		li {
			border-width: 0;
			position: relative;

			&:before {
				border-top: 1px solid var(--gray-300);
				content: ' ';
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
			}

			&.selected {
				border-left-width:5px;
				border-left-style:solid;
			}
		}
	}


	.dex-left-pane {
		overflow: visible;
		width: 25%;
		height: 100%;
	}



	// TODO: if tabbed context pane replaces collapsing panels version, nothing should reference .right-pane-wrap anymore
	.right-pane-wrap {
		height:100%;
		padding-left: $standard-grid-padding;
	}
	.dex-center-pane {
		min-width:50%;

		.panel.panel-default { height:100%; margin-bottom:0; width:100%; }

		&.visible-side-panes- {
			&0 { width:100%; }
			&1 { width:75%; }
			&2 { width:50%; }
		}

		date-label {
			word-break: normal;
		}
	}



	.dex-models-list-group { margin-bottom:0; }

	.label-bubble {
		border: 1px solid $doc-explorer-light-gray;

		&.selected {
			border-color: var(--blue-700);
		}
	}

	.dex-model-node { padding:0}

	.dex-model-attribute {
		padding: 5px;
	}


	.ellipsis {
		max-width:100%;
		overflow:hidden;
		text-overflow:ellipsis;
	}



	.icon-wrapper { margin-right:5px; }

	.chicklet { font-size:var(--font-size-0_875rem); margin-bottom:5px; }

	// .dex-sentence-search-field { width:100%; }


	.doc-explorer-panel {
		// outline around header when collapsed, instead of entire panel
		&.collapsed {
			&:focus {
				outline: 2px solid var(--effort-easy-900);
				outline-offset: var(--default-outline-offset);
			}
		}
		.panel-heading {
			background-color: var(--gray-300);
			&.collapsed {
				background-color: var(--white);
			}
			&:hover {
				background-color: var(--gray-200);
			}
			&:focus {
				outline: 2px solid var(--effort-easy-900);
				outline-offset: var(--default-outline-offset);
			}
			p { margin:0; }
		}
	}

	.doc-explorer-panel .panel-heading {
		border-top: 1px solid var(--gray-500);
	}

	.dex-panel {
		height:100%;
		margin-bottom:0;

		&.collapsed { height:auto; }
	}

	.document-preview-container .panel {
		border:0;
		box-shadow:none;
		border-radius:0;
		display:flex;
		flex-direction: column;
	}
}




.topic-filter-dropdown.dropdown-menu {	z-index: $modal-window-zindex; }

.topic-filter-wrapper { max-height:230px; overflow:auto; }



.document-preview {
	.search-template {
		max-width: 480px;

		.words-tags-input { min-width:0; }
		.filter-rule-values { margin-left:0; width:100%; }
		tags-input {
			width:100%;

			.tags { resize: none; }
		}
	}
	&:focus{
		outline: solid 2px $white; outline-offset: -2px;
	}
}


.document-controls {

	.curate-document-icon {
		position: relative;

		.curated-items-count-label {
			font-size: var(--font-size-0_75rem);
			line-height: 14px;
			position: absolute;
			top: -5px;
			right: -5px;
			width: 14px;
			height: 14px;
			background: var(--action-1000);
			border-radius: 50%;
			text-align: center;
			vertical-align: middle;
			color: $white;
		}
	}
}
.explorer-tools {

	.remove-boundary > li.pagination-last { display: none !important; }

	&.audit-mode, &.rebuttal-mode {
		padding: 18px 16px 14px 16px !important;

		.interaction-audit-toggle, .intelligent-scoring-toggle {
			padding: 4px 7px;
		}
	}

	.interaction-audit-toggle, .intelligent-scoring-toggle {
		font-size: var(--font-size-1_25rem);
		padding: 5px 4px 4px 7px;
	}
}

.audit-mode .hide-in-audit {
	display: none !important;
}

.interaction-audit-title {
	width: 100%;
	text-align: center;
	font-size: var(--font-size-1_5rem);
}

.interaction-audit-mode-tooltip, .intelligent-scoring-mode-tooltip {
	z-index: 100000;
	opacity: 1;
}

.br-sentence-preview {
	cursor: pointer;
	color: var(--gray-1000);

	&.not-in-document { background: var(--gray-200); }
}




$panel-heading-height: 40px;
.doc-explorer-panel {
	.panel-body {
		&.collapsed {
			visibility: hidden;
			height:0 !important;
			overflow:hidden;
		}
		padding-bottom:0;
		padding-top:0;

	}

	.panel-heading {
		cursor:pointer;
		display: flex;
		flex:0 0 $panel-heading-height;
		align-items: center;
		justify-content: space-between;
		user-select:none;

		button {
			border: none;
			background: transparent;
		}

		[class*="q-icon"] {
			padding: 0 8px;
		}
	}
	.panel-actions {
		display: flex;
		align-items: center;
		text-align: center;
	}
}

.panel-body panel-ribbon {
	display: flex;
	flex-direction: column;
	justify-content: center;
	flex: 1 0 $spacing-6;
	margin-left: (-1 * $spacing-4);
	margin-right: (-1 * $spacing-4);
	padding-left: $spacing-4;
	padding-right: $spacing-4;
}


.chicklet-scroll-wrap, .model-scroll-wrap, .attr-scroll-wrap { overflow: auto; margin-right: (-1 * $spacing-4); }

.topic-hierarchy-ul {
	padding-inline-start: 0;
}

context-navigation {
	.topic-name { flex: 1 1 120px; }
	.topic-index {
		flex: 0 1 60px;
		white-space: nowrap;
	}
	.controls { flex: 0 0 60px }
	button { color: inherit }
}

.panel-subsection-wrapper {
	padding: $spacing-3 0;

	~.panel-subsection-wrapper { border-top:1px solid $side-panel-subsection-divider-color; }
}

.verbatim-wrap { margin-bottom: ($baseline-measurement * 4); }

.floating-bottom-button {
	position: fixed !important;
	z-index: 10 !important;
	bottom:10%;
	left:45%;
}

.sentence-highlighted {
	background: var(--action-300);
}

.br-document-preview-attribute {
	border-top: 1px solid $side-panel-subsection-divider-color;

	&.first { border-top:0; }

	.display-name { text-overflow: ellipsis; }

	.value { word-break: break-all; }

	&:focus, &.focus-within {
		.favoritable-item [class*="q-icon"] {
			display: inline-block;
		}
	}
	&:focus-within {
		.favoritable-item [class*="q-icon"] {
			display: inline-block;
		}
	}
}

.doc-explorer-panel {
	&:focus, &.focus-within {
		.chicklet-scroll-wrap .favoritable-item [class*="q-icon"] {
			display: inline-block;
		}
	}
	&:focus-within {
		.chicklet-scroll-wrap .favoritable-item [class*="q-icon"] {
			display: inline-block;
		}
	}
}


.favoritable-item {
	@include icon-fixed-right;
	margin-bottom:0;

	[class*="q-icon"] { display:none; }

	.q-icon-star { display:inline-block; }
	&:hover [class*="q-icon"], &.focus-within [class*="q-icon"], &:focus [class*="q-icon"] { display:inline-block; }
	&:focus-within [class*="q-icon"] { display:inline-block; }
}

explorer-scorecard {
	.q-icon-star-outline { display:none; }
	&:hover [class*="q-icon-star"], &.focus-within [class*="q-icon"], &:focus [class*="q-icon"] { display:inline-block; }
	&:focus-within [class*="q-icon-star"] { display:inline-block; }
}

.ease-bubble { color: $white; }


conversation {
	display: flex;
	flex-direction: column;
	overflow: hidden;
	flex: 1 1 100%;
}

.transcript-controls {
	position: relative;

	&, > div {
		@include flex-center;
	}
}



.main-panel {
	.dropdown-menu {
		min-width: 250px;
		max-width: 350px;
	}
}

.conversation-filter {
	flex: 1 1 auto;
	max-width: 240px;

	select-from-hierarchy { width: 100%; }
}

.document-metrics {
	flex: 1 1 auto;
	button {
		color: inherit;
	}
}

.document-metric {
	cursor: default;
	+.document-metric { padding-left: $spacing-4; }
}



.sentence-metadata, .sentence-icons, .sentiment-icons {
	display: flex;
	align-items: center;
}

.sentence-metadata { justify-content: space-between; }

.explorer-filters {
	@include flex-center;

	> div {
		@include flex-center;
		margin-right: $spacing-4;
	}
}









.q-tabs {
	> li {
		background: var(--gray-200);
		overflow: hidden;
		flex: 1 0 auto;

		&::before {
			position:absolute;
			z-index: 1;
			top:0;
			left:0;
			width: 100%;
			height: 2px;
			content: ' ';
			background: var(--gray-600);
		}

		&.active {
			background: var(--white);
			padding-bottom: 1px;
			border-bottom: 0 !important;
			z-index: 1;
		}

		[ngbnavlink] {
			font-weight: 700;
			text-transform: none !important;
		}
	}

	&.fade-container:before, &.fade-container:after { top: 0;}
}

.q-tabs-separator::after {
	content: ' ';
	position: absolute;
	width: 100%;
	background: var(--gray-600);
	height: 1px;
	margin-top: -1px;
}

tabbed-context-pane {
	--tab-border-color: var(--gray-300);
	.attributes-panel .br-document-preview-attribute {
		margin-left: 0;
		margin-right: 0;
	}

	.tab-pane.active {
		display: flex;
		flex-direction: column;
		overflow: hidden;
		flex: 1;
	}
}
