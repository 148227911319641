.flip-container {
	perspective: 1000;

	.flipper {
		transition: 0.6s;
		transform-style: preserve-3d;

		position: relative;
		height: 100%;
		width: 100%;
	}

	.front, .back {
		backface-visibility: hidden;
		transition: 0.6s;
		transform-style: preserve-3d;
		transform: rotateY(0deg);
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 2;
	}

	.back { transform: rotateY(-180deg); }

	.flip .back { transform: rotateY(0deg); }
	.flip .front { transform: rotateY(180deg); }
}

@media #{$media-query-mobile} {
	.flip-container {
		.front, .back { position: relative; }
	}
	.single-widget-container .flip-container {
		.front, .back { position: absolute; }
	}
}
