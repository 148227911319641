.br-drivers-dialog {

	inclusion-list {
		.item-list {
			min-height: 270px;
		}
	}

	.include-topic-sentiment, .include-topic-names, .include-topic-effort {
		display: flex;
		align-items:center;
	}

	.single-match-mode {
		line-height: 30px;
		margin-right: 5px;
	}

	option.q-icon-warning:before {
		margin-right: $spacing-1;
	}
	option.icon-danger:before {
		color: var(--danger-600);
	}

	.remove-warnings-btn {
		margin-left: -100%;
		z-index: 2;
	}

}

.br-drivers-grid {
	.slick-row {
		.drivers-execute, .drivers-edit {
			display: none;
		}
	}

	.slick-row:hover {
		.cell-actions a {
			display: inline-block;
			text-decoration: underline;
		}
	}
}

.dataset-stats-content {
	overflow: visible;

	.warning-low { font-style: italic;}
	.warning-high { color: var(--danger-600); }
}

select > optgroup:first-of-type {
	padding-top: $standard-grid-padding;
}

.drivers-results-table {
	overflow-y: auto;
	overflow-x: hidden;
	height: 400px;

	table {
		border-collapse: collapse;
		width: 100%;
	}

	th, td {
		padding: $spacing-2 $spacing-4 $spacing-2 0;
	}

	th { font-weight: normal; }

	.separator {
		height: 15px;
		padding-left: 15px;
	}
}

.select-all-drivers {
	position: absolute;
	top: 10px;
	z-index: 2;
	left: 9px;
}

.stats-text-area {
	margin:0;
	width: 100%;
	height:300px
}

option[class*=q-icon] {
	font-size: 1rem;
}
