$qualtrics-blue: #3A88E5;
$qualtrics-dark-blue: #1A57C2;
$qualtrics-purple: #5F1AE5;


.sign-in-page-bg {
	overflow: auto;
	height: 100%;
	width: 100%;
	background: var(--gray-100);
	position: absolute;

	&.bg-image {
		background-repeat: no-repeat;
		background-position: center center;
		background-attachment: fixed;
		-o-background-size: 100% 100%, auto;
		-moz-background-size: 100% 100%, auto;
		-webkit-background-size: 100% 100%, auto;
		background-size: cover;
	}
}


.in-iframe {
	login {
		height: 100%;
		display: flex;
	}

	.sign-in-page-bg {
		background: var(--white-100);
	}

	.sign-in-page-container {
		align-items: center;
		display: flex;
		flex: 1 1;
		justify-content: center;
		max-width: none;
		margin:0;
	}

	.container {
		width: auto;
	}
}

.sign-in-page-container {
	margin-top: 12%;
	max-width: 432px;
	position: relative;
}

.mt-136-important {
	margin-top: 136px !important;
}

.sign-in-page, .sign-in-page-info-panel {
	font-size: #{var(--font-size-default)};

	.sign-in-container, .info-panel-container {

		.login-wrapper {
			border-radius: 8px;
			border: 1px solid var(--gray-300);
			overflow: hidden;
		}

		.sign-in-message {
			color: $white;
			min-height: 32px;
			font-size: var(--font-size-default);
			padding: $spacing-2 $spacing-3;
			letter-spacing: 0.3px;
			border-top-left-radius: $standard-border-radius;
			border-top-right-radius: $standard-border-radius;
			line-height: $spacing-5;

			a {
				color: $white;
			}

			&.info {
				background: var(--blue-700);
			}

			.align-items-center {
				justify-content: center;
			}
		}

		.login-form {
			background: $white;
			padding: $spacing-5;
			border-radius: inherit;

			.form-group {
				margin-bottom: $spacing-4;

				label {
					margin-left: $spacing-1;
					font-weight: 700;
				}

				input {
					border-radius: $standard-border-radius;
					border: 1px solid var(--gray-900);
				}

				&.password {
					margin-bottom: $spacing-4;
				}
			}
		}

		.sign-in-options {
			margin-top: $spacing-5;

			a {
				color: var(--gray-900) !important;
				font-weight: 700;
			}

			.remember-me {
				i {
					font-size: var(--font-size-1_125rem);
					vertical-align: middle;
					margin-right: $spacing-3;
				}
			}
		}

		.image-container{
			background: $white;
			width: 100%;
			margin: 0 auto 0;
			text-align: center;
			padding-top: 24px;

			img { height: 36px; }
		}
	}
}

.policy-container {
	text-align: center;
	font-size: var(--font-size-default);
	margin-top: $spacing-6;
}

.img-responsive {
	display: inline-block;
}

#in-username:focus + span {
	display: none;
}

/*
@media screen and (min-width: 100px) and (max-width: 992px){

}
*/
/* Smartphones (portrait) ----------- 320px */
@media only screen and (max-width : 400px) {

	.sign-in-page .sign-in-container .login-form {
		padding: $spacing-3;
	}

	.sign-in-options a{
		border: none;
		width: 100%;
		text-align: left;
	}

	.sign-in-options .checkbox{
		width: 100%;
	}

	.sign-in-page-container{
	   margin-top: 10%;
	}
}

/* iPads (landscape) ----------- */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
	.sign-in-page-container{
		margin-top: 20%;
		width: 90%;
	}
	.sign-in-options .checkbox {
		width: 50%;
	}
	.sign-in-options a {
		width: 50%;
	}
}

/* iPads (portrait) ----------- */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
	.sign-in-page .sign-in-container{
		width: 100%;
	}
	.sign-in-page .right-container{
		width: 100%;
	}
}


// this page gets its own button colors
.sign-in-page-container .btn-primary {
	&, &:focus {
		background: $qualtrics-blue;
		border-color: $qualtrics-blue;
	}

	&:hover {
		color: $white !important;
	}

	&:active {
		background: $qualtrics-dark-blue;
		border-color: $qualtrics-dark-blue;
	}
}

.hover\:border-qualtrics-dark-blue:hover {
	border-color: $qualtrics-dark-blue !important;
}

// TODO: If upgrading to bootstrap 4.2 we could use .mt-n1 or so.  Check ng-bootstrap - 20200413
#new-password {
	margin-bottom: -1px;
}

#btn-generate {
	width: 175px;
}
