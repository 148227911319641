
.SELECTOR_BUTTON {
	.br-widget-view { overflow: auto;}

	&:not(.header-hidden) .selector-widget { padding-top: $spacing-3; }
	.selector-widget { padding: $spacing-3; }

	.br-widget-total { display: none; }
}

selector-widget-button {
	max-width: 100%;
	overflow: hidden;
	padding: $spacing-2;
	margin: -$spacing-2;

	div {
		border: 1px solid transparent;
		border-radius: 2em;
		font-family: inherit;
		padding: $spacing-2 0;
		overflow: hidden;
		text-align: center;
	}

	.btn-pattern-fills {
		width: $spacing-5;
		height: $spacing-4;
		align-self: center;
	}

	.btn-circle {
		transform: rotate(180deg);
		transform-origin: 8px 8px;
	}

	.btn-name {
		font-family:inherit;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.has-linked-widgets & div { cursor: pointer }
	.filter-applied &:not(.selected) div:not(:focus) { opacity: .3; }

	.selector-widget.flex-direction-column & {
		display: flex;
		flex: 1 1 auto;

		div {
			margin-top: $spacing-3;
		}
	}
}

selector-widget-droplist {
	width: 100%;

	.selector-pill {
		padding: $spacing-2 $spacing-3;
	}

	button.dropdown-toggle {
		justify-content: center;
		padding-right: $spacing-3 !important;
		font-size: var(--font-size-default);

		.caret {
			color: inherit !important;
			margin-top: $spacing-1;
			position: relative;
			right: 0;
		}
	}

	div.dropdown-menu {
		width: 100% !important;
		max-width: none !important;
	}

	.dropdown.fake-select button:not(.ignore-dropdown-style),
	.fake-select > button:not(.ignore-dropdown-style) {
		border-radius: 2em;
	}

	.dropup .dropdown-menu {
		bottom: 100%;
	}
}

// equal size buttons
.selector-widget.flex-direction-column .equal-size-buttons selector-widget-button { width: 100%; }


// justified
.justify-buttons {
	justify-content: space-between;
	selector-widget-button {
		display: flex;
		flex: 1 1 auto;

		> div {
			display: flex;
			flex: 1 1 auto;
		}
	}
}

$real-data-preview-banner-height: 63px;

.selector-widget-preview {
	real-data-preview {
		padding: 0 !important;

		#preview-bar {
			margin-top: 0 !important;
			margin-bottom: 8px !important;
		}
	}

	#real-data-preview {
		.chart-demo {
			margin-top: 0;
		}
	}
}

.selector-widget-preview {
	display: flex;
	flex: 1 1;

	> div {
		height: 100%;
		min-height: 320px;
	}
}

selector-widget-search, .selector-widget-preview {

	.selector-widget {
		margin: $spacing-4;
		margin-top: 0; // required to not truncate "Search" button, but keep dropdown aligned to the child borders
		height: calc(100% - #{$spacing-4});
	}

	multiselect, old-multiselect {
		display: flex;

		.multiselect {
			width: 100%;
			.dropdown {
				height: 100%;
			}
		}

		.selector-search-container {
			margin-top: $spacing-4;
			height: calc(100% - #{$spacing-4});
			border: 1px solid var(--gray-900);

			.selector-title {
				position: absolute;
				left: $spacing-4;
				top: -$spacing-4;
				display: inline-flex;
				align-items: center;
				position: relative;
				height: auto;
				min-height: 32px;
				padding-top: 0;
				padding-bottom: 0;
				border: 1px solid var(--gray-400);
			}

			.selector-search-tags {
				padding: 0 $spacing-4;
			}
		}
	}
}

multiselect-options {
	&.hide-checkbox input[type="checkbox"] { display: none; }
	&.hide-selections {
		.multiselect-item.active, .divider { display: none; }
	}
}
