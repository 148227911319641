$structure-lines-color: var(--gray-600);

.hierarchy-selector-group { position:relative; }

.hierarchy-selector {
	border: 0;
	border-radius: $dropdown-border-radius;
	@include box-shadow($dropdown-box-shadow);
	max-width: 500px;
	position:absolute;
	z-index:1;

	&.right { right:0; }
	> div {
		padding:0;

		~ div {padding-bottom:0;}
		> hierarchy-group > ul { padding-left:0;}
	}

	.hierarchy-list {
		max-height:450px;
		overflow:auto;

		border-top: 1px solid var(--gray-600);
	}
}

.hierarchy-context { border-top: 1px solid var(--gray-600); }
.node-hierarchy {
	font-size: var(--dropdown-font-size);
	line-height: $dropdown-line-height;
	padding-left: $spacing-4;

	.br-hierarchy-node > a {
		@include flex-center;
		line-height: $dropdown-line-height;
		padding: #{($dropdown-item-target-height - $dropdown-line-height) * 0.5} $spacing-3;
		padding-left: 10px;
	}
}

// TODO these selectors are not maintainable and very fragile, need to use class-based styling
.node-hierarchy {
	margin-left: $spacing-4;

	>hierarchy-node > li {
		border-left: 1px solid $structure-lines-color;
		margin-left: -1 * $spacing-4;
		white-space:nowrap;

		&.leaf {
			border-left-color: transparent;
			> a { padding-left: $spacing-5; }
		}

		> a { margin-left: -1 * $spacing-4 }
	}

	>hierarchy-node:last-child > li { border-left-color: transparent; }
	&.single-child > li { border-left-color: transparent; }
}
// alignment when the nodes are a combination of leaves and non-leaves
.mixed-nodes {
	> hierarchy-node > li:not(.leaf) { margin-left: -23px; }

	.mixed-nodes li:not(.leaf) > a {
		margin-left: -10px;
		padding-left: 4px;

		.structure-bottom-line,
		.structure-top-line { left: 9px; }
		.structure-side-line { left: 15px;}
	}
}


// hide some structure lines when user has multiple root nodes
.multiple-root-nodes {
	.mixed-nodes > hierarchy-node >li:not(.leaf) { margin-left: 0; }
	>.node-hierarchy > hierarchy-node > li { border-left: 0; }
 }

 .multiple-root-nodes .mixed-nodes > hierarchy-node > li > a {
	.structure-bottom-line,
	.structure-top-line,
	.structure-side-line,
	.structure-lines { display: none; }
 }

hierarchy-group[group-level="1"] > ul > hierarchy-node > li {
	margin-left: -1px;

	// don't show hierarchy structure if search yields only 1 result
	&:first-child:last-child > a > .structure-lines { display: none; }
}

.node-hierarchy-collapsed li { display:none; }

.br-hierarchy-node-toggle:before {
	font-size: var(--font-size-0_875rem);
	font-family: var(--qualtrics-icons);

	[selected] > a & { color: $white; }

	.node-hierarchy & { font-family: var(--qualtrics-icons) }
}

.node-hierarchy-collapsed > a .br-hierarchy-node-toggle:before { content: "\f729"; }
.node-hierarchy-expanded > a .br-hierarchy-node-toggle:before { content: "\f13b"; }





/*organization hierarchy*/
.br-hierarchy-node-name {
	.hierarchy-item:hover & { color:inherit }

	submenu-flyout & {
		white-space: nowrap;
		max-width: 500px;
		text-overflow: ellipsis;
		display: inline-block;
		overflow:hidden;
	}
}

.br-hierarchy-node {
	.br-hierarchy-node-name {
		text-overflow: ellipsis;
		overflow: hidden;
		display: flex;
		flex: 1;
	}
}

.hierarchy-search {
	position:relative;

	input.hierarchy-search-box { font-size: $dropdown-searchbox-font-size; }
}

.hierarchy-item {
	align-items: center;
	display:flex;

	.hierarchy-item-template {
		align-items: center;
		cursor: pointer;
		display: flex;
		width: 100%;
	}

	input[type="checkbox"] {
		height: 1em;
		margin:0 $spacing-3 0 0;
		width: 1em;
		min-width: 1em;

		.br-hierarchy-node-toggle + .hierarchy-item-template {
			width: calc(100% - 40px);
		}

		.hierarchy-item-template, .br-hierarchy-node-toggle + .hierarchy-item-template {
			width: calc(100% - 40px);
		}
	}
}

.searchable-hierarchy-list-limited-width .hierarchy-item-text{
	display: flex;
	width: 100%;
}

.searchable-hierarchy-root {
	// probably can move this up to the .hierarchy-item section above
	// will do small refactor on this later.
	.hierarchy-item.disabled {
		.hierarchy-item-template {
			cursor: not-allowed;
		}
		text-decoration: line-through;
		color: var(--gray-400);
		cursor: not-allowed;
	}
	.hierarchy-item.grey-out {
		color: var(--gray-400);
	}
}

.topic-inclusion-list, .alert-hierarchy-selection, .model-selection-box {
 	.hierarchy-item {
		.hierarchy-item-template {
			display: flex;
			margin-top: (-1 * $spacing-3);
			margin-bottom: (-1 * $spacing-3);
			padding-bottom: $spacing-3;
			padding-top: $spacing-3;
		}

		input[type="checkbox"] + .br-hierarchy-node-toggle + .hierarchy-item-template {
			width: calc(100% - 80px);
		}

		input[type="checkbox"] + .hierarchy-item-template, .br-hierarchy-node-toggle + .hierarchy-item-template {
			width: calc(100% - 80px);
		}
	}
}

.mobile-layout {
	.hierarchy-selector { position:relative;}
}











// structural lines

$vertical-structure-line: "<line stroke-width='2' x1='0' x2='0' y1='0' y2='12' stroke='#{encodecolor($structure-lines-color)}'></line>";
$horizontal-structure-line: "<line stroke-width='2' x1='0' x2='12' y1='0' y2='0' stroke='#{encodecolor($structure-lines-color)}'></line>";

$vertical-structure-line-selected: "<line stroke-width='2' x1='0' x2='0' y1='0' y2='12' stroke='#{encodecolor($white)}'></line>";
$horizontal-structure-line-selected: "<line stroke-width='2' x1='0' x2='12' y1='0' y2='0' stroke='#{encodecolor($white)}'></line>";


.structure-lines {
	height: $dropdown-item-target-height;
    width: $spacing-4;
	position: absolute;
	left: $spacing-3;

	background-image: url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg'><line stroke-width='2' x1='0' x2='0' y1='0' y2='32' stroke='#{encodecolor($structure-lines-color)}'></line><line x1='0' x2='12' y1='16' y2='16' stroke='#{encodecolor($structure-lines-color)}'></line></svg>");

	[selected] > a & {
		background-image: url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg'><line stroke-width='2' x1='0' x2='0' y1='0' y2='32' stroke='#{encodecolor($white)}'></line><line x1='0' x2='12' y1='16' y2='16' stroke='#{encodecolor($white)}'></line></svg>");
	}
}

.last-leaf {
	background-image: url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg'><line stroke-width='2' x1='0' x2='0' y1='0' y2='16' stroke='#{encodecolor($structure-lines-color)}'></line><line x1='0' x2='12' y1='16' y2='16' stroke='#{encodecolor($structure-lines-color)}'></line></svg>");

	[selected] > a & {
		background-image: url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg'><line stroke-width='2' x1='0' x2='0' y1='0' y2='16' stroke='#{encodecolor($white)}'></line><line x1='0' x2='12' y1='16' y2='16' stroke='#{encodecolor($white)}'></line></svg>");
	}
}

.structure-top-line, .structure-bottom-line, .structure-side-line { position: absolute; }

.structure-top-line, .structure-bottom-line { height: 12px; width: 2px; left: 15px; background-size: 2px 12px; }

.structure-top-line {
	background-image: url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg'>#{$vertical-structure-line}</svg>");
	top: 0;

	[selected] > a & {
		background-image: url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg'>#{$vertical-structure-line-selected}</svg>");
	}
}

.structure-bottom-line {
	background-image: url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg'>#{$vertical-structure-line}</svg>");
	bottom: 0;

	[selected] > a & {
		background-image: url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg'>#{$vertical-structure-line-selected}</svg>");
	}

	hierarchy-node:last-child > li > a & { display: none; }
}

.structure-side-line {
	background-image: url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg'>#{$horizontal-structure-line}</svg>");
	height: 2px;
	width: 6px;
	top: 16px;
	left: 20px;
	background-size: 6px 2px;

	[selected] > a & {
		background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'>#{$horizontal-structure-line-selected}</svg>");
	}
}

.level-selector {
	width: min-content;
}
