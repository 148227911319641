grouping-configurer-cog { display: inline-block; }
// #settings .form-group {
// 	width:100%;
// }

#cb-modal-overlay {
	position: absolute;
	top: 55px;
	right:0;
	bottom: 56px;
	left:0;
	background-color: rgba(0, 0, 0, .5);
	z-index: 1000;
}

.axis-selector {
	float:left;
}


.adv-chart-settings-field {
	max-width: #{($input-padding-horizontal * 2) + ($spacing-6 * 2)} ;
}


.has-axis {
	white-space: nowrap;
}

.visual-left {
	z-index: 5;
}




#in-num-period {
    vertical-align:middle;
}


.props-widget-name {
	font-size: var(--font-size-1_675rem);
	text-transform: none;
	line-height: $input-height;
	margin-bottom: 0;
}




.color-swatch {
    display:  block;
	float: left;
	height: 1em;
	width: 1em;
    background-color: var(--gray-600);
    margin-right: 1px;
}

.br-selected-color {
	padding-top: $spacing-2;
	padding-bottom: $spacing-2;
}


.br-secondary-point-color-list .color-swatch,
#sel-dual-point-color + .dropdown-menu .color-swatch,
#sel-trend-point-color + .dropdown-menu .color-swatch,
#sel-dual-secondary-point-color + .dropdown-menu .color-swatch {
    width: 19%;
    background-color: var(--gray-600);
    margin-right: 1%;
    padding-top:19%;
}


.br-color-dropdown {
	.hierarchy-item {
		overflow: hidden;
		>div { white-space: nowrap; }
	}

	.hierarchy-item-template {
		&, > span { width:100%; }
	}

	button { overflow:hidden; }
}

.inherit-palette .br-color-dropdown {
	display: none;
}
.highcharts-data-labels tspan {cursor:pointer}

.br-color-cog-menu {
	display: inline-block;
	top: 30%;
    left: unset;
    margin-left: 8px;
}
