.panel { margin-bottom: $spacing-4; }

.panel-default>.panel-heading, .panel-default>.panel-footer {
	background: var(--gray-300);
	border-color:var(--gray-500);
	height: 3.5rem;
	align-items: center;
	color:var(--gray-1000);
	display: flex;
}

.popup-header {
	background: var(--gray-300);
	border-color:var(--gray-500);
	color:var(--gray-1000);
}

$panel-body-padding: $spacing-6;
.panel-body {
	color:var(--gray-900);
	padding: 2rem;
}
.panel-heading {
	padding: $spacing-3 $spacing-4;

	h3 {
		margin-bottom: 0;
		letter-spacing: 0;
	}
}

collapsing-panel {
	&.compact panel-body > *:last-child { margin-bottom: (-1 * $spacing-4); }

	&.mb-0 .panel { margin-bottom: 0;}

	.panel-heading { border-radius: inherit; }
	.panel-heading.expanded {
		background-color: var(--gray-300) !important;//  important to override the important in line 33;
		border-bottom-left-radius:0;
		border-bottom-right-radius: 0;
	}

	.panel-heading:not(.disable-collapse) {
		background-color: var(--white) !important;// important to override the more specific panel-default colors

		&:hover {
			background-color: var(--gray-200) !important;//  important to override the important in line 33;
		}
		&:focus, &:active {
			outline: solid 2px var(--effort-easy-900); // color in figma is #1f4186
			outline-offset: var(--default-outline-offset);
			position: relative;
			z-index: 1;
		}
	}
}

panel-heading { display: flex; }

// panel spacing with no panel coloring
.panel-spacing-only {
    box-shadow: none;
}

h4.panel-title { text-transform: none; }
[uib-accordion-header], .accordion .card-header {
	display: flex;
	align-items:center;
	justify-content: space-between;

	> * {
		display: flex;
		align-items: center;
	}
}

a:focus { outline:none; }

$table-row-height: 2.5rem;
$table-font-size: var(--font-size-default);
$table-line-height: $default-line-height;
$table-border-width: $standard-border-width;


.table {
	font-size: $table-font-size;

	th {
		color: var(--gray-900);
		font-size: var(--font-size-default);
	}

	>thead, >tbody, >tfoot {
		>tr {
			>th, >td {
				border-color:var(--gray-200);
				line-height:$table-line-height; // padding in one line doesn't seem to work
				// just using rem directly as I can't figure this out
				padding-top:(($table-row-height - ($table-line-height * 1rem)) * 0.5) - 0.0625rem;
				padding-right: $spacing-3;
				padding-left: $spacing-3;
				padding-bottom: ($table-row-height - ($table-line-height * 1rem)) * 0.5;
				vertical-align: middle;
			}
		}
	}

	td > input {
		&[type="checkbox"], &[type="radio"] { margin:0; }
	}

	tr.even { background: $table-alternate-row-color }
}

th simple-dropdown {
	font-weight: normal;
}

.form-control { @include box-shadow(none); }
.form-group { margin-bottom: $spacing-4; }

.select2-container .select2-choice, .cg-busy-default-text { color:inherit; }


.popover {
	border-radius: $dropdown-border-radius;
	border: none;
	@include box-shadow($dropdown-box-shadow);
	padding:0;
}

$popover-vertical-padding: $spacing-3;
$popover-horizontal-padding: $spacing-3;

.popover-content {
	padding: $popover-vertical-padding $popover-horizontal-padding;
	background-color: var(--white);
	color: var(--gray-1000);
}

%chip-style {
	background:var(--gray-600);
	border-radius:$standard-border-radius;
	border:none;
	color:$white;
	cursor:initial;
	font:14px / 1.5 var(--font-standard);
	height:20px;
	user-select: none;
}

@mixin metric-label-tags {
	@include box-shadow(none);
	padding: 0;
	resize: both;
	width: 100%;
}

@mixin metric-label-tag {
	background: var(--gray-600);
	border-radius: $standard-border-radius;
	border: none;
	color: $white;
	cursor: initial;
	height: 20px;
	user-select: none;
	max-width: calc(100% - #{$spacing-3});
}

/* tag input */
tags-input, ng-tags-input {
	border: none;
	float: left;
	min-width: 350px;

	> .host { margin:0;}

	.tags {
		@include metric-label-tags;
		min-height: $input-height;
		border: $standard-border-width solid $input-inactive-border-color;
		border-radius: $standard-border-radius;

		.tag-item {
			@include metric-label-tag;
			margin: 2px;
			padding: 0 4px;
			font: 14px / 1.5 normal;

			.remove-button {
				margin: 0 $spacing-2 0 0;
				float: left;
				font: inherit;
				color: var(--gray-200);
				vertical-align: initial;

				&:hover, &:active { color: $white; }
			}
		}

		.input {
			margin:0;
			min-height: calc(2.5 * var(--input-font-size));
			height: 2.5rem;
			padding: var(--input-padding);
			border: 0;
			outline: 0;
		}

		&.focused {
			@extend %no-box-shadow;
			// border-color: $input-focus-border-color
		}

		transition: border-color ease-in-out $css-animation-duration, color ease-in-out $css-animation-duration;

		&.disabled, &[disabled], &[readonly], &[disabled] .form-control {
			background: $input-disabled-bg-color;
			border: $standard-border-width solid $input-disabled-border-color;
		}

		.tag-item.selected {
			background: var(--danger-600);
		}
	}

	%no-box-shadow {
		@include box-shadow(none);
	}


	&.ng-invalid {
		&.ng-touched { border-color: var(--danger-600); }
		.tags, &.ng-touched .tags { @extend %no-box-shadow; }
	}

	ti-tag-item {
		> ng-include {
			display: flex;
			flex-direction: row-reverse;
		}
		span {
			flex: 1 1 auto;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
	}

	.autocomplete {
		background: var(--white);
		padding: $spacing-3 0;
		border-radius: $dropdown-border-radius;
		border: none;
		@include box-shadow($dropdown-box-shadow);

		.suggestion-item {
			&, & em {
				color: var(--gray-900);
				background: var(--white);
			}
		}
	}
}

.custom-math-tooltip-labels {
	@include metric-label-tags;

	.metric-label {
		@include metric-label-tag;
		padding: 2px;
		margin: 1px;
	}
}

$toggle-transition-time: $css-animation-duration;

.toggle-switch {
	$standard-height: 24px;
	$standard-width: 48px;
	$standard-knob-size: 20px;

	$small-height: 20px;
	$small-width: 40px;
	$small-knob-size: 16px;


	$xsmall-height: 16px;
	$xsmall-width: 32px;
	$xsmall-knob-size: 12px;


	border: none;
	height: $standard-height;
	width: $standard-width;
	min-width: auto;
	position:relative;
	border-radius: #{$standard-height * 0.5};

	> div { position:relative; width:100%; height:100%; }
	> .switch-off {
		left: auto;

		> .knob { right: calc(100% - #{$standard-knob-size + 2px}); }
	}

	.switch-left, .switch-right { display: none; }

	.knob {
		border: none;
		background:$white;
		border-radius: 50%;
		height:$standard-knob-size;
		width:$standard-knob-size;
		position:absolute;
		left:auto;
		right:2px;
		top:2px;
		transition: all $toggle-transition-time;
	}

	&.sm {
		height: $small-height;
		width: $small-width;
		> .switch-off > .knob { right: calc(100% - #{$small-knob-size + 2px}); }
		.knob {
			height:$small-knob-size;
			width:$small-knob-size;
		}
	}

	&.xs {
		height: $xsmall-height;
		width: $xsmall-width;

		> .switch-off > .knob { right: calc(100% - #{$xsmall-knob-size + 2px}); }
		.knob {
			height:$xsmall-knob-size;
			width:$xsmall-knob-size;
		}
	}

	&.disabled {
		>div>span.knob { background: $white; cursor: $disabled-cursor; }

		.switch-off, .switch-on {
			cursor: $disabled-cursor;
			opacity:$fade-opacity;
		}
	}

	.slick-row.selected &.switch-on { outline: 1px solid $white; }
}

.toggle-switch-animate {
	transition: all $toggle-transition-time;
}

$tab-multiline-wrap-margin: $spacing-3;



.nav-tabs:not(.q-tabs), .nav-tab-bar {
	> li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus,
	.nav-link.active {
		color:var(--action-900) !important;
		background: none !important;
		--tab-border-color: var(--action-900);
	}
	> li:not(.active) > a:hover, li:not(.active) > a:focus, li:not(.active) > nav-link:hover {
		background: none !important;
		color: var(--action-800) !important;
		--tab-border-color: var(--action-800);
	}

	> li:not(.active) > a:active, > li:not(.active) > .nav-link:active {
		color: var(--action-900) !important;
		background: none !important;
	}
}

.nav-tab-bar, .nav-tabs {
	--tab-border-color: transparent;

	display: block;
	clear: both;
	font-size: var(--font-size-default);
	border-bottom: 0;
	margin-top: (-1 * $tab-multiline-wrap-margin);
    margin-bottom: $spacing-5;
	text-transform: uppercase;
	@include no-select();

	> li {
		margin-top: $tab-multiline-wrap-margin;
		border-bottom: 2px solid var(--gray-300) !important;
		margin-bottom: -1px;
		height: 3.125rem;
		line-height: 1.5;
	}

	> li > a, a.nav-link {
		border-radius: 0;
		background:none;
		border:0 !important;
		border-bottom:2px solid var(--tab-border-color) !important;
		color:var(--gray-1000);
		cursor: pointer;
		float: left;
		margin-bottom:.25em;
		padding:0 1em;
		text-transform: capitalize;
		display: flex;
		align-items: center;
		font-weight: var(--button-font-weight);
		margin-left: 0;
		margin-right: 0;
		// constant height allows tabs to wrap cleanly on smaller screens
		height: 3.125rem;

		&:focus { background: none; }
	}

	> li.disabled > a, li[disabled] > a {
		&, &:hover, &:focus {
			color: var(--gray-600);
			cursor: not-allowed;
		}
	}
}

// for tabs that are contained within an outer wrapper of some sort
.nav-tabs-inner, .sub-tabs {
	.nav-tab-bar, .nav-tabs { margin-bottom: $spacing-3; }
}

.uib-datepicker, .uib-datepicker-popup, .filter-date-wrap, .cx-date-filter-test-wrap {
	.well { background:transparent; border-color:transparent; box-shadow:none;}

	button {
		&, &:focus, &:active, &:hover { border-radius: $standard-border-radius !important; box-shadow:none; outline:none; }
	}

	th > button, td > button {
		&, &.disabled, &[disabled] {border-color:transparent;}
	}

	.btn-group {
		padding:0;

		button { margin-left:0; margin-right: $button-spacing-between;}
	}

	input, select { border:none; }
}

// layer body-appended popovers above modals
%body-modal-zindex { z-index: 1050; }
body > [uib-popover-template-popup] { @extend %body-modal-zindex }
body > ngb-popover-window,
body > ngb-popover-window.popover {
	display: block;
	@extend %body-modal-zindex;
}

// layer ngbootstrap menu above modal
body > div.dropdown,
body > div.dropup { z-index: 1060 !important; }

// pair with [ngBusy] to allow subsections to show loading indicators
.busy-panel .panel-body {
	position: relative;
	overflow: hidden;
}

/*
 * To have angularjs error dialogs on top of ng dialogs
 */
.error-dialog {
	z-index: 2050 !important;
}

.error-dialog-backdrop {
	z-index: 2040 !important;
}

/* 3rd party carets and sort indicators */
.caret, .br-grid span.slick-sort-indicator, .slick-sort-indicator {
	font-family: "qualtrics-icons";
	background: none;
	top: calc(50% - 8px);
}

.caret:before, .slick-sort-indicator:before { font-size: var(--font-size-0_675rem); }

.dropup .caret:before, .slick-sort-indicator-asc:before { content: $menu-arrow-up; }
.caret:before, .slick-sort-indicator-desc:before { content: $menu-arrow-down; }

.caret, .dropup .caret {
	border: 0;
	width: auto;
	height: auto;
}

// these use shadow dom for styling so required !important to overwrite :/
rgba-input-component input, hex-input-component input { color: var(--gray-900) !important }


.ng-busy-backdrop { background: var(--loading-spinner-background); }




.slick-columnpicker {
	background: var(--white);
	padding: 8px 0;
	border: none;
	border-radius: 4px;
	box-shadow: 0 0 8px var(--black-50-percent);
	font-size: var(--font-size-default);

	label {
		padding:0 8px;
		display: flex;
		flex: 1;
		align-items: center;
		height: 32px;
		line-height: 1;
		margin:0;

		&:hover { background: var(--action-100); }
	}

	hr { margin: 4px 0;}

	input { margin: 0 8px 0 0; }
}
