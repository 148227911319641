
.pdf-export .beta-discussions .br-home-gridster {
    min-height:initial;
}


.mobile-layout {

    .beta-discussions .br-global-view {
        overflow: visible !important;
        height: 100%;
    }
}

@media screen and (min-width: 768px) {
    .beta-discussions .secondary-header {
        z-index: 1030;
    }
}












/* DASHBOARD DISCUSSION PANEL */
.discussion-wrapper{
    max-height: 400px;
    overflow: auto;
}

.edit .discussion-wrapper {
    max-height: 295px;
}

.discussion{
    margin-bottom: 10px;
    margin-top: 10px;
    display: block;
    border-bottom: solid var(--gray-600) 1px;
    padding:0 10px;
}

cb-avatar {
    .user-avatar { border-radius: 50%; }
    .fallback-avatar { background-color:var(--gray-400); }
}

.discussion-avatar {
    left:0;
    position:absolute;
    top:0;
}

.discussion-meta {position:relative; padding-left:50px;}

.discussion-date, .discussion-options { font-size: var(--font-size-0_875rem); }


.discussion-comment{
    font-size: var(--font-size-default);
    overflow-wrap:break-word;
}


.edit .discussion-panel.docked-panel { z-index: 1; }

.discussion-panel>.docked-trigger {
    top:225px;
}

.discussion-modal-trigger {
    position: absolute;
    z-index: 1029;
    right:0;
    top:175px;
    height: 30px;
    transform: rotate(-90deg);
 }

.discussion-panel, .discussion-modal{
    font-size:var(--font-size-default);
}


.discussion-comment-mention {
    font-weight:normal;
    font-style:normal;
    // text-decoration:none;
    display:inline-block;
}
.discussion-submission {
    height:100px;
    min-height:100px;
    overflow:auto;
}



[contenteditable=true]:empty:not(:focus):before{
    content:attr(data-ph);
    cursor:text;
}

.mention-link > div {
    overflow: hidden;
    white-space: normal;
    max-width: 300px;
}

.mention-title, .mention-subtitle {
    max-width:300px;
    overflow:hidden;
    text-overflow:ellipsis;
}

.mentions-up .dropdown-menu, .mentions-mobile .dropdown-menu {
    top: auto !important;
    bottom: 180px;
    max-height: 300px;
    overflow: auto;
}

.mentions-up .dropdown-menu {
    top:auto;
    bottom:110%;
}
// .discussion-comment-toolbar + .br-comment-panel { position:relative; }

.mentions-mobile {
    &.comments-0>.dropdown-menu { max-height: 100px; }
    &.comments-1>.dropdown-menu { max-height: 150px; }
    &.comments-2>.dropdown-menu { max-height: 200px; }

    .dropdown-menu { bottom: 165px;}
}

.br-mobile [floatable-widget] { display:none; }



/* short screen fix */

    @media (max-height:768px) and (min-width : 768px){
        .discussion-panel {
            height:85%;

            > .panel, discussion-widget, ng-include { height:100%; }
            .panel-heading {height:7%;}
            .panel-body { height:93%;}
        }

    }

/* end small screen fix */



.mobile-layout {
    .discussion-comment { font-size: .85em; }
    .discussion-date, .discussion-options { font-size: .8em; }
}
