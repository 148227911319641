:root {
	--input-font-size: var(--font-size-default);
	--input-padding-horizontal: 1rem;
	--input-padding-vertical: 0.5rem;
	--input-padding: var(--input-padding-vertical) var(--input-padding-horizontal);
	--input-default-height: 2.5rem;
}

%base-input-style {
	height: var(--input-default-height);
	padding: var(--input-padding);
	font-size: var(--input-font-size);
	line-height:1;
}

%disabled-input-options {
	&.show-value-disabled[disabled] {
		-webkit-appearance:none;
		-moz-appearance:none;
		-ms-appearance:none;
		border:1px solid transparent;
		background:none;
		color: $input-focus-text-color;
		padding-left:0;
		padding-right:0;
	}

	&.hide-disabled[disabled] { display:none; }
}

// simple reset for ios inputs
input {
	&:not([type="radio"]):not([type="checkbox"]) {
		-webkit-appearance: none;
	}
}

$button-active-background-color: var(--action-800);

select, .fake-select > button:not(.ignore-dropdown-style), .nav-pills>li.read-only>a, .nav-pills>li.read-only>a:hover, .nav-pills>li.read-only>a:focus,
.form-control, input:not([type="checkbox"]):not([type="radio"]), .input {
	@extend %base-input-style;
	@extend %disabled-input-options;
}

.tags {
	&.tags-flex {
		display: flex;
		flex-wrap: wrap;
		align-content: flex-start;
		input.input {
			min-width: 2em;
		}
	}
	span.input {
		@extend %base-input-style;
		max-width: 100%;
	}
}

.nav-pills>li.read-only > a {
	overflow:hidden;
	text-overflow:ellipsis;
	white-space:nowrap;
}

// select does not display the same and needs padding adjustment
select {
	&, &.form-control, .form-control > & {
		padding-top:5px;
	}

	&[multiple] option {
		line-height:1;
		margin-left:#{-1 * $input-padding-horizontal};
		margin-right:#{-1 * $input-padding-horizontal};
		padding: #{$input-padding-vertical - 1}	$input-padding-horizontal;
	}

	max-width: 100%;
}

option {
	&[disabled]:hover { cursor: not-allowed; }
}

%input-text-colors {
	color: $input-inactive-text-color;
	&::placeholder { color: $input-placeholder-text-color; }

	&:focus {
		color:$input-focus-text-color;
		&::placeholder { color: $input-focus-text-color; }
	}

	&.disabled, &[disabled], &[readonly], &[disabled] .form-control {
		color: $input-disabled-text-color;
		cursor: default;

		&::placeholder { color: $input-disabled-text-color; }
	}
}

textarea, input, select, .dropdown.fake-select button:not(.ignore-dropdown-style),
.form-control, .input, .fake-select > button:not(.ignore-dropdown-style), text-angular .ta-scroll-window
{
	border-radius: $standard-border-radius;
	border: 1px solid $input-inactive-border-color;

	@extend %input-text-colors;

	transition: border-color ease-in-out $css-animation-duration, color ease-in-out $css-animation-duration;

	&.disabled, &[disabled], &[readonly], &[disabled] .form-control {
		cursor: not-allowed;
		background: $input-disabled-bg-color !important;
		// border: $standard-border-width solid $input-disabled-border-color;
		opacity: .9;
	}
	&:focus {
		outline: solid 2px var(--focus-outline-color);
		outline-offset: -2px;
	}
}

// fix for firefox bug where descenders (g, j, y) text is cut off partially
select {
	display: flex;
	padding-top: 0;
	padding-bottom:0;
}

.dropdown.fake-select {
	flex-grow: 1;
	&:not(.open):not([ngbdropdown]) {
		overflow:hidden;
	}

	button:not(.ignore-dropdown-style) {
		display: flex;
		align-items: center;
		background: var(--white);
		position: relative;
		width:100%;
		height: auto;
		min-height: 2.5rem;
		font-size: var(--font-size-default);
		padding: var(--input-padding);

		// fix for firefox bug where descenders (g, j, y) text is cut off partially
			padding-top:0;
			padding-bottom:0;

			// cascade flex alignment all the way down -- fixes issue with colorpicker swatches not aligning properly
			* {
				display: flex;
				align-items: center;
			}

		.display-name { white-space: nowrap;}
	}
}

.fake-select {
	> button[disabled="disabled"]:not(.ignore-dropdown-style) {
		color:var(--gray-900);
		cursor:default;
	}

	> .dropdown-menu { margin-top:$spacing-1;}
}

.dropdown-input .dropdown-menu { margin-top:$spacing-1;}
.dropdown-input {
	[disabled] &, &[disabled] {
		.dropdown-toggle {
			cursor: not-allowed;
			pointer-events: none;
			background: $input-disabled-bg-color;
			opacity: .5;

			.caret { color: var(--gray-900); }
		}
	}
}

ngb-typeahead-window .dropdown-item {
	border: none;
	width: 100%;
}

.tags-input ngb-typeahead-window {
	max-height: 280px;
	overflow-y: auto;
}

.nav-pills>li {
	&.read-only>a {
		&, &:hover, &:focus {
			background: $input-disabled-bg-color;
			border-width: $standard-border-width;
			border-style: solid;
			border-color: $input-disabled-border-color;
			border-radius:$standard-border-radius;
			color: $input-disabled-text-color;
		}
	}

	> a, &.active > a, > label, &.active > label {
		border-radius: 0;
		color: $dropdown-menu-item-color;
		display: block;
		font-size: var(--dropdown-font-size);
		line-height: $dropdown-line-height;
		padding: (($dropdown-item-target-height - $dropdown-line-height) * 0.5) $spacing-4;
	}

	> a.disabled { color:var(--gray-600); cursor:no-drop;}
	> a.disabled:hover { background:var(--white);}


	&.selected {
		> a, > label {
			&, &:hover, &:focus {
				background: var(--gray-400) ;
				color: var(--gray-900);
				font-weight: bold;
			}
		}
	}

	&.inverse {
		> a, > a:hover, > a:focus {
			background: var(--gray-900);
			color: var(--white);
		}
	}
}

.nav-pills-hierarchical, .nav-pills .nav-pills {
	display: flex;
	flex-direction: column;
	@include flex-fill;
	margin-left: $spacing-4;

	li {
		display: flex;
		flex-direction: row;
		align-items: center;
		flex-wrap:wrap;
		@include flex-fill;
	}

	i { flex: 0 0 $spacing-4; }
	a {
		display: flex;
		flex: 1 0 calc(100% - #{$spacing-4});
	}
}

textarea { padding: $input-padding }

search-list {
	.search-list-dropdown {
		position: relative;
		padding: 0;
		margin: 0;
	}

	.option-chicklet { height: 100%; width: 100%; }
}

simple-dropdown {
	display: inline-flex;
	border: none;

	* { border-radius: inherit; }
	[ngbdropdownmenu] * { border-radius: 0; }

	search-list {
		height: 100%;
		border: 0;
	}

}

.dropdown-input.option-chicklet {
	>a {
		@extend %input-text-colors;;
	}
}

.dropdown-input {
	position: relative;
	.dropdown-toggle {
		font-size: var(--font-size-default);
		line-height: 1.25;
		display: block;
		min-height: 2.5rem;
		padding: var(--input-padding);
		padding-right: ($dropdown-caret-width + $text-to-dropdown-caret-distance + $border-to-dropdown-caret-distance) !important;
		overflow:hidden;

		.caret { @extend %dropdown-caret; }
	}

	.searchable-list.dropdown-fixed {
		position: fixed !important;
	}
}

.searchable-list .searchable-list-item {
	padding: 0
}

// if simple-dropdown has form-control class, borders get wonky
//simple-dropdown.form-control { padding:0; border:0;}

simple-dropdown.invalid .dropdown-toggle,
input[type=text].invalid,
search-list-dropdown.invalid .dropdown-toggle {
	border-color: var(--danger-600);
}

%form-focus-shadow { outline: solid 2px var(--focus-outline-color); outline-offset: -2px }

simple-dropdown, search-list {
	&.font-icons * { font-family: inherit }

	.search-list-selected-option {
		white-space: nowrap;
		text-overflow: ellipsis;
		&:focus {
			@extend %form-focus-shadow
		}
	}
}

%overlap-left-border { margin-left:-1px;}

%first-input-group-field-radius { border-radius: $standard-border-radius 0 0 $standard-border-radius; }
%last-input-group-field-radius { border-radius: 0 $standard-border-radius $standard-border-radius 0; }

.input-group {
	display: flex;
	flex-direction: row;

	> * {
		border-radius:0;
		border-style: solid;
		border-color: $input-inactive-border-color;
		border-width: 1px;
		color: $input-inactive-text-color;
	}

	// TODO: review if we can remove this

	> *:not(:first-child):not(:last-child) .dropdown-toggle {
		border-radius: 0 !important; // all simple-dropdowns have border-radius-4 by default
	}

	> *:first-child:not(:last-child) .dropdown-toggle {
		border-top-right-radius: 0 !important;
		border-bottom-right-radius: 0 !important; // dropdown-toggle might have border-r-4
	}

	> *:last-child:not(:first-child) .dropdown-toggle {
		border-top-left-radius: 0 !important;
		border-bottom-left-radius: 0 !important; // dropdown-toggle might have border-r-4
	}

	> *:first-child, > .input-left {
		@extend %first-input-group-field-radius;

		~ * { margin-left: -1px; }
	}

	> *:last-child, > .input-right {
		@extend %last-input-group-field-radius;
	}

	// if input group only contains one input -- probably conditionally
	> *:first-child:last-child {
		border-radius: $standard-border-radius;
	}

	> input {
		padding-top: .5em;
		padding-bottom: .5em;
	}

	> .dropdown-menu { border: none; }

	[uib-datepicker-popup-wrap] { border: 0}
}

button.dropdown-item {
	text-align: left;
}

.btn-group-toggle > .btn input[type=radio] {
	position: absolute;
	clip: rect(0,0,0,0);
	pointer-events: none;
}

$horizontal-spacing-between-input-groups: $spacing-4;
$horizontal-spacing-between-input-and-label: .5em;

.r-margin-checkbox, .r-margin-radio { margin-right: $horizontal-spacing-between-input-and-label; }
.l-margin-checkbox, .l-margin-radio { margin-left: $horizontal-spacing-between-input-and-label; }

.labeled-checkbox, .labeled-radio { flex-direction: row; }
.labeled-checkbox, .labeled-radio, .form-check, .form-radio, .labeled-checkbox-inline, .labeled-radio-inline {
	display: flex;
	align-items: center;
	margin-bottom: .5em;

	[type="checkbox"], [type="radio"] {
		height: .9em;
		width: .9em;
		margin-top: 0;
	}
	label { margin-bottom:0;}

	[type="checkbox"][disabled] + label {
		color: var(--gray-400)
	}

	> :first-child { margin-right: $horizontal-spacing-between-input-and-label; }
	&.inline-flex { margin-right: $horizontal-spacing-between-input-groups; }


	.dropdown-menu & {
		margin-bottom: 0;
		display: flex;

		label {
			flex: 1 1;
			word-break: break-word;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
}

.labeled-checkbox-inline, .labeled-radio-inline {
	display: inline-flex;
	margin-right: $horizontal-spacing-between-input-groups
}

generic-radio-buttons, generic-checkbox-buttons {
	&.input-height [class*=labeled-] { margin-bottom: 0; }
}

a.labeled-checkbox.focus-within {
	outline-offset: -2px;
}
a.labeled-checkbox:focus-within {
	outline-offset: -2px;
}

/* input with clear/delete/reset icon */
$icon-width: 14px;
$icon-h-padding: $spacing-3;
.clearable-input { padding-right: #{$icon-width + (2 * $icon-h-padding)} !important; }
.btn-clear-input {
	line-height:1;
	position:absolute;
	right:$icon-h-padding;
	top:calc(50% - .5em);
	font-size:var(--font-size-default);

	&:hover {
		color: var(--default-primary-color);
	}
}

#clear-input-btn {
	margin-top: 1px;
	margin-right: 1px;
	max-height: 93%;
	display: flex;
	align-items: center;

	&:focus { position: absolute; }
}

.cb-input-group {

	.clear-label {
		font-size: var(--font-size-0_875rem);
	}

	.clear-btn:hover:not(:focus) {
		background: none;
	}

	.search-btn {
		font-size: var(--font-size-1_125rem);
	}
}

.quick-insights-clear {
	vertical-align: inherit;
	width: 24px;
}

%flex-align-center {
	display: flex;
	align-items:center;
}

.form-inline {
	@extend %flex-align-center;
	min-height: $input-height;

	* {
		// remove vertical margins to allow flex to align properly
		margin-top:0;
		margin-bottom:0;
	}

	.form-group, .input-group {
		margin-right: $horizontal-spacing-between-input-groups;
	}
	.form-group { @extend %flex-align-center; }
	label { margin-right: $horizontal-spacing-between-input-and-label}

	// allows for spacing between rows when an form-inline item wraps
	// may eventually just make this part of the the main .form-inline style
	&.flex-wrap {
		margin-top: (-1 * $spacing-3);

		> * { margin-top: $spacing-3; }
	}
}

.flex-input { flex: 1; }

.form-group {
	&.form-group-fill {
		display: flex;
		flex: 1;
		> input { flex: 1; }
	}
	&.form-group-stacked  { flex-direction: column; }

	&.form-group-dropdown { min-height: $input-height; } // if form group has a sometimes-hidden dropdown
}

.form-group-stacked > * {
	display: flex;
	width: 100%;
}

.help-block {
	color:var(--gray-900);
	font-size: var(--font-size-default);
	line-height: 1.5;
	letter-spacing:.25px;
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;

	.form-group + & {
		margin-top: (-1 * $spacing-3);
		margin-bottom: $spacing-4;
	}
}

// error states
.has-error {
	label, .help-block, .control-label, .radio, .checkbox, .radio-inline, .checkbox-inline { color: map-get($alertTypes,danger); }
	.form-control, .form-control:focus { border-color: map-get($alertTypes,danger); @include box-shadow(none); }
}

// applies only to target element
.has-error-strict {
	border-color: map-get($alertTypes,danger) !important;
	&[class*="q-icon"] {
		border: 0;
		color: map-get($alertTypes,danger) !important;
	}
}

textarea, input, select {
	.has-error &, &.has-error, .has-error &:focus, &.has-error:focus, &.ng-dirty.ng-invalid, &.invalid {
		border-color: map-get($alertTypes,danger);
	}
}

// only allow vertical resize
textarea {
	max-width: 100%;
	resize: vertical;
}

.line-label {
	border-bottom: 1px solid var(--gray-1000);
	line-height:0.1em;
	margin: 24px 0;

	label {
		padding-right: 16px;
		display: inline;
		font-weight: bold;
	}
}

.searchable-hierarchy-list .hierarchy-item input[type=checkbox], .multiselect-item input[type=checkbox] {
	&:focus {
		outline: none;
	}
}
// should go in unified-ui package
.flex-direction-column {
	flex-direction: column !important;
}
.h-2_5rem {
	height: 2.5rem !important;
}
.w-2_5rem {
	width: 2.5rem !important;
}
// used with d-block in inputs
.lh-1_25 {
	line-height: 1.25 !important;
}

.w-inherit {
	width: inherit !important;
}

.ml-minus-1 {
	margin-left: -1 !important;
}
