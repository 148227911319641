.cb-preview-table-container {
	// fixed header and scrollable body
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	overflow: hidden;

	.table-header {
		flex-shrink: 0;
		table-layout: fixed;
	}
	.table-body-container {
		display: flex;
		flex-grow: 1;
		overflow-y: auto;
		overflow-x: hidden;
	}

	.widget-image-export & {
		display: block;
		height: unset;
	}
}

.widget-image-export primary-pane .main-panel {
	overflow: visible;
}

.widget-image-export .br-widget-footer { justify-content: flex-end !important; }

.cb-preview-table {
	cursor: default;
	user-select:none;
	width: 100%;

	&.table-header {
		thead {
			width: 100%;
		}
		th {
			display: inline-block; // required for resizer to be able to truncate content
			border-bottom: 1px solid $table-widget-border-color;

			>span {
				display: block;
				overflow-x: hidden;
				text-overflow: ellipsis;
			}
		}
	}

	tr.alternate { background: $table-alternate-row-color;	}

	&.table-body {
		table-layout: fixed;

		.sizing-header { height: 0; }
	}

	td {
		min-height: 44px;
		height: 44px;
		overflow: hidden;
	}

	.label-bubble {
		font-size: var(--font-size-default);
		letter-spacing: 0.3px;
		line-height: 22px;
	}
}


$preview-height: $baseline-measurement * 50;

analytic-preview-settings {

	.resizable-table {
		height: 200px;
		display: block;
	}

	.document-preview-container {
		display: flex;
		flex-direction: row;
		height: 100%;
	}

	.searchable-hierarchy {
		display: flex;
		flex-direction: column;
		height: 100%;
	}
	.searchable-hierarchy-list { height: auto; }

	.preview-attributes-selection {
		// prevents Sentiment and Effort toggle from showing thru the tooltips
		position: relative;
		z-index: 100;
	}
}

$PREVIEW-FOOTER-HEIGHT: 3.0rem; // should match value in widget-image-export.component

.br-widget-footer.preview-pagination-container {
	height: $PREVIEW-FOOTER-HEIGHT;
	.br-widget-total, .image-export-watermark {
		bottom: auto;
	 }
}

.cb-preview-table {
	.resizable-header {
		overflow: hidden;
		white-space: pre;
		text-overflow: ellipsis;
	}
}

.resizable-table {

	width: 100%;
	height: calc(100% - 48px); // pagination
	position: absolute;

	tbody {
		position: absolute;
		bottom: 0;
		top: 29px; // header
		overflow: auto;
	}
}

.sentence-show-more {
	>span {
		display: none;
	}
	&.visible {
		>a {
			display: none;
		}
		>span {
			display: inline;
		}
	}
}

.pdf-mode {
	.sentence-show-more>a, .verbatim-show-more>a {
		color: var(--gray-900);
	}
}



#dashboardContainer.edit .favoritable-item:hover [class*="q-icon"] { display: inline-block; }

.analytic-feedback-selection-flag {
	&.toggled {
		color: var(--action-800);
	}
}

$actions-width: 100px;
$grip-cell-width: 20px;

.item-name-wrap { width: calc(100% - #{$grip-cell-width + $actions-width}); }

// need to make this more generic....
.item-actions-wrap {
	width: $actions-width;

	> * { width: 20px; text-align:center; }
	.btn {
		min-width: 40px; // trying to adjust in small space, the icon overflows from th ebutton
	}
}



table[disabled] {
	thead, tbody { opacity: .6; }
	* { cursor: default !important; }
}

an-preview-selected-columns, doc-explorer-selected-items {
	.disabled {
		color: var(--gray-400);
		text-decoration: line-through;
	}
}

.an-preview-attributes .searchable-hierarchy {
	.search-box {
		border-bottom-right-radius:0;
		border-bottom-left-radius:0;
		padding: var(--input-padding);
		width:100%;
	}

}

.preview-settings-column, .preview-demo-column {
	float: left;
	height: $preview-height;
	margin-left: 16px;
}

.preview-demo-column {
	width: 55%;

	widget-content {
		border: 0px;
	}
}

.preview-settings-column {
	width: 20%;
}

.preview-group-title {
	border-top: $standard-border;
	line-height: 2;
	padding-left: 16px;
	padding-right: 16px;
}

.cx-preview-selected {
	line-height: 2;
	padding-left: 16px;
	padding-right: 16px;
}

$enrichment-types: ('sentiment', 'easeScore', 'emotion');
preview-table {
	.table-body {
		@each $type in $enrichment-types {
			&.#{$type}-minified {
				.column-#{$type} {
					.enrichment-pill {
						display: none !important;
					}
				}
			}
		}

		@each $type in $enrichment-types {
			&.#{$type}-fullsize {
				.column-#{$type} {
					.enrichment-icon-with-border {
						display: none !important;
					}
				}
			}
		}
	}
}
