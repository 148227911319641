$system-modal-z-index: 10050;

$modal-border-radius: 8px;




/********** MODAL **********/
.modal.system {
	z-index : $system-modal-z-index !important;
}

.modal-backdrop.system {
	z-index : $system-modal-z-index - 10 !important;
}

.modal-title{
	margin-bottom:0;
	padding-right:20px;
	word-break:break-all;
}

$bootstrap-column-gutter-width: $spacing-4;

.row {
	margin-left: ($bootstrap-column-gutter-width * -1);
	margin-right: ($bootstrap-column-gutter-width * -1);
}

[class*="col-xs"], [class*="col-sm"], [class*="col-md"], [class*="col-lg"] {
	padding-left: $bootstrap-column-gutter-width;
	padding-right: $bootstrap-column-gutter-width;
}





.modal-body {
	padding: $spacing-4;

	$total-header-footer-size: (54px + 56px);
	$modal-top-bottom-spacing: 60px;

	&.scroll-overflow {
		max-height:calc(100vh - #{($total-header-footer-size + $modal-top-bottom-spacing)});
		overflow:auto;
	}
}

.modal-dialog {
	background:transparent;

	&.modal-xl, .modal-xl &, [size=xl] & { width: 96%; }
	&.modal-lg, .modal-lg &, [size=lg] & { width: 80%; }
	&.modal-md, .modal-md &, [size=md] & { width: 60%; max-width: 1000px; }
	&.modal-fix, .modal-fix & { width: 700px; }

	@media #{$media-query-mobile} {
		&, &.modal-xl, .modal-xl &, [size=xl] &,
		&.modal-lg, .modal-lg &, [size=lg] &,
		&.modal-md, .modal-md &, [size=md] {
			width: 98%;
			margin-left: auto;
			margin-right: auto;
		}
	}
}

.modal-fullscreen .modal-dialog {
	height: 100% !important;
	width: 100% !important;
	margin: 0;
}

.modal-content { background:transparent; border:0; border-radius:0; box-shadow:none; }
.modal-backdrop{ opacity:.8 }
.modal-header {
	border-bottom:0;
	border-radius: $modal-border-radius $modal-border-radius 0 0;

	padding: $spacing-4;

	.dropdown-toggle, .close { opacity: 1; }

	.close {
		font-size: var(--font-size-1_75rem);
		text-shadow:none;
	}
}

.modal-footer {
	background:var(--white);
	border-top: 1px solid var(--modal-divider-color);
	margin-top: 0;
	padding: .625rem 1rem;
	border-radius: 0 0 $modal-border-radius $modal-border-radius;
	display:flex;
	width: 100%;
	justify-content:flex-end;

	&:after, &:before { display: flex; content:none};
}

.modal-text {
	margin-left: -15px;
	color: var(--gray-900);
	font-size: 120%;
	margin-bottom: 50px;
}

@media screen and (min-width: 768px) and (max-width: 1200px){
	.modal-md .modal-dialog { width:75%; }
}

// allow some max widths for modal content
$modal-size-limits: (800,1000,1200);

@each $modal-size-limit in $modal-size-limits {
  .modal-max-#{$modal-size-limit} .modal-dialog { max-width:#{$modal-size-limit}px; }
}



$modal-total-vertical-margin: 60px;
$modal-header-height: 56px;
$modal-footer-height: 56px;


$icon-distance-from-edge: $spacing-3;
$icon-distance-from-text: $spacing-3;
$icon-width: $spacing-4;

// angular wrapper
alert {
	display: block;
	margin-bottom: $spacing-4;
}


$alert-vertical-padding: 4.5px;
$alert-line-height: $default-line-height;
.alert {
	border-radius:$standard-border-radius;
	display: inline-flex;
	font-size: var(--font-size-default);
	align-items: center;
	margin-bottom: $spacing-4;
	padding: 0.25rem 0.5rem;
	position:relative;
	min-height: 2.5rem;
	line-height: 1.5;

	alert & { margin-bottom:0;}

	@each $type, $value in $messageStripTypes {
		&-#{$type} { background:$value; }
	}
	@each $type, $value in $messageStripColors {
		&-#{$type} { color:$value; border-color:$value; }
	}

	/* for a close within an alert, inherit the color */
	.close {
		color:inherit;
		font-size: var(--font-size-1_125rem);
		font-weight: bold;
		opacity:1;
		margin-left: 0.5rem;
		text-shadow: none;

		>span { vertical-align: middle; }
	}

	.close, .alert-icon {
		display: flex;
	}

	.alert-icon {
		margin-left: 0.5rem;
		&:not(.close) {
			margin-right: 0.5rem;
			margin-left: 0;
		}
	}
	@each $type, $value in $messageStripColors {
			&-#{$type} {
				color:$value;
				.transclude-content {
					color: inherit;
				}
			}
	}
	.transclude-content {
		display: inline;

		> :last-child { margin-bottom:0; }
	}

	// next two are disabled for now...
	// &.alert-narrow { padding:7px 12px; }
	// &.no-margin { margin-bottom:0; }

	&.alert-sm { line-height:1; padding: #{$spacing-3 - $standard-border-width} $spacing-4; }

	.alert-multiline &, &.alert-multiline { line-height: $default-line-height; }

	.baseline-alert & {
		border-radius: 0;
		bottom: 0;
		left: 0;
		margin: 0;
		padding: $spacing-3;
		position: absolute;
		width: 100%;
	}
}

.baseline-alert {
	position: fixed;
	z-index: 11;	// keep this layered overtop of hovered inline-help

	.modal-body & {
		left: 0;
		width: 100%;
		bottom: $modal-footer-height;
	}
}

.text {
	@each $type, $value in $alertTypes {
		&-#{$type},&-#{$type}:hover { color:$value !important; }
	}
}

.bg {
	@each $type, $value in $alertTypes {
		&-#{$type} { background-color:$value; }
	}
}

.border {
	@each $type, $value in $alertTypes {
		&-#{$type} { border-color:$value; }
	}
}





.panel-group .panel { border-radius: var(--panel-radius);}
.panel-heading { border-top-left-radius: var(--panel-radius); border-top-right-radius: var(--panel-radius);}




.form-control:focus {
	@include box-shadow(none !important);
	border-color: inherit;
}
.tooltip-inner {
	max-width: none;
	background: var(--gray-900);
	color: var(--white)
}

.tooltip {

    &.top .tooltip-arrow {
        border-top-color: var(--gray-900);
    }

    &.top-left .tooltip-arrow {
        border-top-color: var(--gray-900);
    }

    &.top-right .tooltip-arrow {
        border-top-color: var(--gray-900);
    }

    &.right .tooltip-arrow {
        border-right-color: var(--gray-900);
    }

    &.left .tooltip-arrow {
        border-left-color: var(--gray-900);
    }

    &.bottom .tooltip-arrow {
        border-bottom-color: var(--gray-900);
    }

    &.bottom-left .tooltip-arrow {
        border-bottom-color: var(--gray-900);
    }

    &.bottom-right .tooltip-arrow {
        border-bottom-color: var(--gray-900);
    }
}

[uib-tooltip-html-popup] { z-index:($system-modal-z-index + 1); }
.tooltip {
	opacity: 1;
}

.pagination {
	color: var(--gray-900);
	font-size: var(--font-size-default);
	margin: $spacing-4 0;

	>li>a, >li>span {
		border: $standard-border-width solid transparent;
		border-radius: $standard-border-radius;
		line-height:1;
		padding: 0;
		margin-right: 0.5rem;
		height: 2.5rem;
		width: 2.25rem;
		display: flex;
		align-items: center;
		justify-content: center;

		&:after {
			font-size:var(--font-size-0_75rem);
			font-family:var(--qualtrics-icons);
			line-height:16px;
		}
	}

	&.pagination-sm {
		>li>a, >li>span {
			padding: 5px 8px; // this is not in line with 8pt grid...

			&:after { line-height:12px; }
		}

		&.pagination-border {
			>li>a, >li>span {
				padding: 4px 8px;
			}
		}
	}

	//angularjs
	.pagination-first, .pagination-prev, .pagination-next, .pagination-last {
		>a { font-size: 0;}
	}
	.pagination-first > a:after { content:"\f15b"; font-size:var(--font-size-1_25rem); }
	.pagination-prev > a:after { content:"\f159"; font-size:var(--font-size-1_25rem); }
	.pagination-next > a:after { content:"\f15a"; font-size:var(--font-size-1_25rem); }
	.pagination-last > a:after { content:"\f15c"; font-size:var(--font-size-1_25rem); }


	&.pagination-border {
		border: 1px solid var(--gray-200);

		>li>a, >li>span {
			padding: #{$spacing-3 - (2 * $standard-border-width)} 12px;
		}
	}
}

.btn-toolbar {
	>.btn+.btn, >.btn+.btn-group, >.btn-group+.btn, >.btn-group+.btn-group {
		margin-left: $button-spacing-between;
	}
}

.modal-footer .btn + .btn {
	margin-left: $button-spacing-between;
}


.form-horizontal .form-group {
    display: flex;
    align-items: center;

	label, p, .control-label {
		margin-top:0;
		margin-bottom:0;
		padding-top:0;
		padding-bottom:0;
	}
}

.list-group-item { border-color: var(--gray-200); }









/* ng-bootstrap datepicker */
.ngb-dp-arrow-btn {
	padding: 0;
	height: 27px;
	font-size: var(--font-size-default);
	text-align: center;
}

.ngb-dp-navigation-select {
	select:first-child {
		margin-left: $spacing-2;
		margin-right: $spacing-3;
	}
}

.date-picker-input {
	width: 120px;
}

.ngb-dp-weekdays {
	height: 20px;
	padding-top: 3px;
}

.datepicker-group-hidden {
	width: 0;
	margin: 0;
	border: none;
	padding: 0;

	input {
		opacity: 0;
	}
}

.custom-day {
	text-align: center;
	padding: 0.185rem 0.25rem;
	display: inline-block;
	height: 2rem;
	width: 2rem;
}

.ngb-dp-day.hidden {
	display: block!important;
}

.ngb-dp-arrow-btn {
	border: 0;
}

.ngb-dp-navigation-chevron {
	&, .right & {
		border: 0;
		transform: none;
		height: auto;
		width: auto;
	}

	&:before {
		font-family: var(--qualtrics-icons);
		content: '\f115';
	}

	.right &:before {
		content: '\f116';
	}
}

.ngb-dp-month { width: 100%; }

ngb-datepicker-navigation-select > .custom-select { font-size: var(--font-size-default); }
ngb-datepicker button, [ngbDatepickerDayView] { border-radius: 2px; }

.no-date-clear .uib-clear {
	display: none;
}
