// clean up and merge with dropdown-menu.scss

:root {
	--dropdown-font-size: var(--font-size-default);
}

%hierarchy-search-box {
	font-size: $dropdown-searchbox-font-size;
	width:100%;
}

.dropdown-menu input:not(.ngb-datepicker-input), .searchable-hierarchy input {
	&.search-box, &[type=text] { @extend %hierarchy-search-box; }
}

.dropdown-menu li {	
	>a.disabled, >.disabled {
		text-decoration: line-through !important;

		&:hover { background-color: inherit; color: inherit; cursor: not-allowed; }
	}

	>a.disabled.cxs-dropdown-divider {
		text-decoration: none !important;
	}
	
}

.hierarchy-select-wrapper {
	width: 100%;
	display: flex;
	align-items: center;
	
	.dropdown-menu.dropdown-menu-hierarchy {
		width:auto; 
		max-width:500px; 
		max-height:none; 
		overflow:visible;
	}

	.searchable-hierarchy > .searchable-hierarchy-list {
		font-size: var(--dropdown-font-size);
		line-height: $dropdown-line-height;
		padding:0;

		> ul {
			max-height:280px; 
			overflow-y:auto; 
			overflow-x:hidden; 
			min-width:100%;
			margin-bottom:0;
		}
	}

	.br-hierarchy-node-name, .no-results {
		white-space:nowrap;
		overflow:hidden;
		text-overflow: ellipsis;
	}

	#dashboard-filters-popup & .hierarchy-item {
		max-width:400px; 
		white-space:nowrap;
	}

	&.dashboard-filter-topic-selection {
		.dropdown-menu.dropdown-menu-hierarchy { 
			min-width: 300px !important; 
		}
	
		.hierarchy-item {
			max-width:400px; 
			white-space:nowrap;
		}
	}	
	
	.selected-item {
		display: inline-block;
		width: 100%;
	}
}

.hierarchy-item, .no-results {
	color: $dropdown-menu-item-color;
	font-size: var(--dropdown-font-size);
	line-height: 1;
	padding: #{($dropdown-item-target-height - $dropdown-line-height) * 0.5} $spacing-3;
}

.dropdown-autosize {
	search-list {
		display: flex;
		flex: 0 1 100%;
		min-width: 0 !important;
	}
}

.ngb-datepicker-menu.dropdown-menu {
	margin-top: 2px;
}

.user-column-dropdown {
	display: inline-block !important;
}

.user-column.dropdown-menu {
	margin-top: 2px;
}