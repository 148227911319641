.single-axis-widget, .dual-axis-widget { 
	display: flex;

	@media screen and #{$media-query-mobile-and-small} {
		flex-direction: column;

		// axis definitions and preview get full width at low resolution
		.widget-axis, .chart-preview {
			max-width: none;
			margin:0 0 32px;
		}
	}
}

.widget-axis {
	margin-right: $spacing-6;
	flex-direction: column;
	flex: 0 0 25%;
	max-width:400px;

	&.secondary-axis { margin-right:0;}
}

.chart-preview {
	flex: 0 1 75%;
	position: relative;
	max-width: 1000px;

	.dual-axis-widget & {
		flex: 1 1 auto;
		margin-right: $spacing-6;
	}
}

