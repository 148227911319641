.notification-tabs {
	width: 400px;
	white-space:normal;
	border-bottom: 4px solid var(--gray-200); // margin in bottom causes bg-color to not apply there, so using border
	div:first-child[role=tablist] {
		margin-bottom: -8px;
		.tab-content {
			background-color: var(--gray-100);
			.tab-content>.active {
				display: flex;
			}
		}
	}

	@media #{$media-query-mobile} {
		.br-header-right & {
			font-size:13px;
			left:-220px;
			right:-20px;
			width:auto;
		}
	}

	.nav-tabs {
		margin-bottom: 0;
		display: flex;
		align-items: center;
		height: 3.5rem;
		font-size: var(--font-size-default);
		.nav-item {
			height: 100%;
			margin: 0;
			.nav-link {
				height: 100%;
				line-height: 3.5;
				font-weight: 700;
				padding: 0 1.5rem;
				text-transform: none;
			}
		}
		li:hover a { background:none;}
	}


	.list-group-item {
		border: 1px solid var(--gray-500);
		border-radius: 4px;
		padding: 1rem;
		background-color: var(--white);
	}

	&.dropdown-menu {
		cursor:default;

		.active>a:focus {
			outline: solid 2px var(--focus-outline-color);
		}
	}
}
.notification-check {
	height: 0.75rem;
	width: 0.75rem;
	border-radius: 0.75rem;
	margin-top: 0.375rem;
	margin-right: 0.75rem;
	flex-shrink: 0;
	background-color: var(--gray-400);
}

.br-alerts-count {
	background: var(--danger-700);
	border-radius:8px;
	line-height:1rem;
	height:1rem;
	padding:0 3px;
	font-size:var(--font-size-0_75rem);
	color:var(--white, $white);
	top:-5px;
	min-width:1.5rem;
}


.nf-list-group {
	background-color: var(--gray-100);
	color: var(--gray-900);
	overflow-y: auto;
	max-height: 500px;
	/* if the browser supports it, we'll set the max height based off of the viewport height instead: 100% of the viewport height, minus 200 px */
	max-height: calc(100vh - 200px);
}

.nf-message {
	b { word-break: break-word; }
	.show-more a { white-space:nowrap;}
}


.nf-alert { margin: $spacing-4; }

.flex-grow-one {
	flex-grow: 1;
}
