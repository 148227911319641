.rounded-grouping {
	border-radius: var(--panel-radius); // usually has a panel header at the top
    border: 1px solid var(--gray-400);
    margin-bottom: $spacing-5;
    padding: $spacing-4;
	position: relative;

    &.warning { border: 1px solid var(--warning-600); }
}

.rounded-grouping-label {
	top: -.5em;
	left: $spacing-4;
	position: absolute;
	padding-left: $spacing-3;
    padding-right: $spacing-3;
    line-height:1;

    * { min-height: 0; margin-bottom:0; margin-top: 0; }
}
