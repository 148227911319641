.cb-table {
	word-break: break-word;
}

.cb-table.cb-table-comfort {
	th, td {
		padding: $spacing-3;
		vertical-align: middle;
	}

	.cb-table-row { border-top: 1px solid var(--gray-900); }
}

.cb-table.cb-table-scrollable {
	overflow-y: auto;
	max-height: 400px;

	table {
		border-collapse: collapse;
		width: 100%;
	}

	th, td {
		padding: $spacing-2 $spacing-4 $spacing-2 0;
	}

	th {
		position: sticky;
		top: 0;
		background: var(--white);
	}
}
