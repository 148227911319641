.br-global-notification {
	position: absolute;
	top: 5px;
	left: 5px;
	opacity: 0.95;
	z-index: 99999999999;
	padding: 10px 30px 10px 20px;
}

.global-notification-list-item {
	background-color: var(--gray-900);
	color: var(--white);
	position: absolute;
	left: $spacing-2;
	z-index: 80000;
	padding: $spacing-3 $spacing-4;

	i { margin-left: $spacing-2; }
}
