.multiselect-dropdown {
	.options-wrapper {
		overflow:auto;
	}
	.multiselect-clear-show { height:20px; }

	&.paging {
		.options-wrapper {
			min-width: 275px;
			overflow: hidden;
		}

		// for old-multiselect component
		.options-list {
			.scroll-container {
				max-height: 350px;
				overflow-y: auto;
			}
		}

		.options-list.scroll-container {
			max-height: 350px;
			overflow-y: auto;
		}

		.new-value-dot {
			height: 8px;
			width: 8px;
			border-radius: 50%;
			display: inline-block;
		}
	}

	.dropdown-menu-hierarchy.dropdown-menu {
		max-width: 400px;
	}
}

.gridster-wrapper .multiselect-dropdown.paging .options-wrapper {
	min-width: auto;
}

.sidebar-editor-enabled {
	.multiselect-chips {
		width: 100%;
	}
}
.multiselect-chips {
	border: $standard-border-width solid $input-inactive-border-color;
	border-radius: $standard-border-radius;
	min-height: $input-height;
	width: 400px;

	template-replacements-list & {
		width: auto;
	}
}

.chips-input {
	border: $standard-border-width solid $input-inactive-border-color;
	border-radius: $standard-border-radius;

	input {
		display: flex;
		flex: 1;
		height: auto !important;
		min-width: 40px;
	}
}

.chip {
	@extend %chip-style;
	padding-left: $spacing-2;
	padding-right: $spacing-2;
	display: flex;
	align-items: center;
}

.multiselect-dropdown.dashboard-filter {
	.options-wrapper {
		max-height: 60vh;
	}
}

cdk-virtual-scroll-viewport {
	width: 350px;
	height: 450px;

	.cdk-virtual-scroll-content-wrapper {
		width: 100%;
	}
}

.w-max-260 {
	max-width: 260px;
}
