.colorpicker__main {
	max-height: 75vh;
}

.vertical-picker {
	saturation-component { height: 100px; }
}


// overwrite some styles from 3rd party component
hex-input-component,
rgba-input-component {
	input { height: 32px !important; }
}

rgba-input-component {
	input {
		padding-left: 8px !important;
		padding-right: 8px !important;
	}
}

@mixin horizontalPicker() {
	.colorpicker {
		&__main {
			flex-direction: row !important;
			width: 672px;
			min-height: 356px;
			flex-wrap: wrap;
		}

		&__colordiv--height {
			margin-right: 24px;
			flex: 1 1 calc(45% - 24px) !important;

			span { display: none}
		}

		&__arraydiv--height {
			flex: 0 1 50% !important;
			height: 320px;
		}

		&__buttons--height {
			width: 100%;
		}
	}

	saturation-component {
		height: 177px;
		margin-top: 0 !important;
	}

	.tab-content, .manual-input { margin-bottom: 0 !important}

	.recent-colors { margin-bottom: 16px !important; }

}

.horizontal-picker { @include horizontalPicker(); }
@media ( max-height : 720px) {
	@include horizontalPicker();
}
