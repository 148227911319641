/*copy from dashboards grid*/


.br-log-grid {
	.slick-row, .slick-header-columns {
		> div:first-child { box-sizing:border-box; padding-left:10px; }
		> div:last-child { box-sizing:border-box; padding-right:10px; }
	}
}

.br-log-grid {
	$log-border-style: 1px solid $table-border-color;

	.slick-header {
		border-bottom: $log-border-style;
		font-weight: 700;
	}

	.slick-header-columns {
		border-bottom: $log-border-style;
		cursor: pointer;
	}

	.slick-header-column {
		border: 0;
	}

	.slick-row {
		border: $log-border-style;
		border-width: 0 0 1px 0;
		height: 40px;
		line-height: 36px;

		&.even { background: $table-alternate-row-color }
		&:hover { background: $table-hover-row-color; }

		&.loading {
			opacity: 0.5;
			filter: alpha(opacity = 50);
		}
	}

	.slick-cell {
		padding-left: $spacing-2;
		padding-right: $spacing-2;
		border-width: 0;
	}

	.slick-sort-indicator {
		float: none;
		margin-left: 10px;
	}
}

.br-log-grid .slick-header-column:hover, .slick-header-column-active {
	background: var(--gray-200);
}
