html, body {
	margin:0;
	padding:0;
	border:0;
	font-family: var(--font-standard);
	font-size: var(--font-size-default);
	line-height: $default-line-height;
	height:100%;
	overflow:auto;
	overflow-x:hidden;
}

h1, h2, h3 { margin-top:0; margin-bottom: $spacing-3; }

.subheading {
	font-size: $subheading-size;
	text-transform: uppercase;
}

p, .body {
	margin:0 0 $spacing-3;
	letter-spacing:.5px;
}


pre {
	&, * { font-family: $font-monospace }
}



hr {
	margin: $spacing-4 0;
	border-top: $standard-border-width solid var(--gray-900);
}

label {
	color: var(--gray-900);
	font-weight: normal;
	letter-spacing: .3px;
	margin-bottom: $spacing-3;

	a & { color:inherit; }

	.labeled-radio [disabled] + &, &.disabled, &[disabled], fieldset[disabled] & {
		cursor: not-allowed;
		opacity: 0.5;
	}

	&[disabled] cb-inline-help { display: none; }
}

.bullet-list { list-style: initial; }




@mixin disabled-link {
	cursor: not-allowed;
	opacity: .65;
}

a[disabled] {
	@include disabled-link;
}


:root {
    // using ems instead of rems so it can inherit from its container
    --icon-font-size: 1.25em;
	--default-outline-offset: 2px;
	--panel-radius: 8px;
	--widget-border-radius: 2px;
}

[class*="q-icon"], .btn-icon [class*="q-icon"], .btn-icon[class*="q-icon"] { font-size: var(--icon-font-size); }

// drag icon is extra small for some reason, and needs to be bumped even further.
.q-icon-drag, .en-icon-drag { font-size: 1.5em }

// custom utility classes that allows use of the default border radius for widgets
.border-radius-widget-default {
	border-radius: var(--widget-border-radius) !important;
}

.border-b-radius-widget-default {
	border-bottom-right-radius: var(--widget-border-radius) !important;
	border-bottom-left-radius: var(--widget-border-radius) !important;
}

.border-b-right-radius-widget-default {
	border-bottom-right-radius: var(--widget-border-radius) !important;
}

.border-b-left-radius-widget-default {
	border-bottom-left-radius: var(--widget-border-radius) !important;
}

.border-t-radius-widget-default {
	border-top-left-radius: var(--widget-border-radius) !important;
	border-top-right-radius: var(--widget-border-radius) !important;
}

.border-t-right-radius-widget-default {
	border-top-right-radius: var(--widget-border-radius) !important;
}

.border-t-left-radius-widget-default {
	border-top-left-radius: var(--widget-border-radius) !important;
}
