.mobile-layout {
	.br-widget-content .slick-header,
	.br-preview-widget-content .slick-header{
		display: none !important;
	}
	.br-widget-content .slick-row,
	.br-preview-widget-content .slick-row{
		position: relative !important;
		overflow: hidden !important;
		height: auto !important;
		width: auto !important;
		top: inherit !important;
		line-height: inherit !important;
		padding:10px 6px;
	}
	.br-widget-content .slick-cell,
	.br-preview-widget-content .slick-cell{
		position: relative !important;
		right: inherit !important;
		left: inherit !important;
		overflow: auto !important;
		height: inherit !important;
		padding: 0 !important;
	}
	.slick-cell-full-width{
		width: 45%;
	}
	.slick-cell-partition{
		max-width: 45%;
	}
	.slick-cell-long-val{
		width: 75%;
	}
	.slick-cell-short-val{
		width: 25%;
	}
	.grid-canvas{
		width: 100% !important;
	}

	.slick-row.new-row {
		display:none;
	}

	.slick-pager-settings {
		display:none !important;
	}

	.br-widget-content .slick-viewport,
	.br-preview-widget-content .slick-viewport{
		height: 100% !important;
		overflow: visible !important;
	}

	.slick-cell.sentiment_breakdown, 
	.slick-cell.nps_breakdown {
		height: 40px !important;
		overflow: visible !important;
	}

	.sentiment_breakdown .property, .nps_breakdown .property {
		position: relative;
		top: -15px;
	}
	  
}
.slick-cell > .slick-align-right {
	flex: 1;
	justify-content: flex-end;
}
.slick-align-center {
	text-align:center;
}

.slick-align-right {
	text-align:right;
}

.table-image-cell-fallback {
	display: none;
}

.table-image-cell {
	overflow: hidden;
	width: 100%;

	.table-image {
		background-repeat: no-repeat;
		background-size: contain;
		height: 100%;
		
		

		&.image-fill { background-size: cover; }
		&.image-fit { background-size: contain; }
		&.image-top { background-position-y: top; }
		&.image-bottom { background-position-y: bottom; }
		&.image-middle { background-position-y: center; }
		&.image-left { background-position-x: left; }
		&.image-right { background-position-x: right; }
		&.image-center { background-position-x: center; }		
	}

	> img {
		height: 1px;
		opacity: 0;
		position: absolute;
		width: 1px;
		z-index:0;

		// if source is unavailable, hide the image and show the link instead
		&[empty=true] {
			&, ~ .table-image { display: none; }
			~a { display: block; }
		}
	}
	
	a { display: none; }
}
