:root {
	--dropdown-font-size: var(--font-size-default);
}



[uib-dropdown-toggle] {	cursor: pointer; }

.pull-right .dropdown-menu, .dropdown-menu.pull-right {
	left:auto;
	right: -10px;
	margin-top: 10px;
}


.dropright .dropdown-menu {
	top: -300%;
	left: 100%;
}

.dropleft .dropdown-menu {
	top: -300%;
	right: 100%;
	left: auto;
}

.dropdown-submenu {
	position:relative;

	>.dropdown-menu {
		top:0;
		left:100%;
		margin-top:-5px;
		margin-left:-1px;
	}
	&:hover, &.on-click.open-menu, &.on-click.open-menu:hover, &.focus-within, &.on-click.open-menu.focus-within {
		> .dropdown-menu { display:block; }
	}

	&:focus-within {
		> .dropdown-menu { display:block; }
		&.on-click > .dropdown-menu {display: none;}
		&.on-click.open-menu > .dropdown-menu {display: block;}
	}


	/* disable hover functionality on submenu that requires click */
	&.on-click{
		&:hover,&.focus-within {
			>.dropdown-menu { display:none; }
		}
	}

	>a {
		min-height:26px;

		&:after {
			display:block;
			content:"\f116";
			font-family: var(--qualtrics-icons);
			position:absolute;

			top:calc(50% - .5em);
			right: 8px;
			font-size: var(--font-size-1_25rem);
		}
	}

	.level-0 { padding-left: $spacing-2; }
	.level-1 { padding-left: $spacing-2 * 2; }
	.level-2 { padding-left: $spacing-2 * 3; }
	.level-3 { padding-left: $spacing-2 * 4; }
	.level-4 { padding-left: $spacing-2 * 5; }
	.level-5 { padding-left: $spacing-2 * 6; }

	&:hover>a:after { border-left-color:inherit; }
	&.pull-left {
		float:none;

		>.dropdown-menu {
			left:-100%;
			margin-left:10px;
			-webkit-border-radius:6px 0 6px 6px;
			-moz-border-radius:6px 0 6px 6px;
			border-radius:6px 0 6px 6px;
		}
	}

	li > a { overflow:hidden; text-overflow:ellipsis; }
	>.top.dropdown-menu {
		top: auto;
		bottom: 0;
	}
}





.dropdown-menu li > a { position:relative;}


.left > :not(.dropdown-bounded) .dropdown-submenu>a:after {
    float:left;
    border-width:5px 5px 5px 0;
    border-right-color:inherit;
    margin-left: -10px;
}

.left > :not(.dropdown-bounded) .dropdown-submenu>.dropdown-menu {
    left:auto;
    right: 100%;
    margin-right:-1px;
}


.br-context-menu.drill .menu-level-2 a,
.br-context-menu.dashboards .menu-level-1 {
    max-width: 500px;
    overflow-x: hidden;
    text-overflow: ellipsis;
}


.dropdown hr{
    margin: $half-standard-padding $double-standard-padding;
}
.dropdown-submenu-dynamic > ul {
	overflow-y: auto;
	max-height: 300px; // should be consistent with context-menu-tree value
	text-overflow: ellipsis;
	padding:0;
}

$menu-vertical-padding: $spacing-3;
$menu-item-horizontal-padding: 20px;
$menu-divider-vertical-margin: $spacing-2;

.dropdown-header {
	font-size: var(--font-size-0_75rem);
	line-height: 1;
	padding: calc(var(--dropdown-font-size) * 0.5) $menu-item-horizontal-padding;

	.dropdown-menu > &:first-child,
	.dropdown-menu > :first-child > &:first-child {
		margin-top: (-1 * $menu-vertical-padding);
	}

	.dropdown-menu .divider + &,
	.dropdown-menu .divider + div > &:first-child { margin-top: (-1 * $menu-divider-vertical-margin) }
}

.no-dropdown-header .dropdown-header {
	display: none;
}


.dropdown-item-padding {
	padding: calc(var(--dropdown-font-size) * 0.5) $menu-item-horizontal-padding;
}

.dropdown-menu {
	border: 0;
	border-radius: $dropdown-border-radius;
	@include box-shadow($dropdown-box-shadow);
	cursor: pointer;
	padding:$menu-vertical-padding 0;
	font-size: var(--dropdown-font-size);
	margin-top: -3px;
	z-index: 1050;

	&.dropdown-left, .dropdown-submenu>&.dropdown-left { left:auto; right:calc(100%);}
	&.dropdown-right, .dropdown-submenu>&.dropdown-right { right:auto; left:calc(100%);}

	&:not(.uib-datepicker-popup) li>button {
		border: none;
		width: 100%;
	}

	li>span, li>a, &:not(.uib-datepicker-popup) li>button, li>div>.filter-child, .dropdown-item {
        clear: both;
        display: block;
		line-height: 1;
        white-space: nowrap;
        @extend .dropdown-item-padding;
    }
	&.wrap-items li>a, li>button {white-space:normal; word-break:break-word;}


	.divider {
		background-color: $dropdown-menu-divider-color !important;
		margin: $menu-divider-vertical-margin 0;
		padding:0;
	}

	hr {
		border:0;
		border-top:1px solid $dropdown-menu-divider-color;
		margin: $menu-divider-vertical-margin 0;
	}

	li>.disabled {
		color: var(--gray-400) !important;
 		&:hover { background-color: inherit !important; cursor: not-allowed; }
	}


	li>a:hover,li>a:focus,li>button:hover,li>button:focus{
		color: $dropdown-menu-item-color;
		background-color: var(--gray-200);
	}


	.disabled>a,.disabled>a:hover,.disabled>a:focus {
		color: var(--gray-900);
	}

	.disabled>a:hover,.disabled>a:focus {
		cursor: not-allowed;
		background-color: transparent;
		background-image: none;
	}
	.parent {
		padding-left:20px;

		&.active {
			outline:0;
		}
	}

	&.disabled, &.read-only {
		&, li, >a, li > a { cursor: default; }
	}

	&.has-submenu > li {
		> a, .dropdown-item {
			padding-left: 1.5em;
			padding-right: 1.5em;
			display: flex;
			align-items: center;
		}
	}

	.strike-through, .strike-through:hover {
		@include strike-through;
	}
}



.dashboard-filters-pop .arrow { display: none; }

.dropup {
	.dropdown-menu {
		bottom: auto;
	}

	.cx-dropdown-menu {
		top: auto !important;
		bottom: 0px !important;
	}
}

.dashboard-filters-pop .arrow {
	margin-left:0;
	left:auto;
	right:100%;
	margin-right:-30px;
}



// align toggle icon with checkboxes at higher level
.multiselect-item.parent .br-hierarchy-node-toggle .q-icon { margin-left: -3px; }
