.br-book-header {
	fullscreen-autorefresh-panel {
		display: inline-block;
	}
	h1{
		font-size: var(--font-size-1_675rem);
	}
	padding-top: 12px;
}

book-tabs {

	.dashboard-tab {
		.btn {
			border-width: 2px 1px 0 1px;
			border-radius: 3px 3px 0 0;
			border-color: var(--gray-300);
		}

		&.selected .btn {
			border-top-color: var(--action-900);
			border-bottom-color: var(--custom-bg-primary, var(--dashboard-bg));
			background: var(--custom-bg-primary, var(--dashboard-bg));

			.tab-action {
				color: var(--gray-400)
			}

			&:hover {
				color: var(--action-1000);
			}
		}
		.btn {
			&:hover, &:active {
				border-right-color: var(--gray-300);
				border-left-color: var(--gray-300);
				background-color: var(--action-200);
			}
			&:hover {
				border-top-color: var(--action-800);
				color: var(--action-900);
			}
			&:active {
				border-top-color: var(--action-900);
				color: var(--action-1000);
			}
		}
	}

	.brand-secondary-bg-fade-start .fade-container:before, .brand-secondary-bg-fade-end .fade-container:after {
		height: 32px;
	}

	.fade-container.with-add-button:after {
		right: 31px;
	}

	.tab-placeholder {
		padding: 0 $spacing-3;
	}

	book-edit-tab {
		.q-icon-drag {
			font-size: var(--font-size-1_375rem);
		}
	}

}
