$pill-height: 40px;

collapsible-pills {
	width: 100%;
	.pills-container {
		overflow-y: visible;
		&.collapsed {
			max-height: #{$pill-height * 4};
			overflow-y: hidden;
		}
		.label-bubble {
			border-color: inherit !important;
			letter-spacing: 0.3px !important;
			font-size: var(--font-size-default) !important;
			&:not(.selected) {
				color: inherit !important;
			}
			.pill-remove {
				font-size: var(--font-size-1_125rem);
			}
		}
	}
	.pills-toggle {
		color: inherit !important;
	}
}
.enrichment-pill {
	// conversation span.label-bubble, overrides the height and radius without !important
	height: 40px !important;
	border-radius: 1000px !important;
}

.h-40 {
	height: 40px !important;
}
.w-40 {
	width: 40px !important;
}
