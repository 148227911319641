$widget-header-icon-size: 16px;
$widget-border-default: 1px solid var(--gray-200);

.br-widget-content {
	padding: 0;
}

// this styling cannot be done using highcharts, as it applies same color to "fill" and "stroke"
.highcharts-legend-item-hidden {
	opacity: 0.5 !important;
}

.gridster-item {
	.handle-se, .handle-ne, .handle-nw, .handle-sw {
		height: 6px;
		width: 6px;
		background-image:
			url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/Pg08IS0tIEdlbmVyYXRvcjogQWRvYmUgRmlyZXdvcmtzIENTNiwgRXhwb3J0IFNWRyBFeHRlbnNpb24gYnkgQWFyb24gQmVhbGwgKGh0dHA6Ly9maXJld29ya3MuYWJlYWxsLmNvbSkgLiBWZXJzaW9uOiAwLjYuMSAgLS0+DTwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DTxzdmcgaWQ9IlVudGl0bGVkLVBhZ2UlMjAxIiB2aWV3Qm94PSIwIDAgNiA2IiBzdHlsZT0iYmFja2dyb3VuZC1jb2xvcjojZmZmZmZmMDAiIHZlcnNpb249IjEuMSINCXhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbDpzcGFjZT0icHJlc2VydmUiDQl4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjZweCIgaGVpZ2h0PSI2cHgiDT4NCTxnIG9wYWNpdHk9IjAuMzAyIj4NCQk8cGF0aCBkPSJNIDYgNiBMIDAgNiBMIDAgNC4yIEwgNCA0LjIgTCA0LjIgNC4yIEwgNC4yIDAgTCA2IDAgTCA2IDYgTCA2IDYgWiIgZmlsbD0iIzAwMDAwMCIvPg0JPC9nPg08L3N2Zz4=');
		background-position: top left;
		background-repeat: no-repeat;
		display: none;
	}
	.handle-se {
		bottom: $spacing-2;
		right: $spacing-2;
	}
	.handle-sw {
		bottom: $spacing-2;
		left: $spacing-2;
		transform: rotate(90deg);
	}
	.handle-nw {
		top: $spacing-2;
		left: $spacing-2;
		transform: rotate(180deg);
	}
	.handle-ne {
		top: $spacing-2;
		right: $spacing-2;
		transform: rotate(270deg);
	}
}

.gridster-item .gridster-item-resizable-handler {
	z-index: 1001;
}

.highcharts-tooltip span {
	z-index: 9999 !important;
}

.gridster-item:hover, .gridster-item.hover {
	.handle-se, .handle-ne, .handle-nw, .handle-sw {
		display:block;
	}
}

.br-widget-page-break {
	height: 4px;
	border: 0;
	background-color: inherit;
}

.br-widget-page-break-line, .br-widget-delimiter {
	position: absolute;
	left: 0;
	width: 100%;
	border-top: dashed 2px var(--action-300);
	line-height: 20px;
	text-align: center;
	top: 50%;
}

.br-widget-delimiter {
	border-top: dotted 1px var(--gray-900);
	padding-left: 0;
	left: 20px;
	z-index: index($dashboardLayers, page-break-indicator);
}

.gridster-widget.page-break-box {
	border-width:1px;
	border-style:dotted;
	width: 100%;
	cursor: n-resize;


	.br-widget-content {
		position: inherit;
		top: 0;
	}

	> .handle-se, > .handle-sw, > .handle-ne, > .handle-nw {
		background-image: none;
		cursor: inherit;
		width: 0;
		height: 0;
	}
}

.gridster-widget.selected {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.br-widget-box {
	position: absolute;
}

.br-widget-header {
	height:41px;
	border-top-right-radius: var(--widget-border-radius);
	border-top-left-radius: var(--widget-border-radius);
	.dropdown-menu { z-index: 1002; }

	.bg-none:not(.header-hidden) & { background: none !important; }
}

widget-menu {
	&.grouped-menu {
		.dropdown-menu {
			padding: 0;
		}
	}
}


.modal-header .dropdown-menu {
	z-index: 1002;
}

.edit .br-widget-header {
	cursor: move;
	.br-widget-header, header {
		z-index:1002;
	}
}



$header-height: 41px; // should match widget-image-export.component
$footer-height: 32px; // should match widget-image-export.component
$hidden-footer-padding: 16px;

.br-widget-content {
	overflow: hidden;
	width:100%;
	height:calc(100% - #{$header-height} - #{$footer-height});
	&.footer-hidden {
		height:calc(100% - #{$header-height} - #{$hidden-footer-padding});
	}

	.footer-hidden & {
		height:calc(100% - #{$header-height});
	}

	.SELECTOR_DROPLIST & {
		overflow: visible;
	}
}

.br-widget-footer {
	width: 100%;
	height: $footer-height;
	padding: 0 $spacing-4;
	font-size: var(--font-size-default);
	position: absolute;
	bottom: 0;

	&.demo-table {
		width: calc(100% - 32px);
		display: flex;
		justify-content: flex-end;
	}

	&.demo-map-footer {
		display: block;
		padding: 0 0;
		bottom: auto;
	}

	@media #{$media-query-mobile} {
		.slide:not(.down) ~ & { display:none; }
	}
}
.br-widget-box {
	container-type: inline-size;
}
@container (max-width: 180px){
	.br-widget-footer {
		padding-left: 0 !important;
		padding-right: 0 !important;
	}
	.pagination {
		display: flex;
	}
}

.demo-heatmap-footer {
	padding: 4px;
	display: flex;
	justify-content: flex-end;
}
.widget-image-export {
	div, span:not(.q-icon), table, td, h1, h2, h3, h4, h5, h6, p {
		font-family: var(--font-sans-serif) !important;
	}
	.footer-hidden {
		.br-widget-content { // widget height is increased to add the logo
			height:calc(100% - #{$header-height} - #{$footer-height});
		}
		.br-widget-footer {
			z-index: 10;
			display: block;
		}
	}
	.br-widget-header .rotate-infinite {
		display: none;
	}
}

.br-widget-footer {
	display: flex;
	align-items: center;

	.footer-hidden & {
		display: none;
	}
}

.br-gridster-zoom .br-widget-overlay {
	z-index: 9;
}

// make widget header move in front of content and become visible
@keyframes moveFront {
    from { z-index: -1; opacity: 0 ;}
    to { z-index: 10; opacity: 1; }
}

.header-hidden {
	.edit &:hover .br-widget-header {
		display: flex;
		width: 100%;
		animation: moveFront .1s;
		animation-delay: 1400ms;
		animation-iteration-count: 1;
		animation-fill-mode: forwards;
	}

	.br-widget-header {
		display: none;
		position: absolute !important; // must always maintain layering
		opacity: 0;
	}

	.br-widget-content {
		height: 100%;
		position: relative;
		z-index: 1;
	}
}

.br-widget-box {
	border-radius: var(--widget-border-radius);
	&.CB {
		&:not(.bg-none) {
			border: $widget-border-default;
		}
	}
	&.BUTTON {
		&.edit-mode {
			border: $widget-border-default;
		}
	}

	empty-content {
		border: $widget-border-default;
	}
}

.br-widget-warning {
	border:1px solid var(--warning-600) !important;
}

@function content-height($zoom) {
	@return calc(100% / #{$zoom} - #{$header-height} - #{$footer-height});
}

@function total-size($zoom) {
	@return calc(100% / #{$zoom});
}

@function preview-height($zoom) {
	@return calc(100% / #{$zoom} - #{$header-height});
}

$zoomLevels: (0.5, 0.75);

@each $zoom in $zoomLevels {
	.br-gridster-zoom.zoom-#{$zoom * 100} {
		.gridster {
			width: #{$zoom * 100%};
		}
		.br-widget-box>*, widget-box>* {
			transform: scale($zoom);
			transform-origin: top left;
			left: 0;
			width: total-size($zoom);
			right: auto;
		}
		widget-box>* {
			height: total-size($zoom);
		}

		.br-widget-box.br-report-widget .br-widget-header {
			z-index:1;
			position:absolute;
		}


		.br-widget-content, .an-preview-main-container {
			height: content-height($zoom);
			top: #{$header-height * $zoom};
		}

		.preview-main-container {
			top: #{$header-height * $zoom};
			height: preview-height($zoom);
		}

		.br-widget-footer {
			transform-origin: bottom left;
			bottom: 0;
		}

		.cb-overlay {
			transform: none;
			left: -1px;
			right: -1px;
			bottom: -1px;
			top: -1px;
			width: auto;
		}
		.widget-dropdown-menu, .filters-dropdown-menu {
			transform: scale($zoom);
			transform-origin: top left;
		}
	}
}

/* main widget title spacing */
.br-widget-box {
	filter-applied-dropdown { display:inline-block; float:none; }
}
/* end main widget title spacing */


.filters-dropdown-menu menu:focus {
	outline: 2px solid var(--focus-outline-color);
}



.br-widget-options {
	.analytics-widget & { display:none; }
	.analytics-widget .audit-mode & {display:flex;}

	.gridster-item.selected .analytics-widget, .br-widget-box.focus-within &, .analytics-widget:hover &, .br-widget-box:focus-within & {
		display:flex;
		outline-offset: -1px;
		padding-left: $spacing-3
	}

	.widget-header-menu {
		vertical-align: middle;
	}



	/* 1.0 only -- can remove after 1.0 is officially deprecated */

	.br-report-widget:not(.analytics-widget) & { @include flex-center; }
}




.br-widget-controller {
	position: relative;
	width: 100%;
	height: 100%;
}

.br-widget-settings {
	background-color: $white;
}

.br-widget-settings .settings-body {
	position: absolute;
	top: 0;
	bottom: 50px;
	left: 0;
	right: 0;
	padding: 10px;
}

.br-widget-settings .settings-footer {
	position: absolute;
	bottom: 0;
	height: 50px;
	right: 0;
	padding-right: 10px;
}

.br-widget-settings .settings-footer a {
	margin-left: 5px;
}

.br-widget-placeholder .br-widget-header {
	visibility: hidden;
}

.br-widget-overlay {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
}


/* for ie9-10, bug with video iframe z-index */
.br-widget-box.CONTENT .br-widget-overlay {
	background: $white;
	opacity: 0.01;
}

.iframe-cover {
	border: none;
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: -1;
}

.br-legacy-widget-message {
	background: var(--white);
	background-position: center;
	font-style: italic;
	font-size: var(--font-size-default);
	opacity: 0.7;
}

.br-widget-tabs .nav-tabs {
	margin-bottom:0;
}

.no-animation, .no-animation .br-widget-box {
	transition: none !important;
}

.no-child-animation * {
	transition: none !important;
}

.cb_multi_metric_chart_tooltip {
	padding: 2px;
	border-radius: 5px;
	background-color: $white;
	position: relative !important;
	z-index: 500 !important;
}

.cb_multi_metric_chart_tooltip .cb_multi_metric_chart_category_label {
	font-size: var(--font-size-0_75rem);
	font-weight: bold;
}

.nps_breakdown {
	overflow: visible !important;
}

.sentiment_breakdown {
	overflow: visible !important;
}

.nps_breakdown .highcharts-container{
	overflow: visible !important;
}

.sentiment_breakdown .highcharts-container{
	overflow: visible !important;
}

.br-widget-box.CONTENT .br-widget-caption {
	display: none;
}

.mobile-layout {

	.br-home-gridster {
		position: absolute;
		top: 0;
		bottom: 8px;
		padding-top: 1px;
	}

	.gridster-loaded .gridster-item{
		top: inherit !important;
		left: inherit !important;
		margin-top: 10px !important;
		margin-left: auto !important;
		margin-right: auto !important;
		width: 96% !important;
		height: auto !important;
		position: relative;

	}

	.br-widget-box {
		position: static;
		height: auto;
		width: 100%;
	}
	.br-widget-header{
		position: static;
	}
	widget-menu {
		display: none;
	}
	.report-header-run-as-date {
		margin-right: 15px;
	}

	.br-widget-content, .preview-main-container{
		position: static;
		top: auto;
		left:auto;
		right:auto;
		bottom:auto;
		transition: max-height 0.5s ease;
	}
	.br-widget-box.CONTENT .br-widget-header {
		border: 0;
		position: static !important;
	}
	.br-widget-box.CONTENT .br-widget-content {
		position: relative !important;
	}

	.br-widget-box.CONTENT .br-widget-content {
		max-height: 1000px;
		display: none;
		&.down {
			display: block;
		}
	}

	.br-widget-box.CONTENT .br-widget-caption {
		display: block;
		&.down {
			display: none;
		}
		.text {
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 2;
			text-overflow: ellipsis;
			word-wrap: break-word;
			overflow: hidden;
		}
	}

	.br-widget-box.CONTENT.TEXT .br-widget-header{
		display: block;
		visibility: visible;
		opacity: 1;
	}

	.br-widget-box.CONTENT.TEXT .slide{
		max-height: 0;
	}
	.br-widget-box.CONTENT.TEXT .slide.down{
		max-height: 1000px;
	}
	.br-widget-content img{
		height: 100%;
		width: 100%;
	}

	.slide{
		max-height: 0;
		transition: max-height .3s ease $css-animation-duration;
	}

	.slide.down{
		max-height: 5000px !important;

		/* open to 75% of the height of the screen */
		.br-report-widget & { height:75vh; }
	}

	.TABLE .slide.down {
		height: 400px;
	}

	.fade-out {
		visibility: hidden;
		opacity: 0;
		line-height: 0;
		transition:visibility 0s 0.3s, opacity 0.3s linear;
	}

	.fade-out > *{
		margin-top: -10000px;
		transition: margin-top 0s 0.3s;
	}

	.fade-in {
		visibility: visible;
		opacity: 1;
		transition: opacity 0.3s linear;
	}

	.desktop-view{
		display: none !important;

	}
	.mobile-view{
		display: block !important;
	}

	.filter-icon{
		font-size: 0.8em;
		width: 20%;
		padding: 4px;
	}

	.document-preview-neutral{
		padding: 11px 12px !important;
	}

	.document-tile{
		overflow: hidden;
		margin: -10px -15px;
		padding: 10px 15px;
	}

	.modal-body{
		overflow: auto;
	}
}

@media print and (orientation: landscape) {
	.main-content{
	   width: 95% !important;
	}
}

@media print and (orientation: portrait) {
	.gridster{
		width: 100%;
		height: 100% !important;
	}

	.gridster-loaded .gridster-item{
		top: inherit !important;
		left: inherit !important;
		margin-top: 20px !important;
		margin-left: auto !important;
		margin-right: auto !important;
		width: 96% !important;
		height: auto !important;
		position: static;

	}

	.br-widget-box{
		position: static;
		height: auto;
		width: 100%;
	 }
 }



.br-widget-box .dropdown-header {
	white-space: nowrap;
}

.history .br-widget .br-widget-options { display: none; }
.history, .preview-as {
	.hide-in-special-mode { display: none !important;}
}

.br-widget-box.CONTENT {
	@media #{$media-query-mobile} {
		.br-widget-header {
			visibility: visible;
			opacity: 1;
		}
	}
}

.br-widget-total {
	bottom: 0;
	right: 0;
	margin: 0;
	outline: 4px solid var(--white);
	@media #{$media-query-mobile} {
		.slide:not(.down) ~ & { display:none; }
	}
}

.br-widget-summary {
	display: flex;
	justify-content: space-between;
	.br-widget-summary-item {
		margin-top: 2px;
		margin-bottom: 2px;
		padding-right: $spacing-4;
		padding-left: $spacing-4;
		@media #{$media-query-mobile} {
			.slide:not(.down) ~ & { display:none; }
		}
	}
	div:last-child {
		margin-left: auto;
	}
}

.br-widget-summary-divider {
	display: none;
}

.widget-image-export .br-widget-summary {
	justify-content: flex-end;
	.br-widget-summary-divider {
		display: inline-block;
	}
}

.pdf-export .br-widget-total {
	z-index:2;
}

.image-export-watermark {
	font-family: var(--font-sans-serif) !important;
	padding-left: $spacing-4;
	bottom: 0;
	height: 25px;
	left: 0;
	position: absolute;
	margin: 0;
	display: none;
	.widget-image-export & {
		display: block;
	}
	img {
		height: 16px;
	}
	&.dark-theme img {
		height: 16px;
	}
}

.cloud-selection-actions {
	position: absolute;
	right: 10px;

	a {
		margin: 10px;
		line-height: 32px;
		height: 32px;
		font-size: var(--font-size-0_875rem);
	}
}

.cloud-selection-table {

	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 20px;

	table {
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 100%;
	}

	thead, tbody {
		display: block;
		width: 100%;
	}

	tbody {
		overflow-x: hidden;
		overflow-y: auto;
		height: calc(100% - 30px);
	}

	.checkbox-cell {
		width: 75px;
	}

	.word-cell {
		width: calc(100% - 75px);
	}

	tr {
		border: 0;
		line-height: 32px;
		height: 32px;
		font-size: var(--font-size-0_875rem);
		border: 1px solid $table-border-color;
		border-width: 0 0 1px 0;
		display: block;
	}
}

copy-submenu {
	input {
		-webkit-appearance: searchfield !important;
	}
	input::-webkit-search-cancel-button {
		-webkit-appearance: searchfield-cancel-button !important;
	}
	ul {
		bottom: auto;
	}

	&.dropup .dropdown-menu {
		bottom: 0;
		top: auto;
	}
}

.widget-warning-row {
	padding-top: 20px;
	color: var(--danger-600);
	font-size: var(--font-size-0_875rem);
	text-align: right;
	padding-right: 15px;
}


.widget-config-selector {
	&, select-from-hierarchy, .br-color-dropdown {
		display: flex;
		flex: 1;
		align-items:center;
		flex-wrap: wrap;
		max-width: 100%;
	}

	alert {
		margin-top: 16px;
		margin-bottom: 0;
	}
}

.cb-analytic-map-props {
	color-selector.widget-config-selector {
		display: block;

		.br-color-dropdown {
			display: block;
			width: calc(100% - 32px);
		}
	}
}

.grouping-options {
	display: flex;
	flex: 0 42px;
	align-items: center;

	.cb_an_line &, .cb-an-heatmap-props & { flex: 0 63px; }
	.new-settings-style .br-color-dropdown & { display: none; }
}

.pdf-mode {
	.slick-viewport, .table-body-container, .dex-left-pane>.h-100-percent>.dex-list-group,
	.dex-center-pane>.dex-panel>.main-panel, .doc-explorer-panel>.panel-body, .br-widget-view {
		overflow: hidden!important;
	}
}

.responsiveness-status-bar {
	span {
		margin-left: 8px;
	}

	.view-results {
		text-decoration: underline;
	}
}

.additional-information-toggle .q-icon-info:hover {
	cursor: pointer;
}

.highcharts-reset-zoom > text {
	font-size: var(--font-size-default-font-size) !important;
	font-family: inherit !important;
	font-weight: 400 !important;
}

.global-other-widget-row {
	height: 400px;
}

.home-page-widget .hide-on-home-page {
	display: none !important;
}

.adhoc-widget .hide-on-adhoc-widget {
	display: none !important;
}

.custom-title-spacer {
	display: none;
}

.metric-config {
	max-height: 60vh;
	.period-0 #dateCustomPicker {
		position: fixed;
		top: 110px;
	}
	.period-1 #dateCustomPicker {
		position: fixed;
		top: unset;
		bottom: 10px;
	}
}

.pdf-export .gauge-container svg.highcharts-root {
	height: 50%;
	transform: translateY(50%) scale(2);
}

.widget\:active {
	z-index: 3;
}

// prevent widget menu from being displayed with overlap
.gridster-widget:not(.widget\:active) .widget-dropdown-menu { display: none !important; }

.cb-analytic-map {
	real-data-preview {
		.chart-demo {
			position: absolute;
			height: calc(100% - 20px) !important;
		}

		&.chart-has-changes {
			.chart-demo {
				top: 8px;
			}
		}
	}
}

metric-real-data-preview {
	.chart-demo {
		position: relative;
	}
}
