.cb-table,
.table tr,
.cases-table tr,
.br-grid .slick-row,
.br-log-grid .slick-row {

  .cb-toggle-button-container {
    background: transparent !important;
    align-items: center !important;
    display: flex !important;
    border-width: 0 !important;
    box-sizing: border-box !important;
    width: inherit;
    height: 100% !important;
    &:focus, &:focus-visible {
      outline: none !important;
    }
    .toggle-switch-animate {
      position: relative;
      box-sizing: border-box;
      width: 48px;
      height: 24px;
      padding: 0;
      overflow: hidden;
      border-style: solid;
      border-width: 2px;
      border-radius: 9999px;
      border-color: var(--gray-900);
      cursor: pointer;
      &.disabled {
        cursor: not-allowed !important;
      }
      &:hover {
        background-color: var(--action-900);
        &.switch-on {
          border-color: var(--action-900);
        }
        &.switch-off {
          border-color: var(--action-900);
        }
      }
      &.switch-on {
        border-color: var(--action-900);
        background-color: var(--action-900);
        &:hover {
          background-color: var(--action-900);
        }
      }
      &.switch-off {
        background-color: transparent;
        transition: background-color 0.3s cubic-bezier(0, 0, 0.2, 1),
        border-color 0.3s cubic-bezier(0, 0, 0.2, 1);
        &:hover {
          background-color: var(--action-900);
        }
      }

      .toggle {
        top: 1px;
        width: 18px;
        height: 18px;
        transition: left 0.3s cubic-bezier(0, 0, 0.2, 1);
        position: absolute;
        border-radius: 9999px;
        &.switch-on {
          left: calc(100% - 20px);
          background-color: var(--white);
        }
        &.switch-off {
          left: 2px;
          background-color: var(--gray-900);
        }
      }
    }
  }


}

.transform-70-percent {
  transform: scale(0.7) !important
}
