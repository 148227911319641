$chicklet-background: $white;
$chicklet-border: var(--gray-600);

/* bottom to top layer order */
$layering: color-bar, expression-container, chicklet-options, chicklet, chicklet-delete;


#custom-expression-container {
	background: var(--gray-200);
	display: block;
	min-height: 80px;
	padding: 5px 0 0 5px;
	position: relative;
	text-align: left;
	z-index: index($layering, expression-container);
}

.drop-input {
	height: 40px;
	width: 200px;
}


.expression-drop-between {
	border-style:dotted;
	border-width:0;
	display: inline-block;
	height: 30px;
	line-height: 1em;
	margin: 0 -2px 0 0;
	vertical-align:middle;
	width:2px;

	&.drag-enter {
		border-width:2px;
		margin:0 2px 2px 0;
		width:30px;
	}
}

.expression-placeholder {
	border-style: dotted;
	border-width: 2px;
	border-color: $chicklet-border;
	display: inline-block;
	text-align: center;
	margin: 0 2px 2px 0;
	padding: 0 12px;

	&:not(.drag-enter) { border-color: $chicklet-border; }
}

.expression-chicklet-wrap {
	display: inline-block;
	position:relative;
}

.expression-chicklet {
	background-color: $chicklet-background;
	border-radius: $standard-border-radius;
	border-style:solid;
	border-width:1px;
	cursor: move;
	display: inline-block;
	height: 30px;
	line-height: 1em;
	margin: 0 2px 2px 0;
	min-width: 30px;
	padding: 7px 12px;
	position: relative;
	text-align: center;
	user-select: none;
	z-index: index($layering, chicklet);

	&:not(.drag-enter) { border-color: $chicklet-border; }

	&.drag-enter { border-style:dotted; border-width:2px; }
}

.calculation {
	margin-right: 0;

	& div { display:none; }

	&:hover div { display: block; }
}

.expression-delete-icon {
	cursor: pointer;
	display: none;
	font-size:var(--font-size-0_75rem);
	position: absolute;
	right: 2px;
	top: 2px;
	z-index: index($layering, chicklet-delete);

	.expression-chicklet:hover > & { display: block; }
}

#expression-creation-bar{
	position:relative;
	z-index: index($layering, chicklet-options);
}

popup-attribute-selection p { margin-bottom:5px; }

.calculation-opts > span {
	visibility:hidden;

	:hover > & { visibility:visible; }
}

.variable-popup {
	max-height: 400px;
	position: absolute;
	background: $white;
	overflow: auto;
	border: 0;
	border-radius: $dropdown-border-radius;
	@include box-shadow($dropdown-box-shadow);
	cursor: pointer;
	font-size: var(--dropdown-font-size);
	margin-top: -3px;
	z-index: 1050;

	.menu-item {
		padding: #{($dropdown-item-target-height - $dropdown-line-height) * 0.5} $menu-item-horizontal-padding;
	}
	.divider {
		height: 1px;
		background-color: $dropdown-menu-divider-color !important;
		margin: $menu-divider-vertical-margin 0;
		padding:0;
	}
}


.type-TEXT {
	.type-distinct{
		float:left
	}
	>.calculation-opts>:not(.type-distinct){
		opacity:0;
		float:right;
	}
}

.operator-buttons > .btn {
	text-transform: none;
}
