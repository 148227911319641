
:root {
	--button-font-size: var(--font-size-default);
	--button-font-weight: 600; //semibold
	--button-border-radius: 4px;
}

$button-border-width: 1px;
$v-padding: ($button-height - ($button-border-width * 2) - $button-font-size) * 0.5;

$btn-width: 34px;

.btn {
	border-radius: var(--button-border-radius);
	border-width:$button-border-width;
	border-style:solid;
	border-color:transparent;
	cursor: pointer;
	font-size: var(--button-font-size);
	font-weight: var(--button-font-weight);
	background: var(--button-background);
	height: calc(2.5 * var(--button-font-size));
	line-height:1 !important;
	padding: calc(.5 * var(--button-font-size)) var(--button-font-size);
	text-align: center;
	vertical-align:top;
	text-transform:capitalize;

	@if ($css-animation-duration > 0) {
		transition: background $css-animation-duration, border-color $css-animation-duration, color $css-animation-duration;
	}

	&.btn-link, &.btn-text { text-decoration: none; }

	&.btn-text {
		background: none;
		color: var(--white);
		border-color: var(--white);
	}

	+ .btn-group, .btn-group + &, + .btn { margin-left:$button-spacing-between; }


	&.btn-icon {
		padding: $v-padding .5em;
	}

	&, &:active, &.active {
		@include box-shadow(none); // remove inner box shadow on some buttons
		&:focus, &.focus { outline: none; z-index:1; }
	}

	&[disabled] {
		cursor: not-allowed;
		opacity: .5;
	}

	&.btn-sm {
		font-size: $button-sm-font-size;
		height: 2.5rem;
		padding: 0 $button-sm-h-padding;
	}

	&.btn-xs {
		$v-padding: 0;

		font-size: $button-xs-font-size;
		height: $button-xs-height;
		width: $button-xs-height;
		padding: 0;
	}

	&, &.active, &:hover {
		// calendar plugin uses this and it sucks
		.text-info { color: inherit; }
	}

	&.no-border, &.no-border:hover, &.no-border[disabled] { background: none; }

}
.modal-footer .btn-secondary {
	border: none;
}

// button with both text and an icon
$button-icon-to-text-distance: $spacing-3;
.btn-icon-left {
	[class*="q-icon"] { margin-right: $button-icon-to-text-distance; }
}

.btn-icon-right, .uib-dropdown-toggle {
	[class*="q-icon"], .caret { margin-left: $button-icon-to-text-distance; }
}

.btn-icon-left, .btn-icon-right {
	align-content: center;
	align-items: center;
	display: inline-flex;
}

button, button * {
	&[class*="q-icon"]:before, &[class^=q-icon]:before {
		font-weight: 400
	}
}






// w/ dropdown caret
$dropdown-caret-width: $spacing-3;
$text-to-dropdown-caret-distance: $spacing-3;
$border-to-dropdown-caret-distance: $spacing-4;

%dropdown-caret {
	position: absolute;
	right: $border-to-dropdown-caret-distance;
	top: calc(50% - 2px);
	line-height: 0;
}


// need to merge these with .btn-dropdown class below to make them all the same
// but first attempt created issues with br-select dropdowns
.btn, button {
	&[uib-dropdown-toggle], &.dropdown-toggle {
		position:relative;

		.caret {
			@extend %dropdown-caret;
			top: calc(50% - 2px);
		}
	}

	&.btn-sm {
		&[uib-dropdown-toggle], &.dropdown-toggle {
			$text-to-dropdown-caret-distance: $spacing-2;
			padding-right: (($button-sm-h-padding - $button-border-width) + $dropdown-caret-width + $text-to-dropdown-caret-distance) !important;

			.caret { right: $button-sm-h-padding; }
		}
	}

	[disabled] .caret  {color: var(--gray-600);}
}

dashboard-actions, .br-book-actions-panel {
	.btn.btn-icon { padding: $spacing-3 !important; }
}

.btn-dropdown {
	[class*="caret"], [class*="icon-triangle"] {
		margin-left: $text-to-dropdown-caret-distance;
	}

	&, & > * {
		display: flex;
		align-items: center;
	}
}


// a label to go next to a button, maintaining similar sizing/alignment
.btn-label {
	border: $button-border-width solid transparent;
	line-height: $button-font-size;
	margin-bottom: 0;
	padding: $v-padding #{$spacing-4 - $button-border-width};
}



.btn-secondary, .btn-default {
	color:$button-secondary-inactive-text-color;
    border:$button-border-width solid $button-secondary-inactive-border-color;

	// button is deemphasized but not disabled
	&.btn-secondary-fade {
		color: var(--gray-400);
		border-color: var(--gray-400);
	}
}



.btn-group {
	display: flex;

	.btn {
		margin-left:#{-1 * $button-border-width};
		border-radius:0;

		&:last-child, &.last-child, &:last-of-type { border-radius:0 var(--button-border-radius) var(--button-border-radius) 0;}
		&:first-child, &.first-child { border-radius: var(--button-border-radius) 0 0 var(--button-border-radius); border-left-width: $button-border-width; margin-left:0;}

		&:first-child:last-child, &.first-child.last-child { border-radius: var(--button-border-radius); }	// group with one button (edge case, but it exists)

		&:disabled, &:disabled:hover{
			border-color: var(--gray-600);
			color: var(--gray-600);
			cursor: no-drop;
		}

		&.btn-selected {
			z-index:2;
		}
	}

	+ .btn-group { margin-left:$button-spacing-between; }

	&.btn-group-sm {
		> .btn {
			font-size:var(--font-size-0_875rem);
			padding: 5px 12px;
			height:auto;
		}
	}

	&.btn-group-text, &.btn-group-link {
		> .btn, > .btn:hover {
			border-color: transparent !important;
		}
	}


	checkbox-button {
		> .btn:first-child { border-left-width: 0; }

		&:last-child > .btn { border-radius:0 var(--button-border-radius) var(--button-border-radius) 0;}
		&:first-child > .btn { border-radius: var(--button-border-radius) 0 0 var(--button-border-radius); border-left-width: 1px; margin-left:0;}
	}

	&.open .dropdown-toggle { box-shadow: none;}

	&.btn-group-stacked {
		flex-direction: column;

		.btn {
			margin-left: 0;
			margin-top: -1px;
		}

		.btn:first-child, .btn.first-child { border-radius: var(--button-border-radius) var(--button-border-radius) 0 0; }

		.btn:last-child,
		.btn.last-child,
		.btn:last-of-type { border-radius: 0 0 var(--button-border-radius) var(--button-border-radius); }
	}

	.btn-parent {
		+ .btn-parent { margin-left: -1px }

		> button { border-radius: 0 !important; }
		&:first-child > button { border-radius: var(--button-border-radius) 0 0 var(--button-border-radius) !important; }
		&:last-child > button { border-radius: 0 var(--button-border-radius) var(--button-border-radius) 0 !important; }
	}
}


.btn-margin-left { margin-left:$button-spacing-between; }


.btn-flex {
    display: inline-flex;
    padding-top: 0;
    padding-bottom: 0;
    align-items: center;
    flex-wrap: wrap;
    max-width: 100%;
    white-space: initial;
    line-height: 1.5 !important;
    height: auto;
    min-height: $button-height;
    flex-direction: row;


	> * {
		display: flex;
		flex: 0 1 auto;
	}

	> [class*="q-icon"], > icon {

		&:first-child { margin-right: $spacing-3; }
		&:last-child { margin-left: $spacing-3; }
	}

	span { flex: 1; }
}


.wrappable-buttons {
	@include flex;
	@include flex-wrap;
	@include flex-multiline;
	@include offset-margin($button-spacing-between);
}




@mixin button-height { height: $button-height; }
@mixin btn-height { height: $button-height; }
@mixin button-min-height { min-height: $button-height; }
@mixin btn-min-height {	min-height: $button-height; }



.btn-icon[ngbDropdown] > [ngbDropdownToggle] {
	width: $button-height;
	height: $button-height;
	display: inline-flex;
	margin-left: -1px; // adjust for border
	margin-top: -1px;
	align-items: inherit;
	justify-content: inherit;
}


/* adding utility classes for buttons to prevent overriding by nested scss classes*/
.bg-focus-danger-700:focus {
	background-color: var(--danger-700) !important;
}
.bg-focus-success-700:focus {
	background-color: var(--success-700) !important;
}
.text-focus-action-900 {
	&:focus, &:focus-visible {
		color: var(--action-900) !important;
	}
}






