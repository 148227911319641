
@mixin align-center {
	align-content: center;
	align-items: center;
}

.align-center { @include align-center; }

.align-items-center { 
	> [class*="q-icon"] { line-height:1; }
}


@mixin flex-multiline {
	@include flex-direction-row;
	@include flex-wrap;

	// give vertical margin to items only when wrapping
	margin-top: (-1 * 8px);
	> * { margin-top: 8px; }
}

@mixin flex-singleline {
	@include flex-direction-row;
	@include flex-nowrap;
}

.flex-multiline { @include flex-multiline; }


// make a new row within a flex-direction:row wrapper
// .flex-row { flex: 0 0 100%; }


.flex-singleline { @include flex-singleline}



// responsive
	@media screen and #{$media-query-mobile-and-small} {
		.stack-medium-and-smaller {
			flex-direction: column;
		
			[class*=max-width] { max-width: none; }
		}
	}


	@media #{$media-query-mobile} {
		// stack form groups in mobile
		.form-horizontal .form-group {
			@include flex-multiline;

			> * {
				flex: 0 0 100%;			
				padding-left:16px;
				padding-right:16px;
			}
		}

		.form-horizontal .help-label { justify-content: flex-start !important; }
	}
		
	@media screen and #{$media-query-mobile-and-small} {
		.form-horizontal .form-group.stack-md {
			@include flex-multiline;

			> * {
				flex: 0 0 100%;			
				padding-left:16px;
				padding-right:16px;
			}
		}		
	}


	// align text right on flex element
	.flex.text-right { justify-content: flex-end; }