.page {
	padding: 0 20px;
}

.block {
	float: left;
	padding-right: $spacing-4;
}



.max-width-800 {
	max-width: 800px;
}
.zero-height {
	height: 0;
}

.shorter-from-right {
	width: calc(100% - 40px) !important;
	display: inline-block !important;
}

.clear-left { clear: left; }
.clear-right { clear: right; }
.clear-both { clear: both; }