// special styles for when e2e tests are running


.e2e-testing {
	.modal.fade { opacity: 1; }

	.modal.fade .modal-dialog, .modal.in .modal-dialog {
		transform: translate(0, 0);
	}

	.flip-container {
		.flipper, .front, .back { transition: 0s; }
	}
}
