$logo_width: 300px;
$logo_height: 60px;

.cell-palette-preview {
	display: flex;
	align-items: center;
	.color-swatch-bar {
		.color-swatch {
			width: 20px;
			height: 20px;
		}
	}
}

$paletteItemSize: 60px; // this value is used in paletteEditor and in tests

#palettes-container { min-height: $paletteItemSize;}

.br-palette-dialog {
	.palette-color {
		width: $paletteItemSize;
		height: $paletteItemSize;
	}

	.palette-drag-clone {
		width: $paletteItemSize;
		height: $paletteItemSize;
		// we need these offsets as draggable clone doesn't work well in modal
		margin-top: -30px; // modal top offset
	}
}

.palette-copy { min-width: 15em; }

.branding-preview {
	height: 400px;

	.branding-preview-button {
		height: $spacing-4;
	}
}

.custom-logo {
	width: $logo_width;
	height: $logo_height;
	color: var(--gray-400);
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	line-height: $logo_height;
}

.custom-logo.logo-preview {
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
}

.preview-logo-name {
	width: $logo_width - $btn-width;
}

.custom-labels {
	white-space: nowrap;
}
