@mixin no-split {
	-webkit-column-break-inside: avoid;
	page-break-inside: avoid;
	break-inside: avoid;
}

@mixin column-definition($columnCount) {
	-webkit-column-count: $columnCount;
	-moz-column-count: $columnCount;
	column-count: $columnCount;
	padding-left: $spacing-4;
	padding-right: $spacing-4;
	-webkit-column-gap: $spacing-4;
	-moz-column-gap: $spacing-4;
	column-gap: $spacing-4;}

[class*="columns-"] {
	> * { @include no-split; }
}


@for $columnCount from 2 through 6 {
	.columns-#{$columnCount} { @include column-definition($columnCount); }
}

// collapse to single column in mobile
@media #{$media-query-mobile} {
	.columns-2, .columns-3, .columns-4, .columns-5, .columns-6 { @include column-definition(1);	}
}


// maximum 2 columns in small to medium resolution
@media only screen and #{$media-small-to-medium-resolution} {	
	.columns-3, .columns-4, .columns-5, .columns-6 { @include column-definition(2) }
}