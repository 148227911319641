.br-warning-dialog {
	.modal-header, .modal-footer { background-color: var(--white) !important; }
}

.br-warning-confirm-dialog {
	.modal-header {	background-color: var(--danger-700) !important; }
	#btn-dialog-save {
		color: var(--white) !important;
		background-color: var(--warning-700) !important;
		border-color: var(--warning-700) !important;
		&:hover { background-color: var(--warning-800) !important; }
		&:not(:hover) { background-color: var(--warning-800) !important; }
	}
}

.warning-modal {
	.modal-header {
		background-color: var(--white) !important;
		.close, cb-modal-close button {
			color: var(--gray-1000) !important;
			&:hover {
				color: var(--gray-400) !important;
			}
		}
	}
	#btn-dialog-save {
		color: var(--white) !important;
		background-color: var(--warning-700) !important;
		border-color: var(--warning-700) !important;
		&:hover { background-color: var(--warning-800) !important; }
		&:not(:hover) { background-color: var(--warning-800) !important; }
	}
}

.br-error-dialog .br-dialog-message {
	white-space: pre-line;
}
.modal-header {
	&.danger-modal, .danger-modal > & {
		background-color: var(--white);
		color: var(--gray-1000);

		.close, cb-modal-close button {
			color: var(--gray-1000) !important;
		}
	}

	cb-modal-close {
		float: right;
		margin-top: -8px;
	}
}
