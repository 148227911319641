*:focus {
	outline: 0;
}

a:hover {
	cursor: pointer;
}

a {
	text-decoration: none;
	&, &.btn, &[uib-dropdown-toggle] {
		&:hover, &:focus { text-decoration: none; } // broadly disable underline on hyperlinks
	}
}

ul {
	list-style: none;
	&.bullet { list-style: disc; }
}

.view-text {
	ul {
		list-style: initial;
	}
}

.hide-drag-preview.cdk-drag-preview {
	display: none !important;
}

form { margin-bottom: 0; }

.round-subtle { border-radius:$standard-border-radius; }

.standard-border { border: $standard-border; }

.secondary-header {
	position: relative;
}

.overflow-auto {
	overflow: auto;
}

.overflow-y-auto {
	overflow-y: auto;
}

.overflow-y-hidden {
	overflow-y: hidden;
}

.overflow-x-auto {
	overflow-x: auto;
}

.overflow-x-hidden {
	overflow-x: hidden;
}

.br-global-view {
	display: flex;
	flex: 1;
	flex-direction: column;

	> * {
		display: flex;
		flex-direction: column;
		flex: 1;
		max-width: 100%;
	}

	.in-iframe & > * { padding-top: 0; }
}

.pdf-export .br-global-view,
.pdf-export .dashboard-view-container { top: 0 !important; }

// hide items in PDF mode
.br-login .secondary-header,
.pdf-export filters-panel,
.pdf-export .book-dashboard-header-panel,
.pdf-mode .hide-pdf, .pdf-export .hide-pdf, .widget-image-export .hide-pdf { display: none !important; }

.show-iframe {
	display: none;

	.in-iframe & { display: flex; }
}

.br-login .hide-login,
.in-iframe .hide-iframe,
.modal-fullscreen .hide-fullscreen {
	display: none;
}

.embed-view {
	.hide-embed {
		display: none;
	}
}

button[uib-dropdown-toggle], div[uib-dropdown-toggle] {
	text-align: left;

	&.btn-icon {
		text-align: center;
		background: none;
	}
}


.dropcenter>.dropdown-menu {
	left: -1500%;
}

#dsh-context-menu .dropdown-menu>li>a {
	overflow: hidden;
	text-overflow:ellipsis;
}

.br-error-text-align {
	text-align: center;
	padding-top: $spacing-3;
}


.br-upload-button-wrapper>input, .br-upload-button-wrapper>input[disabled] {
	position: absolute;
	top: 0;
	right: 0;
	margin: 0;
	padding: 0;
	cursor: pointer;
	opacity: 0;
	z-index:-10;
	filter: alpha(opacity=0);
}

.drop-shadow { box-shadow: $standard-box-shadow; }

.add-user-cps .form-group {
	margin-right: auto;
	margin-left: auto;
}

[class^="col"].no-gutter{padding:0;}

.mobile-layout {
	.secondary-header {
		position: relative;
		top: auto;
		height: auto;
		left: auto;
		right: auto;
		z-index: 1002;
	}

	.br-global-view {
		position: static;
		top: auto;
		bottom: auto;
		left: auto;
		right: auto;
	}
}

@media print {
	a[href]:after {
		content: none !important;
	}
}

.justify {
	display: flex;
	justify-content: space-between;
}

refresh-trigger {
	&.btn-primary {
		span {
			color:inherit;
		}
	}
	display: inline-block !important;
}
.cg-busy-default-sign { border:0; border-radius:0;}

.filter-date-wrap {	padding-bottom:20px; vertical-align:top; }
.filter-time-wrap { bottom:10px; position:absolute;}

.cxs-element-overlay {
	width: 100%;
	height: 100%;
	background: none;
	top: 0;
	left: 0;
	position: absolute;
	z-index: 10000000 !important;
}

.cursor-move {
	cursor: move;
}

.cursor-loading * {
	cursor: wait;
}

.cursor-text {
	cursor: text !important;
}

.font-sm { font-size:var(--font-size-0_875rem); }

.user-selector {
	display:inline-block;
	width: 300px;

	.button-text {
		overflow: hidden;
		text-overflow: ellipsis;
		width: calc(100% - 14px);
		float: left;
	}

	.search-box {
		line-height: 18px;
	}

	.hierarchy-select-wrapper .hierarchy-item {
		width: 250px;
		overflow-x: hidden;
		text-overflow: ellipsis;
		padding-right:0;
	}
}

.page-tab-content {
	min-height:768px;
}


.vertical-scroll {
  max-height:250px;
  overflow-x: hidden;
  overflow-y: scroll;
}


// elements using this class will also need td and th widths specified in px

.scrollable-table {
	table-layout: fixed;
	border-collapse: collapse;

	thead tr {
		display: block;
		overflow-y: scroll;
	}

	tbody {
		overflow: auto;
		display: block;
		max-height: 50vh;
		word-break: break-word;
	}
}

.z-index-1 {
	z-index: 1;
}

.normal-line-height {
	line-height: normal;
}

.scroll-carousel-target {
	overflow: hidden;
}

.scroll-carousel-button {
	width: 40px;
	a {
		color: inherit;
	}
}

.brand-secondary-bg-fade-start .fade-container:before, .brand-secondary-bg-fade-end .fade-container:after {
	content: '';
	z-index: 10;
	position: absolute;
	width: 20px;
	height: 100%;
}

.fade-container:before {
	left: 0;
}
.fade-container:after {
	right: 0;
}

.popup-header {
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
}

.fill-container {
	width: 100%;
	height: 100%;
}

.dependencies-type-selector {
	width: 142px;
}

.row-height-input {
	width: 92px;
}

.hide-empty:empty {
	display: none !important;
}

.cb-tooltip {
	z-index: 9999;
}

.tooltip-bottom {
	top: 9px !important;
}

#preview-bar {
	background-color: var(--action-700);

	span {
		color: var(--white);
	}

	.btn {
		color: var(--white);
		border: 1px solid var(--white);

		&:hover {
			background-color: var(--white);
		}

		&:focus {
			outline-color: var(--white);
		}

		&:focus:hover {
			background-color: var(--action-800);
		}

		&:hover, &:hover > span {
			color: var(--action-700);
		}

		&:focus, &:focus:hover, &:focus > span, &:focus:hover > span {
			color: var(--white);
		}
	}
}

.border-hover-h-1:hover {
	border-left-width: 1px !important;
	border-right-width: 1px !important;
}

.border-top-hover-action-800:hover {
	border-top-color: var(--action-800) !important;
}

.border-top-action-900 {
	border-top-color: var(--action-900) !important;
}

.tab-content-fill-container {
	.tab-pane.active {
		display: flex;
		flex-direction: column;
		overflow: hidden;
		flex: 1;
		height: 100%;
	}
}


menu { 
	margin: 0;
	padding: 0;
}