.tab-definition {
	height: $spacing-6;
	display: flex;
	align-items: center;

	&.tab-required {
		.q-icon-trash, input { display: none; }
	}

	&:not(.tab-required) > span { display: none; }

	.button-text.dropdown-toggle {
		min-height: var(--input-default-height);
		height: auto;
		line-height: 1;
		padding: var(--input-padding);
		font-size: var(--font-size-default);
		border-radius: 4px;
		display: flex;
		align-items: center;
	}

	&.tab-defined .button-text.dropdown-toggle {
		border: none;
		padding-right: 8px !important;

		.caret { display: none; }
	}
}



%baseline-drag-style {
	.cdk-drag-placeholder { opacity: 0; }

	.cdk-drag-animating, [cdkdrag] { transition: transform 250ms cubic-bezier(0, 0, 0.2, 1); }
}


dashboard-list-tabs-editor {
	@extend %baseline-drag-style;

	.cdk-drop-list-dragging .tab-definition:not(.cdk-drag-placeholder) {
	transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
	}

	:not(.cdk-drag) { transition: opacity 250ms;}
	.cdk-drop-list-dragging > :not(.cdk-drag) { opacity: .3; } // fade non-draggable items while drag is being performed
}

conversation-settings {	
	.cdk-drop-list-dragging .lane-definition:not(.cdk-drag-placeholder) { opacity: .5; }
	
	.cdk-drag-animating, .cdk-drop-list-dragging .lane-definition {
		transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
	}
}

mixed-filter-builder {
	@extend %baseline-drag-style;
}