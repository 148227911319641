$pagination-height: 48px;
$footer-height: 22px;


.positiveSentiment, .positive-sentiment { color: $sentiment-positive; }
.stPositiveSentiment, .strong-positive-sentiment { color: $sentiment-strong-positive; }
.neutralSentiment, .neutral-sentiment { color:  $sentiment-neutral; }
.negativeSentiment, .negative-sentiment { color:  $sentiment-negative; }
.stNegativeSentiment, .strong-negative-sentiment { color: $sentiment-strong-negative; }

.positiveSentiment, .stPositiveSentiment, .neutralSentiment, .negativeSentiment, .stNegativeSentiment { font-size: inherit;}

/* these may be unnecessary now... */
.sentimentIcon.stNegativeSentiment:after,
.sentimentIcon.negativeSentiment:after {
	content:"\f165";
}

.sentimentIcon.stPositiveSentiment:after,
.sentimentIcon.positiveSentiment:after{
	content:"\f164";
}

.sentimentIcon.neutralSentiment:after {
	content:"\f056";
}

.double_underline {
	border-bottom: 3px double var(--gray-1000);
}

.ngram {
	border-bottom: 1px solid var(--gray-1000);
	font-size: inherit;
}

.ngramCenter {
	border-bottom: 1px solid var(--gray-1000);
	font-style: italic;
	font-size: inherit;
}

.hide-sentiment-formatting .br-sentence-text * {
	font-size: inherit !important;
	font-style: inherit !important;
	color: inherit !important;
	border-bottom: none !important;
}

.br-preview-widget-content {
	overflow-x: auto;
	overflow-y: hidden;
	bottom:$pagination-height;
	left:0;
	position:absolute;
	right:0;
	top:42px;
	position: absolute !important;

	.mobile-layout & {
		top:0;
	}

	&.filtered-by-confidentiality {
		bottom: $footer-height;
	}
}

.br-preview-chat {
	overflow: auto;
}

.TABLE .br-preview-widget-content {
	bottom: 20px;
}

.DOCUMENT-PREVIEW, .AN-VERBATIM-PREVIEW {
	.br-preview-widget-content {
		bottom: 0;

		&.filtered-by-confidentiality {
			bottom: $footer-height;
		}
	}
}

.preview-totals-container {
  font-weight: 700;
  white-space: nowrap;
  height:50px;
  line-height:50px;
  padding-left:$spacing-4;
  padding-right:$spacing-4;
  position:absolute;
  width:100%;
  z-index:100;
}

.previewTotal {	float: left; }

.iconButton {
	font-family: var(--qualtrics-icons);

	&.filter {
		&.filtered:after {
			content: "\f1ad";
			position: relative;
			display: inline-block;
			height: 12px;
			top: -8px;
			left: -4px;
			width: 12px;
			text-align: center;
			line-height: 12px;
			font-size: var(--font-size-0_675rem);
			border-radius: 50%;
			color: $white;
			background: var(--success-800);
		}
	}
}

.preview-pagination-container {
	padding-left: $spacing-4;
	height: $pagination-height;
	position: absolute;
	bottom: 0;
	border-top: $standard-border;
	width: 100%;
	overflow-x: auto;
	overflow-y: hidden;
	z-index: 1;
}

.preview-pagination-container, .sentences-pane-pagination {
	.remove-boundary > li.pagination-last { display: none !important; }
}

.sentences-pane-pagination {
	.btn-icon[disabled] {
		background-color: transparent !important;
	}
}

.preview-main-container {
	position: absolute;
	width: 100%;
	bottom: 0;
	top: 41px;
	left:0;

  drill-breadcrumbs ~ & { top:95px; }
}

.br-preview-filter-menu{
	cursor: pointer;
	float: right;
}


.br-preview-filter-menu a>i {
	position: absolute;
	right: 10px;
	line-height: 18px;
}

.preview-table-sentence-cell {
	padding: 5px;
	font-size: var(--font-size-default);
	padding: 10px 5px 10px 10px;
}


.explorer-header-menu {
	.dropdown-menu {
		width: 350px;

		li>a {
			white-space: pre-wrap;
		}
	}
}

.sentimentIcon.totalMentionsIcon:after {
   content: "\f0e6";
}

.feedback-source-container {
	overflow: hidden;
}

.feedback-preview-username h6{
	font-weight: bold;
	margin-top: 4px;
	margin-bottom: 1px;
	color: var(--action-800);
	font-size: var(--font-size-0_875rem);
}

.feedback-preview-icon {
	@include flex-center;
	@include flex-justify-center;
	@include flex-inline;
	font-size: var(--font-size-default);
	line-height: 1;
	.q-icon-minus {
		font-size: var(--font-size-default) !important; // lowering font-size to match design specs
		color: var(--gray-600) !important; // using important to override inline css styles applied in enrichment-icon.class.ts
		background-color: var(--white);
		border-radius: 1000px;
	}
}

.feedback-preview-source-text {
	text-overflow: ellipsis;
	overflow:hidden;
	display:inline-block;
	width:100%;
	white-space:nowrap;
}

.feedback-preview{
	&-thumbs-up-strong{	color: $sentiment-strong-positive; }
	&-thumbs-up{ color: $sentiment-positive; }
	&-neutral{ color: $sentiment-neutral; }
	&-thumbs-down{ color: $sentiment-negative; }
	&-thumbs-down-strong{ color: $sentiment-strong-negative; }
}

.previewTableSentimentCell {
	 width: 45px;
}

.previewTableSourceCell {
	 width: 45px;
	 padding-left: 10px;
}

.preview_content {
	padding-top: 10px;
 }

 .previewMainTable {
	 background-color: $white;
	 width: 100%;
}

.previewTableRow {
   border-bottom: solid $table-border-color 1px;
 }

.suggestion-rules-textarea { height: 86px; }

.ruleColor1 { background-color: $swim-lane-color-1; }
.ruleColor2 { background-color: $swim-lane-color-2; }
.ruleColor3 { background-color: $swim-lane-color-3; }
.ruleColor4 { background-color: $swim-lane-color-4; }

.merged-textarea-row {
	textarea {
		border-radius:0;
		margin-top: -1px;
		position:relative;
		&:focus { z-index: 1; }
	}

	&:first-of-type {
		border-top-left-radius: $standard-border-radius;
		border-top-right-radius: $standard-border-radius;
	}

	&:last-of-type {
		border-bottom-left-radius: $standard-border-radius;
		border-bottom-right-radius: $standard-border-radius;
	}
}

.preview-tabe-view {
	.slick-cell {
		padding-top: 5px;
		padding-bottom: 4px;
		white-space: normal;
		font-size: var(--font-size-default);
		overflow: visible;
		border:0;
		padding-left: 10px;
		padding-right: 10px;
	}

	.slick-header-column {
		border:0;
		font-size: var(--font-size-default);
		font-weight: 700;
		height: 40px;
		line-height: 40px;
	}

	.slick-column-name {
		padding-left: 10px;
		padding-right: 10px;
	}

	.slick-sort-indicator {
		float: none;
		margin-left: 10px;
	}

	.slick-header-columns { border-bottom: 1px solid $table-border-color;	}
}

.temp_transparent {
	opacity: 0;
	font-size: var(--font-size-default);
}

.measurmentTableCell {
	padding: 5px 10px;
}

.dynamic-border {
	border-bottom: 1px solid $table-border-color !important;
}

.dynamic-background {
	background-color: $white;
}

@for $i from 0 through 39 {
	.dynamic-cell-#{$i} {
		z-index: $i;
	}
}

.preview-dropdown-submenu {
	width: 200px;
	height: 80px;
}

.mobile-layout {
	.preview-main-container {
		position: relative !important;
		top: auto !important;
		bottom: auto !important;
		overflow: hidden;
	}

	.slick-row {
		border-bottom: 1px solid $table-border-color;
	}

	.dynamic-border {
		border-bottom: none !important;
	}

	.br-preview-widget-content .slick-row {
		border-bottom: 1px solid $table-border-color;
	}

	.slick-row .value div {
		float: none;
		display: inline-block;
	}

	.slick-row .feedback-preview-sentiment-icon {
		height: 24px;
	}

	.PREVIEW .preview-totals-container {
		position: static;
		top: auto;
		float: none;
	}

	.PREVIEW .br-preview-widget-content {
		position: static;
		top: auto;
		bottom: auto;
		left: auto;
		right: auto;
		overflow: visible;
	}

	.PREVIEW .preview-pagination-container {
		bottom: auto;
		position: static;
		overflow: hidden;
	}

	.TABLE .preview-pagination-container {
		overflow: hidden;
	}

	.PREVIEW .preview-main-container.slide.down {
		height: auto;
	}
}

.sentencesWithContext .primary-sentence { font-weight: bold; }
