$dropdown-border-radius: 4px;
$dropdown-box-shadow: 0 0 8px var(--black-50-percent);

@mixin popover {
	border: none;
	border-radius: $dropdown-border-radius;
	@include box-shadow($dropdown-box-shadow);
	font-size: var(--font-size-default);
}

.popover-base {
	@include popover;
}
