$xs-max: 767px;

$sm-min: $xs-max + 1;
$sm-max: 991px;

$md-min: $sm-max + 1;
$md-max: 1199px;

$lg-min: $md-max + 1;

$xs-resolution: "(max-width:#{$xs-max})";
$sm-resolution: "(min-width:#{$sm-min})";
$md-resolution: "(min-width:#{$md-min})";
$lg-resolution: "(min-width:#{$lg-min})";

$media-query-mobile: "screen and #{$xs-resolution}";
$media-query-xs: "screen and #{$xs-resolution}";
$media-query-sm: "screen and #{$sm-resolution}";
$media-query-md: "screen and #{$md-resolution}";
$media-query-lg: "screen and #{$lg-resolution}";

$media-mobile-to-small-resolution: $xs-resolution;
$media-small-to-medium-resolution: "#{$sm-resolution} and (max-width: #{$sm-max})";
$media-medium-to-large-resolution: "#{$md-resolution} and (max-width: #{$md-max})";


$media-query-mobile-and-small : '(max-width : #{$sm-max})';

@mixin hide-xs {
	@media #{$media-query-xs} { display: none; }
}

@mixin flex-xs {
	@media #{$media-query-xs} { display: flex; }
}

@mixin hide-sm {
	@media #{$media-query-sm} { display: none; }
}

@mixin flex-sm {
	@media #{$media-query-sm} { display: flex; }
}

@mixin hide-md {
	@media #{$media-query-md} { display: none; }
}

@mixin flex-md {
	@media #{$media-query-md} { display: flex; }
}

@mixin hide-lg {
	@media #{$media-query-lg} { display: none; }
}

@mixin flex-lg {
	@media #{$media-query-lg} { display: flex; }
}


// bootstrap's hidden-xs (etc..) cause problems when using flex,
// so this works around that issue
.hide-xs { @include hide-xs }
.hide-sm { @include hide-sm }
.hide-md { @include hide-md }
.hide-lg { @include hide-lg }

.flex-xs { @include flex-xs }
.flex-sm { @include flex-sm }
.flex-md { @include flex-md }
.flex-lg { @include flex-lg }