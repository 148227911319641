.widget-tab-panels {
	&:not(.sidebar-editor-enabled) {
		min-width:800px;
		max-width:1200px;
	}
}

.sidebar-editor {
	.panel-body {
		padding: 1rem;
	}
	tags-input .tags {
		resize: vertical;
	}
	.multiselect-chips {
		width: auto;
	}
}
.sidebar-editor-list {
	.searchable-list {
		max-width: 240px !important;
	}
	.search-list-selected-option {
		max-width: 260px;
	}
}

.chicklet {
	display: inline-block !important;
	padding: $spacing-1 $spacing-3;
	background: var(--gray-200);
	margin: 0 5px 0 0;
}

.option-chicklet {
	background: none;
	padding: $spacing-2 $spacing-3;

	&:not([disabled]) { cursor: pointer; }

	.filter-statement & {
		font-size: var(--font-size-default);
		line-height:1;
		float:left;
		margin:0;
	}

	> a { color:var(--gray-900); text-decoration:none; }
	&.option-chicklet-static { cursor:default; }
	&.option-chicklet-disabled { color: var(--gray-900); }
}

.searchable-list { min-width:240px; max-width:350px;}
.searchable-list-item { white-space: nowrap; }
.searchable-list-item > a { overflow:hidden; text-overflow:ellipsis; }
.searchable-list-search-box { margin:$spacing-3; }
.searchable-list-empty { padding:5px 15px}
.searchable-list a.blank { height: 24px; }

.test-date-filter-button {
	margin-left: 10px;
}

.filter-statement {
	position: relative;
	float: none;
	clear: both;
	margin: 0 0 $spacing-3 0;
	padding: #{$spacing-3 - $standard-border-width} 16px;
	border: solid var(--gray-600) $standard-border-width;
	border-radius: $standard-border-radius;
	width:100%;
	flex-wrap: wrap;

	.filter-date-picker { left:10%; top:90%; }

	.sidebar-editor-enabled & {
		padding-left: $spacing-3;
		padding-right: $spacing-3;
	}

}

// clearfix
.filter-statement {
	&:not(.sidebar-editor-enabled):after {
		content: '';
		display: block;
		float: none;
		clear: both;
	}
}

.filter-tools { display:inline-block; }

.filter-rule-values { margin-left:$spacing-3;}

/* date */

.filter-date-picker {
	white-space: nowrap;
	background: var(--white);
	border-radius: $dropdown-border-radius;
	@include box-shadow($dropdown-box-shadow);
	padding: $spacing-4;
	position: absolute;
	top: calc(100% + 3px);
	z-index: 10000;
}

.filter-date-picker > .filter-date-wrap { display:inline-block;}
.cx-date-filter-selector, .filter-statement, .br-user-date-filter {
	.filter-date-picker > .filter-date-wrap:first-child {
		margin-right: 15px;
		padding-right: 15px;
		border-right: $standard-border ;
	}
}

.cx-date-filter-test-button-wrap {
	width: 70%;
	margin: auto;
}

.cx-date-filter-test-wrap {
	margin-right: 15px;
	margin-left: 15px;
}

date-range {
	display: flex;
	align-items: center;

	.cx-date-range-selector:not(.exact-only) {
		min-width: 440px;
	}
}

.cx-date-range-selector {
	display: flex;
	align-items: center;
	flex-wrap: wrap;

	>date-point, >span, >.test-date-filter-button {
		display: inline-block;
	}

	.cx-date-range-to .filter-date-picker {
		left: 40%;
	}
	.cx-date-range-from .filter-date-picker {
		left: 15%;
	}

	.filter-date-picker > .filter-date-wrap:first-child {
		margin-right: 0;
		padding-right: 0;
		border-right:0;
	}

	.cx-date-point-selector {
		display: flex;
		align-items: center;
	}

	.cx-date-point-type {
		padding-right: 5px;
	}

	.cx-date-point-controls {
		display: flex;
		align-items: center;

		.period-dropdown {
			float: left;
			margin-left: 0;
			margin-right: 5px;
		}
	}

	date-point, input, .filter-text-piece, .cx-date-point-type, .cx-date-point-controls { float:left; }
	.filter-text-piece { line-height:30px; }
}

.filter-time-text, input[type="number"].filter-time-text { width: 4.125rem;}
.filter-time-selector { width: 55px; padding: 0; border-radius: $standard-border-radius; }

.br-create-filter-modal {
	tags-input { width:600px; }
}

.filter-disabled-overlay {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 10;
	background: var(--gray-400);
	opacity: 0.3;
	cursor: $disabled-cursor;
}

.lock-filters-button {
	color: var(--gray-900);
}

.filter-grouping-statement {
	display: flex;
	align-items: center;

	.filter-name { margin-bottom:0; margin-right: $spacing-4; }
	.filter-tools {
		display: flex;
		flex: 0 0 80px;
		justify-content: flex-end;
	}
}

.grouping-filters {
	flex: 1;
	> .filter-rule-values {
		margin-right: $spacing-2;
	}
}

.sidebar-editor {
	.filter-grouping-statement,
	.filter-tools {
		display: flex;
		flex: 0;
		justify-content: flex-end;
	}
}
