.br-dashboard-props {
	[class*="col-"] { padding-right: 0; }
	[class*="col-"] + [class*="col"] { padding-right: $spacing-4; }

	label { padding-left: $spacing-2; }

	[type="checkbox"] { width: $spacing-4; }
}

.dashboard-props-modal {
	.modal-body { padding-bottom: $spacing-4; }

	panel-body > {
		// make cg-busy overlay fill the panel
		.cg-busy-backdrop, [cg-busy] .cg-busy-backdrop { margin: (-1 * $panel-body-padding); }
	}
}


.br-drillable-tag-invalid span {
	text-decoration: line-through;
}
@mixin tagItem {
	background:var(--gray-600);
	border-radius:$standard-border-radius;
	border:none;
	color:$white;
	cursor:initial;
	font:14px / 1.5 normal;
	height:20px;
	user-select: none;
}
.label-tags {
	@include tagItem;
}
.tag-template {
	display: inline-flex;
	align-items: center;
	max-width: 100%;

	*:first-child {
		width: $spacing-4; // fixed-width so label doesnt change size when icon changes
		margin-right: $spacing-2;
	}
}
labels {
	.tag-item {
		i {
			display: block;
		}
		a {
			display: none;
		}
	}
	.tag-item:hover {
		i {
			display:none;
		}
		a {
			display: block;
		}
	}
}
