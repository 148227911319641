#bypass-block a, #bypass-modal-block a {
	position: absolute;
	top: -50px;
	border-radius: 2px;
	z-index: 9999;
	
	text-decoration: underline;
	font-size: small;

	transition: top 0.3s ease-out 0s, background 0.3s linear 0s;

	&:focus {
		top: 3px;
	}
}
