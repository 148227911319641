custom-math-editor {
	display: block;
	> div {
		padding: 8px;
		min-height: 40px;
		border-radius: 4px;
		font-size: var(--font-size-0_875rem);
	}

	.dropdown-menu {
		ul {
			max-height: 300px;
		}
	}
}

.formula-token, .text-token {
	position: relative;
	font-family: monospace;
	cursor: pointer;

	* { font-family: inherit; }
}

.formula-token {
	background: var(--gray-200);
	border-radius: 4px;
	padding: $spacing-1 $spacing-2;

	&:hover {
		background: var(--danger-600);
		color: $white;
		z-index: 1;
	}

	.remove { display: none;}
	&:hover .remove {
		display: inline-block;
		position: absolute;
		z-index: 2;
		left: calc(100% - 3px);
		top: 0;
		background-color: inherit;
		border-radius: inherit;
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
		padding-left: 6px;
		padding-right: $spacing-2;
	}
}

.text-token {
	&.error:after {
		content: ' ';
		border: 1px dotted var(--danger-600);
		border-width: 1px 0 0 0;
		height: 0;
		width: 100%;
		position: absolute;
		left: 0;
		bottom:0;
	}
}
