@import "./conversation-variables";


$conversation-horizontal-margin: $spacing-5;
$transition-time: .3s;
$overtalk-background-color: var(--warning-200);

$show-participant-margin: $spacing-4;

.conversation-segment {

	display: block; // required to properly calculate scroll indicator (offsetHeight is 0 without this)

	&.same-channel-as-last.hidden-transcript { display: none; }
	&.hidden-transcript .sentence-container {
		align-items: center;
		border-radius: 50% !important;
		cursor: pointer;
		display: flex;
		height: $spacing-8;
		justify-content: center;
		padding: 0;
		width: $spacing-8;

		p, .timestamp, div:not(.participant-label), sentence-pills, .participant-label b { display: none; }

		.speaker-icon {
			position: relative;
			top: auto;
			left: auto;
			right: auto;
			bottom: auto;
		}

		.show-participant {
			display: block;
			font-size: var(--font-size-0_875rem);
			left: calc(100% + #{$show-participant-margin});
			max-height: 3em;
			overflow: hidden;
			position: absolute;
			width: 200px; // arbitrary. just needs to be long enough to fit the text
		}
	}

	.show-participant { display: none; }

	.sentence-icon {
		font-size: var(--font-size-2_125rem);
		position: absolute;
		left: $spacing-3;
		top: 0;
	}

	.sentence-container {
		border-color: var(--border-color-client);
		background: var(--bg-color-client);
		border-width: var(--conversation-turn-border-width);
		border-style: solid;
		margin-bottom: $spacing-4;
		padding: $spacing-3 64px $spacing-3 56px;
		position: relative;
		margin-left: $conversation-horizontal-margin;
		width: calc(85% - #{$conversation-horizontal-margin});
		transition: border-color $transition-time;
	}
	&:not(.same-channel-as-last) .sentence-container {
		min-height: 50px; // ensure space for participant icons
	}

	tuning-suggestion-summary & .sentence-container {
		margin-left: 0;
	}

	&.is-highlighted, &.is-adjacent {
		.sentence-container {
			border-color: var(--blue-700);
		}
	}

	&.agent-transcript {
		> div { margin-right: $spacing-5; }

		> .sentence-container {
			border-color: var(--border-color-agent);
			background: var(--bg-color-agent);
			float: right;
			margin-right: $spacing-4;
			margin-left: 0;
		}

		&.hidden-transcript .show-participant {
			left: auto;
			right: calc(100% + #{$show-participant-margin});
			text-align: right;
		}
	}

	&.bot-transcript {
		> div { margin-right: $spacing-5; }

		> .sentence-container {
			border-color: var(--border-color-bot);
			background: var(--bg-color-bot);
			float: right;
			margin-right: $spacing-4;
			margin-left: 0;
		}

		&.hidden-transcript .show-participant {
			left: auto;
			right: calc(100% + #{$show-participant-margin});
			text-align: right;
		}
	}

	.br-sentence-text {
		background-color: var(--topic-highlight-color); // --topic-highlight-color is only set dynamically, and has no value by default.
		font-size: var(--font-size-default);
		line-height: 1.2;
		transition: background-color $transition-time;

		.no-audio & { padding-right: $spacing-3; }
	}

	p {
		margin-bottom: $spacing-2;
		position: relative;
	}

	.timestamp {
		position: absolute;
		right: $spacing-3;
		top: $spacing-4;
	}

	p, div {
		&:empty { display: none}
	}

	.feedback-preview-icon { font-size: var(--font-size-default); }
	.sentence-topics {
		margin-bottom: (-1 * $spacing-2);
		display: block;
	}

	&.selected-sentence .br-sentence-text {
		background-color: var(--action-300)
	}
}

%selected-sentence-border {
	border-left-width: 4px;
	border-left-style: solid;
	border-radius: 2px 0 0 2px;
}

voice-message, chat-message {
	&.is-highlighted {
		.br-sentence-text {
			border-radius: $spacing-2;
			background-color: var(--blue-300);
			color: var(--default-text-color);
		}
	}
}

voice-message {

	.cluster-play-control, .sentence-play-control { display: none; }

	&:not(.hidden-transcript) .sentence-container:hover {
		.q-icon-play { display: block; }
		.speaker-icon, .q-icon-pause { display: none }
	}

	.sentence-play-control {
		display: none;
		position: absolute;
		right: 0;
		top: $spacing-3;
	}

	.audio-playing &.is-highlighted {
		.sentence-play-control {
			&.q-icon-volume { display: block; }
			&.q-icon-play { display: none; }
		}
	}

	.audio-playing &.is-adjacent {
		.cluster-play-control.q-icon-play {
			display: block;
		}

		.speaker-icon {
			display: none;
		}

		.sentence-container:hover .cluster-play-control {
			&.q-icon-play { display: none; }
			&.q-icon-pause { display: block; }
		}
	}

	.no-audio &.is-highlighted {
		.q-icon-volume, .q-icon-play { display: none !important; } // never show playing icon if no audio is available
	}

	.br-sentence-text {
		padding: $spacing-3;
		padding-right: $spacing-6;
		display: block;
	}

	voice-sentence {
		display: block;
		margin-left: #{-1 * $spacing-3};
		margin-right: #{-1 * $spacing-3};
	}

	&.selected-sentence voice-sentence { @extend %selected-sentence-border; }

	&.same-channel-as-last {
		> .sentence-container {
			border-top: 0;
			border-top-left-radius:0;
			border-top-right-radius: 0;
			margin-top:-20px;
		}
	}

	&:not(.same-channel-as-last) .sentence-container { min-height: $spacing-8; }
}

.audit-mode .br-sentence-text:hover span {
	cursor: pointer;
}

.sentence-topics .pill-remove { display: none; }
.audit-mode .sentence-topics .label-bubble .pill-remove { display: inline-block; }

.is-overtalk {
	background: $overtalk-background-color;
	display: flex;
	justify-content: flex-start;

	&.agent-transcript {
		justify-content: flex-end;
	}

	>div {
		margin-left: $spacing-4;
		width: 85%;
	}
}

.conversation-metadata-entry {
	height: $spacing-6;
	color: $white;
	font-style: normal;
	font-weight: bold;
	font-size: var(--font-size-1_125rem);
	letter-spacing: 0.3px;
	margin-left: $spacing-5;
	margin-right: $spacing-5;
}

.silence {
	background: var(--gray-400);
	border-radius: $spacing-2;
	margin-bottom: $spacing-4;

	.spine-update & {
		background: transparent;
		color: var(--gray-600);
		font-weight: normal;
		font-size: var(--font-size-0_875rem);
		margin-bottom: 0;
		margin-top: 0;
	}
}

chat-message {
	&.selected-sentence .sentence-text-container {
		@extend %selected-sentence-border;
		padding-left: $spacing-3;
	}

	&.same-channel-as-last .sentence-icon { display: none; }
}

participant-selector {
	.dropdown-item span {
		max-width: 80%;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

// scorecard icons visibility is controlled by runtime generated classes like `show-scorecard-12345`
.icon-highlight.scorecard-sentence-icon {
	display: none;
}

.enrichment-pill.scorecard-sentence-pill {
	display: none !important;
}



.sentence-icon, call-icon {
	&.q-icon-conversation-customer-circle {
		color: var(--icon-color-client);
	}
	&.q-icon-conversation-agent-circle {
		color: var(--icon-color-agent);
	}
	&.q-icon-robot-circle {
		color: var(--icon-color-bot);
	}
}
.conversation-segment {
	.sentence-container {
		background-color: var(--bg-color-client);
	}
	&.agent-transcript > .sentence-container {
		background-color: var(--bg-color-agent);
	}
	&.bot-transcript > .sentence-container {
		background-color: var(--bg-color-bot);
	}
}
