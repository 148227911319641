
$filters-subheader-margin-bottom: $spacing-3;

dashboard-filters-edit {
	display: block;

	.dropdown-header {
		padding-left: $spacing-3;
		padding-right: $spacing-3;
		margin: 0 (-1 * $popover-horizontal-padding) $filters-subheader-margin-bottom (-1 * $popover-horizontal-padding);

		&:first-child {
			margin-top: (-1 * $popover-vertical-padding);
		}
	}
}

.book-dashboard-filters {
	width:0;
	position: absolute;
	top: $spacing-4;
	left: $spacing-3;
}

.popover.dashboard-filters-pop {
	max-width:600px;
	z-index:1039;
	.option-chicklet {
		position: relative;
		overflow: hidden;
	}
}

.dashboard-filters-wrap {
	margin-bottom:$spacing-3;
	overflow-y: auto;

	// margin and padding to make sure scroll bar (when necessary) doesnt butt against filter dropdowns
	padding-right: $popover-horizontal-padding;
	margin-right: (-1 * $popover-horizontal-padding);

	.filter-clone {

		.filter-clone-panel {
			margin-left: $spacing-4;
			margin-right: $spacing-4;
			padding: $spacing-2 $spacing-3;
			background-color: $white;
			border: 1px solid var(--gray-600);
			position: fixed;
			white-space: nowrap;
		}

		.dashboard-filter-name {
			margin: 0;
			padding: 0;
		}
	}
}

.dashboard-filter-name-wrap {
	@include flex-center;
	@include btn-min-height;
}

.dashboard-filter-name {
	margin-bottom:0;
	@include no-select;
	flex: 1;
}

.dashboard-level-filter {

	.dashboard-filters-edit-mode & {
		padding-left: $spacing-5;
		padding-right: $spacing-2; // prevents button lock/delete shadow from being cut off when clicked
	}

	.br-organization-panel {
		width:100%;

		.button-text { float:left; overflow:hidden; }
	}

	.drag-filter {
		right: calc(100% + #{$spacing-3});
		position: absolute;
	}


	.cx-date-filter-selector {
		white-space:nowrap;

		.calendar-open .dropdown-menu { display: none !important; }
		.chicklet {
			display:block;
			margin-right:0;
		}
		.with-calendar .chicklet {
			margin-right:25px;
		}

		> i {
			float: right;
			line-height: 30px;
		}
	}

	&[ng-drag] {
		transform: none !important;
	}

	.filter-placeholder {
		width: 100%;
		height: 100%;
		background: var(--gray-600);
		border: 1px solid var(--gray-600);
		margin: 0;

		.dashboard-filter-name {
			visibility: hidden;
		}
	}
}

.filter-lock { width:12px; }

.dashboard-filter-alert { margin-top:3px; }

/* compact filters bar */

.filters-bar-compact {

	> span {
		@include flex-center;
		white-space:nowrap;

		> i { margin: 0 $spacing-3 0 0; }
		> span { padding-left: 3px; }
	}

	.br-mobile & > span {
		display:inline;
		white-space:normal;
	}

	.filter-label:before {
		content: ' / ';
	}

	.filter-label:nth-child(1):before {
		content: '';
	}
}

.filter-tooltip-wrap {
	.tooltip-inner { max-width:400px; }
}

.filter-tooltip { margin-bottom:0; padding:$spacing-3 $spacing-4; }


.no-filters-warning {
	padding: $spacing-3;
	border: 1px solid var(--gray-600);
	border-radius: $standard-border-radius;
	line-height: 1;
}

.filters-dropdown-menu {
	max-width: 700px;
}
