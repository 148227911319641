.single-metric-widget text {
	transition: font-size 250ms cubic-bezier(0, 0, 0.2, 1);
}

.metric-period-label, .metric-change-labels {
	font-size: .5em;

	.widget-sm & { font-size: .75em; }
	.widget-xs & { font-size: 1em; }
	.widget-xs.with-long-values:not(.view-ratio-2_39) & { font-size: .75em; }

	.widget-sm.with-extra-long-values &, .widget-xs.with-extra-long-values & { font-size: .65em; }
}
.single-metric-chart {
	.metric-value, .metric-last-value {
		font-size: 2em;
		transform: translate(0, 40px);

		.horizontal.without-long-values.without-change-graph & {
			font-size: 3em;
			transform: translate(0, 80px);
		}
	}
}


.with-change-graph {
	#current-period-group { transform: translate(0, 35px); }
	#previous-period-group { transform: translate(0, 150px); }
}


.single-metric-chart {
	font-family: var(--font-standard);
	font-weight:300;
	font-size: 20px;

	.metric-label { font-weight:300; }
}

.metric-period-label { font-weight: 300; }

.metric-change-line { stroke-width: 0.5px; }


#previous-period-group {
	transform: translate(0, 100px);

	.horizontal & {
		transform: translate(200px, 0);
	}
}

#change-group {
	font-size: 1em;
	transform: translate(0, 70px);

	.horizontal & {
		transform: translate(90px, 90px);
	}

	.horizontal.without-long-values.without-change-graph & {
		font-size: 1.25em;
		transform: translate(0, 125px);
	}
}


.with-extra-long-values:not(.widget-xs.view-ratio-2_39) {
	.metric-value, .metric-last-value { font-size: 1.3em }
}









// this covers horizontal and single value (which is neither classed as horizontal nor vertical)
.single-metric-chart:not(.vertical):not(.multiline-labels) {
	.metric-period-label { transform: translateY(-.5em) }
	&.view-ratio-1_33 .metric-period-label { transform: translateY(0) }
}

.horizontal {
	&.without-change-graph {
		&.with-previous-period #values { transform: translate(100px, 100px) }
		&.multiline-labels:not(.without-long-values) .metric-period-label { transform: translateX(0) }
	}

	.metric-value, .metric-last-value { transform: translate(0, 55px)}


	&.multiline-labels {
		.metric-period-label {
			letter-spacing: 0;
			transform: translateY(-1em);
		}

		#values { transform: translate(110px, 65px); }

		&.without-long-values .step-chart { transform: translate(55px, 215px); }


		.metric-period-label { transform: translate(0, -1em) !important; }

		&.widget-sm {
			&.with-extra-long-values, &.with-long-values, & {
				.metric-period-label { transform: translate(0, -1.5em) !important; }
			}
		}

		&.widget-xs.view-ratio-2_39.without-change-graph {
			.metric-period-label { transform: translate(0, -2em) !important; }
		}

		&.with-extra-long-values {
			.metric-period-label { transform: translateY(-.5em) !important; }
			&:not(.widget-sm,.widget-xs) .metric-period-label { transform: translateY(-1em) !important; }
		}

		&.with-long-values.view-ratio-1_85 .metric-period-label {
			transform: translateY(-1.75em) !important;
		}
	}

	&.widget-xs.without-change-graph {
		.metric-period-label, .metric-change-labels, #change-group { font-size: 1.2em; }
	}

	&.view-ratio-1_33 {
		font-size: var(--font-size-1_675rem);

		#current-period-group { transform: translate(-10px, 0); }
		#change-group { transform: translate(0, 90px) }
		#previous-period-group { transform: translate(240px, 0) }
		.step-chart { transform: translate(40px, 180px); }

		&.with-previous-period.without-change-graph {
			#current-period-group { transform: translate(-10px, 0) }
			#previous-period-group { transform: translate(240px, 0) }
		}

		&.with-long-values {
			.metric-value, .metric-last-value { font-size: 1.6em; }
		}
	}

	&.view-ratio-1_78, &.view-ratio-1_85, &.view-ratio-2_39 {
		font-size: var(--font-size-2rem);

		#current-period-group { transform: translate(45px, 0); }
		#previous-period-group { transform: translate(350px, 0); }
		#change-group { transform: translate(0, 100px); }
		.step-chart { transform: translate(55px, 180px); }
	}

	&.view-ratio-1_85 {
		.without-change-graph.with-previous-period #values { transform: translate(120px, 100px); }
	}

	&.view-ratio-2_39 {
		#current-period-group { transform: translate(80px, 0); }
		#previous-period-group { transform: translate(490px, 0) }
	}
}




.without-change-graph.without-previous-period {
	font-size: var(--font-size-2rem);

	.metric-value { transform: translate(0, 55px); }

	&.widget-sm {
		&.with-long-values .metric-value { font-size: 1.6em; }
		&.view-ratio-1_33.with-extra-long-values .metric-value { font-size: 1em; }
	}

	&.view-ratio-1_33.with-extra-long-values {
			.metric-value { font-size: 1em; }
			&.widget-xs .metric-period-label { font-size: 1em; }
	}

	&:not(.widget-xs) {
		.metric-period-label { transform: translateY(0); }
		&.with-extra-long-values .metric-period-label { transform: translateY(.8em); }
	}
}




// this section can be removed when beta is removed
.single-metric-chart {
	.metric-period-label {
		font-size: $font-size-4;
		font-weight: 300;
	}
	.metric-value { font-size: 3.25rem; }
	.metric-last-value { font-size: var(--font-size-1_375rem); }
	.metric-change-labels { font-size: var(--font-size-0_875rem); }

	.metric-value { transform: translate(0, 50px); }
	.metric-last-value  { transform: translate(0, 24px); }

	#change-group { transform: translate(0, 90px); }
	#previous-period-group { transform: translate(0, 130px); }
}

highcharts-gauge {
	metric-label {
		line-height: 1;
	}
	.gauge-chart {
		metric-label {
			position: relative;
			top: var(--chart-metric-top);
		}
	}

	.main-metric .metric-value {
		font-size: var(--metric-font-size);
		font-weight: bold;
	}

	.comparison-metric .metric-value { font-size: var(--comparison-font-size); }

	.metric-difference {
		font-weight: bold;
		font-size: var(--delta-font-size);
		margin-top: var(--delta-spacing);
		line-height: var(--delta-font-size);
	}

	.chart-with-metric-container {
		width: var(--gauge-diameter);
		height: var(--gauge-height);
	}

	.chart-container {
		position: absolute;
		width: var(--gauge-diameter);
		height: var(--gauge-diameter);
		z-index: 0;
	}

	.comparisons {
		position: relative;
		z-index: 1;
	}

	.comparison-metric {
		width: var(--comparison-width);
		height: var(--comparison-height);
		overflow: hidden;
	}

	.metric-title {
		font-weight: bold;
		font-size: var(--label-font-size);
		margin: 0 auto var(--label-spacing) auto; // required to keep not use the whole width
		display: -webkit-box;
		-webkit-box-orient: vertical;
		text-overflow: ellipsis;
		overflow: hidden;
		-webkit-line-clamp: 1;
	}

	.two-lines-label {
		height: var(--comparison-height-extended);
		.metric-title {
			-webkit-line-clamp: 2;
		}
	}

	.gauge-container {
		padding: var(--container-margin);
	}

	.layout-horizontal {
		&.gauge-container {
			flex-direction: row;
		}
		.comparisons {
			flex-direction: row;
			align-items: flex-end;
		}
		.comparison-metric {
			margin-left: var(--horizontal-spacing);
		}
	}

	.layout-two-columns {
		&.gauge-container {
			flex-direction: row;
		}
		.comparisons {
			flex-direction: column;
		}
		.comparison-metric {
			margin-left: var(--horizontal-spacing);
		}
		.comparison-metric:not(:first-child) {
			margin-top: var(--vertical-spacing);
		}
	}

	.layout-two-lines {
		&.gauge-container {
			flex-direction: column;
		}
		.comparisons {
			flex-direction: row;
			align-items: flex-end;
		}
		.comparison-metric {
			margin-top: var(--vertical-spacing);
		}
		.comparison-metric:not(:first-child) {
			margin-left: var(--horizontal-spacing);
		}
	}

	.layout-vertical {
		&.gauge-container {
			flex-direction: column;
		}
		.comparisons {
			flex-direction: column;
		}
		.comparison-metric {
			margin-top: var(--vertical-spacing);
		}
	}
}
