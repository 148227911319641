$active-item-color : getColor(gray, 400);

.document-preview-modal-window {
	overflow: hidden;
	overflow-y: hidden;

	.modal-dialog{
		height: calc(100% - 50px);
		width: calc(100% - 25px);

		.modal-content {
			height:100%;
			overflow-x: auto;
			overflow-y: hidden;
		}
	}
}

.dex-left-pane {
	float: left;
	overflow: auto;
}


.dex-center-pane {
	.panel.panel-default {
		overflow-y: initial;
		float: left;
		width: 99%;
	}
}


.dex-list-group .list-group-item {
	overflow: hidden;

	&:first-child { border-radius:0;}
}

.dex-models-list-group {
	padding-left: 0;
	margin-bottom: 20px;
}

$twitter-container-border-color: getColor(gray, 600);
$twitter-container-padding: $spacing-4;

.twitter-container {
	border-top: solid $twitter-container-border-color 1px;
	padding-top: $twitter-container-padding;

	.twitter-display-name {
		font-size: var(--font-size-1_125rem) ;
		font-weight: bold;
		margin-right: $spacing-3;
		margin-left: $spacing-3;
	}

	.twitter-logo {
		font-size: var(--font-size-1_375rem);
	}

	.twitter-creator {
		margin-left: $spacing-3;
		font-size: var(--font-size-0_875rem);
		color: var(--gray-600);
	}

	.twitter-comment {
		overflow: hidden;
		margin-bottom: $spacing-4;
		font-size: var(--font-size-1_125rem);
		font-weight: 600;
	}

	.twitter-timestamp, .twitter-metadata {
		font-size: var(--font-size-0_875rem);
		color: var(--gray-600);
	}

	.twitter-metadata span {
		font-size: var(--font-size-0_875rem);
		color: var(--gray-900);
	}

	.twitter-media img {
		max-width: 100%;
		height: auto;
	}

	.twitter-bottom {
		border-bottom: 1px solid $twitter-container-border-color;
		padding-bottom: $twitter-container-padding;
	}
}

/* engager case creation modal */
.document-preview-group {
	border-bottom: var(--gray-200) 2px solid;
}

.document-preview-group-panel {
	.panel-body {
		max-height: 300px;
		overflow-y: auto;
	}
}

/* scorecards in doc view */
.scorecards-panel {
	tr {
		line-height: 2;
		border-bottom: var(--gray-200) 2px solid;
	}
	.scorecard-rule-column {
		word-break: break-word;
	}
	.scorecard-warning-column {
		width: 40px;
	}
	.scorecard-result-column {
		width: 120px;
	}
	.scorecard-result-column-rebut {
		width: 160px;
	}
	.scorecard-result-icon {
		background-color: var(--white);
		border-radius: 8px;
		padding: 1px 3px 1px 3px;
	}
	.scorecard-rebutted:before {
		content: '*';
		padding-right: 5px;
	}
}


/* rebrand */

.document-preview-container {
	.panel { border:0; box-shadow:none; border-radius:0; display:flex; flex-direction: column;}
	.panel-heading {
		background: var(--gray-900);
		color: var(--white);
		font-size: var(--font-size-1_125rem);
		border-radius:0;
		border:0;
		h3 {
			font-size: var(--font-size-1_125rem);
			letter-spacing: 0.5px;
		}
	}
}

/* dates */


.dex-model-node { border:0; padding:5px 15px; }




/* audio transcript */
.audio-transcript {
	max-height:calc(100% - 50px);
	overflow:auto;

	.list-group-item.font-bold { background:$active-item-color; }
}
