.alert-template-display-settings {
    display: grid;
}

.alert-template-case-mapping, .alert-template-mention-mapping {
    .left-aligner {
        width: 13.2% !important;
    }
    .attribute-selector {
        width: 77% !important;
    }
}

.engagor-topic-select a {
	min-height: 30px;
}
