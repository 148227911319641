.recolor-manage-dialog {
	.modal-body { max-height:75vh; overflow:auto; }

	.row + .row { border-top: 1px solid var(--gray-400); }

	.input-group {
		max-width: 160px;

		&, > input { min-width: 0; }
	}

	.colored-area { min-width: $spacing-6; }
}
