$asset-selector-base-offset: 42px;

.mobile-app-settings {

    .inline-help {
        margin-left: $spacing-3;
    }

    .modal-overlay {
        position: absolute;
        top: 60px;
        right: -8px;
        bottom: 16px;
        left: -8px;
        background-color: rgba(0, 0, 0, .5);
        z-index: 1000;
    }

    .alert {
        width: 100%;

        .jump-to-errors {
            color: inherit;
            text-decoration: underline;
            margin-left: 0.5rem;
            font-weight: bold;
        }
    }

    .mobile-project-selector {
        select.error {
            border-color: var(--danger-600);
        }
    }

    .app-name.error {
        border-color: var(--danger-600);
    }

    .tab-alert-icon {
        color: var(--danger-600);
    }

    .mobile-app-configuration {
        .app-name, .project-selection-select, .organization-select, .studio-filter-select {
            width: 100%;
        }
    }

    .error-message {
        padding-top: 4px;
        color: var(--danger-700);
        font-size: var(--font-size-0_875rem);
    }

    .error-popup { font-size: var(--font-size-1_25rem); }

    .screen-config {
        width: 65%;
    }

    .simple-grouping-mobile-screen-configuration {
        multi-calculation-selector {
            calculation-selector.no-asset {
                width: calc(100% - #{$asset-selector-base-offset + 16});
            }

            .calculations-table {
                border-collapse: separate;
                border-spacing: 3px;
            }

            .calculation-toolbar {
                width: 40px;
            }

        }

        grouping-selector, calculation-selector {
            width: 100%;

            select-from-hierarchy {
                width: 100%
            }

            &.error {
                .dropdown-toggle {
                    border-color: var(--danger-600);
                }
            }

            &.wide-selector {
                .grouping-selector, .calculation-selector {
                    width: calc(100% - #{$asset-selector-base-offset});
                }

                &.no-asset {
                    .grouping-selector, .calculation-selector {
                        width: calc(100% - #{$asset-selector-base-offset + 20});
                    }
                }
            }

        }

    }

    .mobile-app-sharing-control {

        .recipients-table {
            width: 100%;

            tbody {
                > tr {
                    border-top: 1px solid $grid-border-color;
                    > td {
                        padding: $standard-grid-padding;
                    }
                    > td.report-recipient-action {
                        text-align: center;
                    }
                }
                > tr.new-report-recipient {
                    vertical-align: bottom;
                }
            }

            .remove-recipient-icon {
                width: 30px;
            }
        }

        .external-sharing-prompt {
            margin-left: 60px;
            margin-right: 20px;

            .external-sharing-prompt-highlight {
                text-decoration: underline;
                font-weight: bold;
            }
        }

    }

    .mobile-navigation {
        .mobile-navigation-tabset {
            width: 100%;
        }

        .mobile-navigation-tab {
            width: 100%;
        }

        .add-screen-button {
            float: right;
        }
    }

}

.document-displayed-items-dialog {

    .selection-deselection-list {

		max-width: 50%;

		@media screen and #{$media-query-mobile-and-small} {
			max-width: none;
		}

        .searchable-hierarchy-list {
            margin-left: $spacing-3;
            margin-right: $spacing-3;
			border: 1px solid var(--gray-600);
			border-radius: $standard-border-radius;
        }


        .selected-item-description {
			background-color: var(--gray-200);
			padding: $spacing-3;
			flex-grow: 0.4;
			text-align: center;
			font-size: var(--font-size-default);
        }
    }
}

.mobile-app-customizations {

    .available-date-ranges, .available-saved-filters {
        width: 100%;

        tr {
            height: 30px;
            border-bottom: 1px solid $table-border-color;
            background-color: var(--white);
        }

        .date-range-default-state, .remove-date-range {
            text-align: center;
        }

        .date-range-use-as-default {
            color: var(--default-primary-color);
        }

        .remove-date-range, .remove-saved-filter {
            width: 30px;
        }
    }
}
