// text formatting

@mixin strike-through { text-decoration: line-through; }
@mixin no-wrap {white-space: nowrap;}
@mixin break-word {
	word-break: break-word;
	-ms-word-wrap: break-word;
}


@mixin text-ellipsis {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.strike-through { @include strike-through }
.wrap, .text-wrap { white-space: initial; }
.no-wrap, .nowrap { @include no-wrap;}
.break-word { @include break-word; }


.underline { text-decoration:underline; }
.italic { font-style:italic; }
.text-ellipsis { @include text-ellipsis; }
.lh-1 { line-height: 1 !important; }
.lh-default { line-height: $default-line-height !important; }

.multiline-truncate {
	display: -webkit-box;
	overflow:hidden;
	text-overflow:ellipsis;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	max-height: 4.5em; // fallback for other browsers that don't support line clamp;
}


.link-disabled {
	pointer-events: none;
	cursor: default;
	color: var(--gray-400) !important;
}
