
html.pdf-export {
	// hide scrollbars, so charts are scaled properly in export
	overflow-y: hidden;

	body {
		overflow-y: hidden;
	}

	.br-widget-box.CONTENT .br-widget-content {
		height: 100% !important;
	}

	.view-text {
		overflow: hidden;
	}

}

.chrome-pdf-header, .chrome-pdf-logo {
	display: none
}

.pdf-header {
	padding: 15px 0;
	border-bottom: solid var(--gray-600) 1px;
	display: none;
	background-color: $white;
}

.pdf-header h1 {
	font-size: var(--font-size-1_375rem);
	line-height: 24px;
}

.pdf-header label {
	margin-bottom: 2px;
	color: var(--gray-900);
	font-weight: normal;
}

.pdf-export .br-home-gridster {
	.gridster {
		margin-top: -10px; // to make top and bottom margins equal on paged pdf
		height: 100% !important; // override gridster height with calculated height
	}
}

.pdf-export, .pdf-mode {
	#main-dashboard-controller, .br-global-view { overflow: visible; }
}

.pdf-export .br-global-view {
	overflow: visible;
	display: block;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}
