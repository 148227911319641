checkbox-button {
	display: inline-block;

	> .btn {
		background: none;
		border-color: transparent;

		> [class*="q-icon"] {
			margin-right: $spacing-3;
			width:15px;
		}

		&[disabled] {
			border-color: transparent;

			> [class*="q-icon"] { color: inherit !important; }
		}

		&.btn-selected {
			background:none;
			font-weight:normal;
		}
	}
}

checkbox-button.cx-permission-checkbox>.btn {
	font-size: var(--font-size-default);
}
