.br-widget-dialog {
	.modal-dialog {	width: 96%; }

	.br-widget-content{ top:0; }
	&.CONTENT { top: 10%;}
}

.br-settings-text, .br-settings-label {
	.modal-dialog {
		margin-top:80px;
	}
}

.br-widget-settings {
	overflow: auto;
}

.group-selector, .calculation-selector {
	/* move grip hidden by default */
	cb-drag-handle { display:none; }
}

.reorderable-selector {
	padding-left: 1.5rem;
	position: relative;

	cb-drag-handle { display:block; left:0; position: absolute; }
	&.dragging .btn-secondary { display: none; }
}


.date-input.fix-margin { margin-bottom:-20px; }

.widget-settings-date-button {
	top: -30px;
	left: calc(100% - 53px);
	position: relative;
}

.form-control.widget-settings-date-input {
	width: 100%;
}

.form-control.date-input {
	float:left;
	margin-right:10px;
	width: calc(100% - 25px);
}

.date-input+.calendar-side {vertical-align:middle;}

.colorpicker input[name=colorpicker-input]{
	width: 120px;
	font-size: var(--font-size-0_875rem);
}

.br-filter-arrows { padding-top:100px;}
.br-filter-checkbox { margin-right:10px !important;}

ul>li>a.br-grey-out, ul>li>a.br-grey-out:hover {
	cursor: default;
	background-color: transparent;
	opacity: .35;
}

.br-multi-item .br-item-reorder, .br-multi-item .br-item-non-reorder {
	display: none
}

.br-multi-item:hover .br-item-reorder {
	display: block
}

/* new basic widget */
.settings-ordered-by { width:300px; }
.widget-definition .searchable-hierarchy-list li {line-height:30px; margin-top:2px;}

.basic-report-model-search { height:30px; padding:5px;}

.has-axis select-from-hierarchy, select-from-hierarchy.has-direction { float:left; width:50%;}
.has-axis select-from-hierarchy .dropdown-menu { width:180%;}

select-from-hierarchy.has-direction { width:50%; float:left; margin-right:15px;}
select-from-hierarchy.has-direction + select { margin-top:22px; }

.hierarchy-folder > .hierarchy-item { white-space:nowrap;}

.br-color-dropdown {
	.dropdown-menu-hierarchy.dropdown-menu {
		min-width: 250px;
		max-width: 300px !important;
	}

	&.dropup .dropdown-menu {
		bottom: 100%;
	}

}

.filter-hierarchy-dropdown {
	.dropdown-menu-hierarchy.dropdown-menu {
		min-width: 250px;
	}
}

// the maximum number of icons (plus, minus, cog) shown next to groupings in a widget type
// need to provide space to account for these icons
$max-icons : (
  cb-an-heatmap-props : 3,
  cb-analytic-cloud: 1,
  cb-analytic-map-props: 1,
  cb-analytic-scatter: 1,
  cb-an-pie-props: 2,
  cb-an-network-props: 1
);
%inline-block { display:inline-block; }


// spacing for widget config cogs and group add/remove buttons
@each $widget, $max-config-icons in $max-icons {
  .#{$widget} .attribute-selector { @extend %inline-block; width: calc(100% - #{($max-config-icons * $icon-button-width)}) }
}

.cb-analytic-dual .secondary-axis .attribute-selector { width: calc(100% - #{$icon-button-width}); }

.br-widget-total-real-preview, .br-dual-widget-total-demo {
	color: var(--gray-900);
	position: absolute;
	right: 8px;
	bottom: 16px;
	align-items: center;
	outline: 4px solid var(--white);
}

.br-widget-total-demo {
	color: var(--gray-900);
	position: absolute;
	right: 27px;
	top: 320px;

	[highcharts-bubble] + &, [d3-cloud] + &, [highcharts-pie] + &, [highcharts-network] + &, [highcharts-treemap] + & {
		bottom: 10px;
		right: 20px;
		top: auto;
	}

	&.demo-table, &.demo-heatmap {
		bottom: 0;
		right: 0;
		top: auto;
		position: relative;
		float: right;
	}

	&.demo-map {
		top: auto;
	}

	&.demo-metric {
		top: auto;
	}

}

.cb-analytic-dual, .cb-analytic-scatter, .cb-analytic-heatmap {
	label { display: block;	}
}

.cb-analytic-dual, .cb-analytic-heatmap {
	.sort-panel select-from-hierarchy { width: 100%; }
	select-from-hierarchy.has-direction + select { margin-top: 0; }
}

.chart-demo {
	margin-top: 8px;

	.tree-container {
		position: absolute;
	}
}

#chart-demo, #real-data-preview {
	&.h-100-percent { overflow-y: auto; }
	metric-widget { width: 100%; height: 100%; }
}

.cb-analytic-settings, .cb-attr-report {
	widget-content,
	.chart-demo,
	.empty-preview-container {
		border: 1px solid var(--gray-200);
		border-radius: var(--widget-border-radius);;
	}

	period-selector {
		max-width: 400px;
	}
	.period-switcher {
		width: auto;
	}

	.dropdown.option-chicklet {
		margin: 0;
	}
}

.br-toggled-selector {
	>input { position:absolute; }
	>label {
		position:absolute;
		left: 30px;
	}

	select-from-hierarchy label { margin-left: 15px; }
}


period-selector.fill-width {
	.cx-date-filter-selector {
		display: flex;
		align-items: center;
	}

	search-list {
		display: flex;
		flex: 0 1 100%;
		min-width: 0;
	}

	.cx-date-filter-selector .q-icon-calendar {
		margin-right: $icon-distance-from-text;
		padding: 8px;
	}

	.option-chicklet {
		width: 100%;
	}

}

.cx-table-conversions {
	padding: 3px;
	margin-right: 30px;
}

.cx-date-filter-selector .w-fit-parent {
	max-width: calc(100% - 36px);
}

plot-bands-configurer {
	display: block;

	.plot-bands-grid {
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
	}
	.plot-band-item {
		display: flex;
		flex-basis: 33%;
		justify-content: center;
		flex-direction: column;
	}

	.band-text-color, .band-background-color {
		display: inline-block;
		height: $input-height;
		width: $input-height;
		margin-left: $spacing-2;
	}

	.font-icon {
		display: flex;
		flex-flow: column;
		justify-content: center;
		text-align: center;
		height: 100%;
	}

	plot-band-item {
		margin-bottom: 10px;
	}

	.plot-dividers {
		padding:0;
	}
}

.separator-right {
	border-right: 1px solid var(--gray-600);
	height: 100%;
}

.plot-bands-top-hidden {
	.quadrantTopLeft, .quadrantTopRight {
		visibility: hidden !important;
	}
}
.plot-bands-bottom-hidden {
	.quadrantBottomLeft, .quadrantBottomRight {
		visibility: hidden !important;
	}
}

.threshold-selection {
	input[type="number"] {
		display: flex;
		@include flex-fill;
		min-width: 60px;
		max-width: 120px;
	}
}

time-reference-line-builder {
	.cx-date-range-selector {
		display: flex;
	}
}

item-list {
	height: auto !important;
}


report-visualization-selector {
	z-index: 2;	// required to layer dropdown above table preview

	.btn.btn-icon {
		justify-content: center;
		align-items: center;
		display: flex;
		line-height: 1;
	}

	[class*=q-icon]:before { font-size: var(--font-size-1_125rem); }
}

.numeric-with-suffix {
	position: relative;
	.input-suffix {
		position: absolute;
		top: .5em;
	}
}

$drag-grip-width: 28px; // 16 width + 4 padding + 8 margin
$selector-button-width: 40px; // 32px button + 8px margin

.new-settings-style {
	.settings-panel {
		max-width: 450px;
	}

	@for $index from 1 through 3 {
		.with-#{$index}-buttons {
			.dropdown-selector {
				max-width: calc(100% - #{$selector-button-width * $index});
				&.has-drag-grip {
					max-width: calc(100% - #{$selector-button-width * $index + $drag-grip-width});
				}
			}
		}
	}

	.panel-body alert.panel-bottom {
		margin: 16px -17px -16px -17px; // to fill the width inside panel's paddings
		.alert {
			border-radius: 0 0 2px 2px;
		}
	}
}
