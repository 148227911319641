$editor-vertical-space: 244px;

.ProseMirror, .view-text.newTextFormat, .view-label.newTextFormat {

	blockquote {
		padding: 10px 20px;
		margin: 0 0 20px;
		border-left: 5px solid var(--black-50-percent);
	}

	table {
		border-collapse: collapse;
		table-layout: fixed;
		overflow: hidden;
	}

	img {
		cursor: default;
	}

}

.view-text.newTextFormat .text-wrapper {
	&.rotate-down {
		overflow: hidden;
		margin-left: auto;
		&.valign-bottom {
			margin-left: unset;
		}
		.text-container {
			transform: rotate(90deg) translate(0, -100%);
			transform-origin: top left;
		}
	}
	&.rotate-up {
		overflow: hidden;
		&.valign-bottom {
			margin-left: auto;
		}
		.text-container {
			transform: rotate(-90deg) translate(-100%, 0);
			transform-origin: top left;
		}
	}
	&.valign-middle {
		&.rotate-up, &.rotate-down {
			margin-left: auto;
			margin-right: auto;
		}
	}

	&:not(.rotate-up):not(.rotate-down) {
		height: 100%;
		display: flex;
		.text-container {
			width: 100%;
		}
		&.valign-middle .text-container {
			margin-top: auto;
			margin-bottom: auto;
		}
		&.valign-bottom .text-container {
			margin-top: auto;
		}
	}

	p:empty:after {
		content: '\a0';
	}
}

#text-editor .text-container {
	&.rotate-down {
		overflow: hidden;
		margin-left: auto;
		height: $editor-vertical-space;
		&.valign-bottom {
			margin-left: unset;
		}
		.ProseMirror {
			transform: rotate(90deg) translate(0, -100%);
			transform-origin: top left;
			width: $editor-vertical-space;
		}
	}
	&.rotate-up {
		overflow: hidden;
		height: $editor-vertical-space;
		&.valign-bottom {
			margin-left: auto;
		}
		.ProseMirror {
			transform: rotate(-90deg) translate(-100%, 0);
			transform-origin: top left;
			width: $editor-vertical-space;
		}
	}
	&.valign-middle {
		&.rotate-up, &.rotate-down {
			margin-left: auto;
			margin-right: auto;
		}
	}

	&:not(.rotate-up):not(.rotate-down) {
		height: 100%;
		display: flex;
		&.valign-middle .ProseMirror {
			margin-top: auto;
			margin-bottom: auto;
		}
		&.valign-bottom .ProseMirror {
			margin-top: auto;
		}
	}
}

.ProseMirror, .view-text {
	pre, pre code, blockquote p {
		font-size:1.06vw;
	}
}

.mobile-layout {
	.ProseMirror, .view-text {
		pre, pre code, blockquote p {
			font-size:1.77vw;
		}
	}
}

.ProseMirror {
	h6 {font-size:0.73vw;}
	h5 {font-size:0.86vw;}
	h4 {font-size:1.06vw;}
	h3 {font-size:1.26vw;}
	h2 {font-size:1.6vw;}
	h1 {font-size:2.13vw;}
}

.mobile-layout .ProseMirror {
	h6 {font-size:1.22vw;}
	h5 {font-size:1.44vw;}
	h4 {font-size:1.77vw;}
	h3 {font-size:2.11vw;}
	h2 {font-size:2.67vw;}
	h1 {font-size:3.56vw;}
}

cb-text-editor {
	.text-wrapper, #html {
		height: 268px;
		min-height: 268px;
		width: 100%;
		overflow-y: auto;
		border: 1px solid $input-inactive-border-color;
		padding: 7px 8px;
	}

	.ProseMirror {
		width: 100%;
		ul {
			list-style-type: initial;
		}

		hr {
			padding-bottom: 5px;
		}
	}

	#text-editor #toolbar {
		margin-bottom: 8px;
		.btn-group {
			margin-bottom: 8px;
		}
		button.dropdown-toggle {
			&[name=hierarchyEnrichments], &[name=rotation], &[name=valign] {
				border-radius: var(--button-border-radius);
			}
		}
	}
}

.br-text-settings cb-text-editor textarea#html {
	background-color: $white;
	color: var(--gray-900);
	.dashboard-dark-mode & {
		background-color: var(--gray-1000);
		color: $white;
	}
}

// simple dropdown supports classes but not css
.font-sans-serif {font-family: Arial, Helvetica, sans-serif}
.font-serif {font-family: times new roman, serif}
.font-wide {font-family: arial black, sans-serif}
.font-narrow {font-family: arial narrow, sans-serif}
.font-courier-new {font-family: courier new, monospace}
.font-garamond {font-family: garamond, serif}
.font-georgia {font-family: georgia, serif}
.font-gahoma {font-family: tahoma, sans-serif}
.font-grebuchet-ms {font-family: trebuchet ms, sans-serif}
.font-helvetica {font-family: '72', Helvetica Neue, Helvetica, Arial, sans-serif}
.font-verdana {font-family: verdana, sans-serif}
.font-proxima-nova {font-family: proxima_nova_rgregular}

.font-small {font-size: small}
.font-medium {font-size: medium}
.font-large {font-size: large}
.font-x-large {font-size: x-large}
.font-xx-large {font-size: xx-large}
