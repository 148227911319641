@use "sass:math";

$drill-menu-wrapper-width: 800px;
$drill-menu-height: 350px;
$drill-menu-header-height: 49px;

$drill-column-count: 4;

$drill-column-width: $drill-menu-wrapper-width * math.div(1, $drill-column-count);

$drill-child-indicator-width: 5px;

$drill-root-width: 200px;

$drill-menu-item-height: 32px;





.drill-menu {
	width: $drill-root-width;

	.dropdown-submenu>a:after {
		left: auto;
		right: 8px;
	}

	.left {
		.drill-submenu, .recolor-submenu {
			right: $drill-root-width + 10px;
			left: auto;
		}
	}

}

.recolor-submenu {
	left: $drill-root-width + 10px;
	position: absolute;
	background: $white;
	margin-top: -$drill-menu-item-height;
	padding:$spacing-3;
}

.scrollable-list {
	overflow-y: auto;
}

.dropdown-bounded {
	width: $drill-menu-wrapper-width;
	height: $drill-menu-height + $drill-menu-header-height;
	background:$white;
	@include box-shadow($standard-box-shadow);
	border:$standard-border;
	overflow: hidden;

	#dsh-context-menu {
		display:block;
		border-left:0;
		top:$drill-menu-header-height;
	}

	.dropdown-menu {
		box-shadow:none;
		border:0;
		border-left:$standard-border;
		border-radius: 0;
		position:absolute;
		top:5px;
		width:auto;
		> div { max-height: 330px; }

		a {
			padding-right: ($drill-child-indicator-width + $spacing-3 + $spacing-3);

			&:after { left: calc(100% - #{($spacing-3 + $drill-child-indicator-width)}); }
		}
	}
}


.drill-submenu {
	width: 100%;
	height: $drill-menu-height;
	align-self: baseline;
}

.geography-menu menu-option-submenu {
	height: auto;

	.menu-level-1, .menu-level-2 {
		width: 100% !important;
	}
}

.geography-menu, scorecard-select-menu {
	.sub-menu-item {
		padding-left: 0 !important;
		width: 100% !important;
	}

	.option, .sub-option {
		&.checked::before, &.q-icon-circle::before, &.q-icon-check::before {
			display: inline-block;
			width: 16px;
		}

		&.q-icon-check::before {
			color: var(--default-primary-color);
		}

		&.checked::before {
			content: '✓';
		}
	}
}

scorecard-select-menu {
	.scorecard-context-menu-header {
		font-size: var(--font-size-0_875rem);
		font-weight: bold;
		letter-spacing: 0.28px;
		padding-left: 10px;
	}
	.scorecard-context-menu-subheader {
		font-size: var(--font-size-0_75rem);
		font-weight: bold;
		text-transform: uppercase;
		letter-spacing: 0.75px;
		line-height: 16px;
		padding-left: 10px;
		margin-bottom: 0px;
	}
	.option.metric-identifier {
		background: var(--gray-200);
		border-radius: $spacing-2;
		height: 100%;
		margin: $spacing-2;
		padding: $spacing-2 $spacing-3 $spacing-2 $spacing-3;
		position: relative;
	}
	.dropdown {
		box-shadow: $dropdown-box-shadow;
		ul {
			padding-inline-start: 0px;
			margin-bottom: 0px;
		}
	}
}

.drill-submenu, .geography-menu menu-option-submenu {
	left: $drill-root-width + 10px;

	border:0;
	overflow: visible;

	&.dropup {
		// go upwards if no space below
		bottom: 0;
		height: 100%;

		#dsh-context-menu {
			background: none;
		}
	}
	#dsh-context-menu {
		display:block;
		border-left:0;
		top: 0;
	}

	> #dsh-context-menu {
		border: 0;
		box-shadow:  none;
	}

	.dropdown-menu {
		position:absolute;
		top: 0;
		margin-top: 0;
		width:auto;
		> div { max-height: 330px; }

	}
	.dropdown-submenu>.dropdown-menu {
		margin-top: -9px;
	}
}

.dropdown-bounded, .drill-submenu, .geography-menu menu-option-submenu {
	.scrollable-list {
		overflow: auto;
	}

	.menu-level-0 {
		overflow:auto;
		max-height:calc(100% + 8px);
	}

	.menu-level-0,
	.menu-level-1,
	.menu-level-2,
	.menu-level-3 {
		width: $drill-column-width;
	}

	.menu-level-1, .menu-level-2, .menu-level-3 {
		margin-top:0;
		top:0;
	}

	.dropdown-submenu.open-menu {
		position:static;
	}

	.searchable-hierarchy-list {
		height: inherit;
		overflow: visible;
	}
}

.audit-suggestion-menu {
	.menu-level-1, .menu-level-2 {
		.scrollable-list {
			overflow: inherit;
		}
	}

	.menu-level-2 {
		.sub-menu-item {
			width: 200px;
		}
	}

	.sub-option.q-icon-trash:before, .sub-option.q-icon-add:before {
		padding-right: $spacing-3;
	}

	.searchable-hierarchy-list {
		height: inherit;
		max-height: 330px;
		overflow-y: visible;
	}
}

.menu-expansion-icon { width: 1.675rem; }

.context-menu-header {
	background:var(--gray-200);
	border-bottom:$standard-border;
	padding:5px;
	text-align:center;
	width:100%;
}

.drill-btn-delimiter {
	border-left-width: 1px;
	border-left-style: solid;
	height: 70%;
}
