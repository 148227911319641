.selected-group-box {
	margin-top:0;
}

.user-disabled { font-style:italic; color:var(--gray-900)}
.user-disabled .br-user-disable-user, .user-disabled .br-organization-panel { display:none;}

// otherwise width is not constant and it affects system permissions checkboxes positions
.user-create-wizard .modal-dialog {
	width: 700px;
}

.modal-body {
	.group-search-result, .selected-group-box { overflow-y: scroll; }
	.group-search-result { height:250px;	}
	.selected-group-box { height:300px; }

	.user-group-selector {
		display: inline-block;
		width: 95%;
	}
}

.br-license-confirmation ul {
	height: 200px;
	overflow-y: scroll;
}

.br-custom-field {
	cursor: pointer;
	font-weight: bold;
}

.user-column {
	white-space: nowrap;
	padding: $spacing-4 $spacing-3;
	.user-column-clear-show {
		margin-right: 10px;
	}
}

.br-user-filter {
	&input {
		width: 210px;
	}

	.dropdown-toggle {
		height: $input-height;
		min-width: 200px;
	}
}

.br-user-actions [type="checkbox"], .br-group-actions [type="checkbox"] {
	margin-right: $horizontal-spacing-between-input-and-label;
	margin-top: 0;
}

.br-user-firstName, .br-user-lastName, .br-user-email { max-width:300px; overflow:hidden; text-overflow: ellipsis; }


.br-user-date-filter {
	table tr:hover {
		background-color: inherit;
	}
}

.br-user-accessExpiration {
	width: 165px;
}

.br-user-expiration-menu {
	width: 50px;
}

#btn-export-user.btn-no-pad-left{
	margin-left:0;
}

.account-in-cp-result{
	max-height:140px;
	overflow-y: scroll;
	overflow-x: hidden;
}

.br-cp-accounts {
	max-height: 600px;
    overflow: auto;
}


.br-cp-accounts table>div.cg-busy {
	margin-top: -15px;
}

.br-error-container>div.cg-busy {
	margin-top: -20px;
}

.add-user-dialog {
	.help-block { display:none;}
	.has-error .help-block { display:block; }
	.help-block.warning-block { display:block; }
}

.br-alert-assignee {
	white-space: nowrap;
}

.modal-body .br-alert-select-table {
	overflow: visible;
	height: auto;
}

.table-split { border:0 !important; }

@media ( max-width : 992px) {
	.modal-body .br-alert-select-table { overflow: auto;}
}

.cx-new-owner-selection {
	max-height: 500px;
	overflow-y: auto;
	margin-top: 10px;
}

.cx-project-access {
	max-height: 250px;
	overflow-y: scroll;
	margin-top: 10px;
}

.cx-project-access-all-block {
	padding-top: 5px;
}

.bulk-remove-message {
	overflow-y:auto;
	max-height:300px;
	padding-bottom:10px;
}

.no-horiz-resize .tags {
	resize: vertical;
}

.master-account-name {
	display: block;
	padding-bottom: 10px;
	font-weight: bold;
	padding-left: 8px;
}

.transparent:not(.open) {
	opacity: 0;
}

user-column:hover .transparent {
	opacity: 1;
}


.slick-header-button {
	float: left;
	margin-right: 15px;
}


.license-count {
	display: flex;
	flex-direction: column;
}

.license-details { transition: height .3s; }

.bulk-upload-errors-table {
	max-height: 600px;
	overflow-y: scroll;
	table-layout: fixed;
}
.disable-tag-removal .remove-button {
	display: none;
}

.cx-user-transfer-table {
	max-height: 500px;
}



// no longer needed once impersonate modal is converted to angular
.impersonate-modal {
	z-index: 1052 !important;

	+ .modal-backdrop { z-index: 1051 !important; }
}



.permissions-section {
	justify-content: space-between;
}

// if button is disabled but has a title, allow pointer events so we can see the tooltip
.allow-tooltips button[disabled][title] { pointer-events: auto;}

group-edit-modal {
	.permissions-section { justify-content: initial; }
	.permission-group { break-inside: avoid-column; }

	@media (min-width: 1100px) {
		--col-gap: 24px;
		.permissions-section { column-gap: var(--col-gap); }
		.permission-column:first-child { column-count: 2 }
		.permission-column { column-gap: var(--col-gap); }
	}


	@media (min-width: 1400px) {
		--col-gap: 36px;
		.permission-column { column-count: 2 }
	}
}
