
.br-dashboard-empty-image {
	display:none;
	font-size: var(--font-size-1_25rem);
	font-weight: 600;
	height:111px;
	line-height: 111px;
	text-align: center;
	vertical-align: middle;
	width: 150px;
}

dashboard-preview-image img {
	display: block;
	&[empty=true] {
		display: none;

		+.br-dashboard-empty-image {
			display: block;
		}
	}

}

.dashboard-tooltip {
	position: absolute;
	z-index: 999;
	transition: visibility $css-animation-duration ease-out, opacity $css-animation-duration ease-out;
}

.br-dash-tooltip {
	position: absolute;
	bottom: 12px; // arrow
	$padding: $spacing-4;
	background: var(--white);
	padding: $padding;
	z-index: 999;
	transition: visibility $css-animation-duration ease-out, opacity $css-animation-duration ease-out;
	border:2px solid transparent;
	border-radius: $standard-border-radius;
	box-shadow: 0 2px 8px 2px $box-shadow-color;

	.e2e-testing & { transition: none; }

	.br-dash-preview {
		width: 150px;
	}
	.dashboard-preview {
		display: flex;
		flex-direction: column;
		padding: 0;
		width:100%;

		a { width: 100%; }

		img { height:auto; width:100%; }
	}

	.br-arrow {
		&, &:before, &:after {
			position: absolute;
			width: 0;
			content: " ";
			border: 12px solid transparent;
		}

		bottom: -22px;
		left: calc(50% - 12px);

		&:before {
			border-top-color:$white;
			bottom:-16px;
			left:-12px;
			z-index: 2;
		}

		&:after {
			border-top-color:$box-shadow-color;
			bottom:-17px;
			left:-12px;
			z-index:1;
		}
	}


	.br-dashboard-empty-image {
		$height: 86px;

		height: $height;
		line-height: $height;
		width:100%;
	}

	&.large .br-dashboard-empty-image {
		$height: 200px;

		height: $height;
		line-height: $height;
		width:100%;
	}
}

.dashboard-preview a {
	max-width:200px;
	overflow: hidden;

	img {
		height: 111px;
		width: 150px;
	}
}




.dashboard-preview-tile {
	overflow:hidden;
	border-radius:5px;
	border:1px solid var(--gray-600);
	height: 111px;
	width: 150px;
	white-space: normal;
}




.br-list-box {
	padding: $spacing-5;
	min-height: 100%;
	background: var(--custom-bg-secondary, var(--white));
}


.sub-tab-content {
	padding: 0;
	margin: 0;

	.tab-content {
		margin-left: 20px;
		margin-bottom: 20px;
		margin-right: 20px;
	}

	.saml-tab-content {
		padding: 0;

		.idp-show-link {
			background:none !important;
			border:none;
			padding:0!important;
			cursor: pointer;
		}
	}
}

@media screen and (min-width: 992px) {
	.schedule-settings-dialog .modal-dialog {
		width: 80%;
		font-size: var(--font-size-0_875rem);
	}
}


$warningPanelHeight : 30px;

.auto-save-info-panel {
	font-size: var(--font-size-default);
	height:30px;
	line-height:30px;
	margin:0;
	overflow:hidden;
	padding:0 10px;
	.close {line-height:inherit;}
	.edit & .close {
		margin-right: 40px;
	}
	.edit & + .br-gridster-container > .br-home-gridster { top:145px;}
	+ .br-gridster-container > .br-home-gridster { top:$warningPanelHeight;}
}

.concurrent-edit-warn-panel {
	right: $spacing-4;
	margin-top: $spacing-2;
	z-index: 100;
}


#scheduleSettings {
	[datepicker-popup-wrap] [ng-click="select(null)"] {
		display: none;
	}

	&.new-scheduler {
		&.schedules-1 { min-height:570px; }
		&.schedules-2 { min-height:620px; }
		&.schedules-3 { min-height:700px; }
	}
}

schedule-panel {
	> div {margin-bottom:$spacing-4;}
	.panel-body { min-height:400px; }


	$label-width : 100px;
	.label-left {
		width:$label-width; padding-right:$spacing-3;
	}

	.form-inline .label-left { margin-right:0; }
	.schedule-message-details { width:calc(100% - #{$label-width}); height: 100px}
	.schedule-email-body { min-height: 100px}

	.floating-label { line-height:30px; }
	.schedule-row-unlabeled {margin-left:$label-width;}

	.schedule-start-time [uib-datepicker-popup-wrap] {display:inline-block;}


	[mentio] {
		display: inline-block;
		width:calc(100% - #{$label-width});
	}

	.email-input-wrap {
		&, & + input { flex: 1 0 150px; }
	}


	// these keep elements aligned in case of wrapping
		.this-item-wraps { margin-left: $label-width }
		.next-item-wraps, .form-inline .form-group.next-item-wraps { margin-right: ($horizontal-spacing-between-input-groups - $label-width)}


	@media #{$media-query-mobile} {
		.label-left {
			float: none !important;
			display: block;
			flex: 1 0 100%;
			width: auto;
			margin-bottom: 2px;
			padding: 0;
		}

		.schedule-row-unlabeled { margin-left: 0;}
		.form-group { flex-wrap: wrap;}

		[mentio] { width: 100%; }
	}
}

.br-actions-dropdown .dropdown-submenu>a:after,
.br-settings-menu .dropdown-submenu>a:after {
	float:none;
	border-style: none;
	content: '';
}

.br-actions-dropdown {
	.menu-icon {
		margin-left: -1em;
		margin-right: .125em;
		font-size: 1.125rem;
		line-height: 1rem;

		&.right {
			right: 5px;
			left: auto;
		}
	}
}

.br-owner-warning {
	padding-top: 0;
}




.cx-date-filter-selector, .cx-date-range-selector {

	position:relative;

	.dropdown-toggle {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
}

.command-center-warning {
	font-weight: bold;
	color: var(--danger-600);
}

.cx-component-group {
	max-height: 250px;
	overflow-y: auto;
}

.edit filters-panel {
	display: block;
	position: relative;
	z-index: 100;
}
filters-panel>div {
	position: relative;
	z-index: 10;
}

.dashboard-list-content {

	.dashboard-list-header {
		z-index: 100;
	}

	.br-dashboard-title {
		width: 50%;
	}

	.dashboard-description {
		input {
			height:50px;
			width: 100%;
		}
	}

	.dashboard-tab-selection {
		line-height: normal;
		margin-top: $spacing-3;
		font-size: var(--font-size-default);

		// .alert-warning {
		// 	padding: 6px 20px;
		// 	height: 30px;
		// }
	}
}
book-editor {
	border-bottom-width: 1px;
	border-bottom-style: solid;
	.slick-row.dashboardSet, .slick-row.bookDisallowed {
		opacity: 0.25;
		pointer-events: none;
		cursor: default;
	}
}
.slick-row.hiddenItem, .slick-row.inactive, .ag-row.hiddenItem {
	opacity: $fade-opacity;
}

.dashboard-thumbnails {

	@media #{$media-query-mobile} {
		margin-top:50px;
	}
}

.dashboard-transfer .confirm-title {
	word-break: keep-all;
}

.dashboard-transfer .confirm-body ul {
	list-style: disc;
}

.br-dashboard-book-preview {
	position: absolute;
	width: 150px;

	.dashboard-preview-tab {
		position: absolute;
		top: -5px;
		height: 5px;
		width: 20px;
		border-width: 1px;
		border-style: solid;
		border-left:0;
		border-bottom:0;
	}
	.dashboard-preview-tab.active {
		border: none;
		top: -6px;
		height: 6px;
	}
}

.select-all-checkbox {
	padding-left:10px;
	position:absolute;
	top:calc(100% + 40px);
	z-index:2;

	@include hide-xs;
}

#dashboard-theme-toggle {
	button { float: none };
}

$book-header-height: $spacing-3 * 2 + $default-line-height * 16px; // top/bottom paddings + label

.header-padding {
	padding-top: $book-header-height !important;
}

.dashboard-pinned-header {
	height: $book-header-height + 8px; // 8 pixels to keep bottom shadow visible (other sides are truncated with overflow)
	overflow: hidden;
	position: absolute;
	z-index: 3;
	width: 100%;

	.mobile-layout & {
		position: static;
	}

	.header-shadow {
		height: $book-header-height;
		margin-top: -$book-header-height;
	}
}

$responsive-text-horizontal-padding: 16px;
$responsive-text-vertical-padding: 3px;
$responsive-text-font-size: var(--font-size-default);
$responsive-progress-height: 10px;
$responsive-progress-color: var(--gray-600);

$responsive-text-height: 24px;

.responsive-dashboard-footer {
	z-index: 3 !important;
	position: absolute;
	bottom: 0;
	width: 100%;
	height: $responsive-text-height;

	&.has-progress {
		height: $responsive-text-height + $responsive-progress-height;
	}

	.responsive-state {
		color: $white;
		height: $responsive-text-height;
		font-size: $responsive-text-font-size;
		font-weight: bold;

		.responsive-state-text {
			padding: $responsive-text-vertical-padding $responsive-text-horizontal-padding;
			background-color: $responsive-progress-color;
		}

		.view-results {
			text-decoration: underline;
		}
	}

	.responsive-progress {
		height: $responsive-progress-height;
		background-color: $responsive-progress-color;

		.progress-bar {
			background-color: $responsive-progress-color;
		}
	}
}

.dashboard-thumbnails {
	.scroll-carousel-button .btn {
		margin-top: 40px;
		font-size: var(--font-size-1_375rem);
	}
	.empty .scroll-carousel-button {
		visibility: hidden;
	}
}

.grid-2-col {
	display: grid;
	gap: 16px;
	grid-template-columns: 1fr 1fr;
}

.br-spot-check {
	.dropdown-menu {
		top: 114px;
		right: 0;
		position: fixed;
		bottom: 0;
		width: 716px;
		margin-left: auto;
		padding: 0;
		line-height: 1;
	}
}
