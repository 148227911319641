drill-breadcrumbs {
	position:relative;
	z-index:999;

	+ .br-widget-controller { height:calc(100% - 40px); }

	>nav { border-top:1px solid var(--gray-600); padding-top:$spacing-3; }
}

.nested-angle + .nested-angle { margin-left: -9px; }
