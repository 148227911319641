interaction-explorer {
    overflow-y: hidden;

    .interaction-explorer-container {
        height: 100%;
    }

    .interaction-explorer-wrapper {
        height: calc(100% - 24px);
    }

    .br-interaction-explorer-document-table {
        position: relative;
        display: flex;
        flex-direction: column;
        height: calc(100% - 40px);
    }

    .br-interaction-explorer-document-list {
        position: relative;
        height: 100%;
    }

    .br-interaction-explorer-pagination {
        position: absolute;
        bottom: 0;
        margin: 0 !important;
    }

    .br-interaction-explorer-grid {
        height: calc(100% - 50px);

        .slick-cell {
            height: 7.5em;

            .burger-wrap {
                top: 0.5rem;
            }
        }

        .label-bubble {
            font-size: var(--font-size-default);
            letter-spacing: 0.3px;
            line-height: 22px;
        }
    }

    .select-all-interactions {
        position: absolute;
        top: 6px;
        z-index: 10;
        left: 10px;
    }

    .br-list-box {
        height: 100%;
    }

    .remove-boundary .pagination-last {
        display: none;
    }

    .interaction-filter {
        $filter-height: 350px;

        .mixed-filter-builder-item {
            min-width: 600px;
            max-width: 1000px;
        }

        .filter-builder-wrapper {
            display: flex;

            .filter-builder, .saved-filters {
                display: flex;
                flex-direction: column;

                .filter-dropdown {
                    height: $filter-height;
                    overflow-y: auto;
                    overflow-x: hidden;
                }

                .filter-builder-controls {
                    display: flex;
                    justify-content: flex-end;
                }

                .filter-statement { flex-wrap: inherit; }
            }

            .filter-builder {
                justify-content: space-between;
            }

            .saved-filters {
                .scrolling-list {
                    max-height: $filter-height;
                    overflow-y: auto;
                }
            }
        }
    }

	.feedback-preview-icon + .feedback-preview-icon {
		margin-left: 8px;
	}
}

@media screen and (min-width : 768px) and (max-width:1399px) {
	.saved-filters { width: 200px !important; }

    interaction-filter .objects-filter .filter-dropdown { min-width: 600px; }
}
