@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@mixin rotate {
	animation-name:spin;
	animation-duration: 1.5s;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
}


.rotate, .rotate-infinite { @include rotate(); }


[class*=q-icon] {
	&.rotate, &.rotate-infinite {
		// gives the rotation a smoother/more stable look
		line-height: 1;
		&:before { @include rotate(); }
	}
}
