
$vertical-spacing-when-stacked: $spacing-4;

.selection-deselection-list {
	display: flex;
	flex: 1 1 auto;
	flex-direction: column;
	max-width: 45%;

	input {
		flex: 1 1 120px;
		min-width: 120px;		
	}

	select { 
		height:300px;
		overflow-y:scroll;
	}

	@media screen and #{$media-query-mobile-and-small} {
		max-width: none;
		margin-bottom: $vertical-spacing-when-stacked;

		select { height: 15em; }
	}

	.pinned-item {
		font-weight: bold;
	}
}

.filter-select-all { 
	@include flex;
	@include flex-multiline;
	@include flex-justify-separate;

	margin-left: (-1 * $spacing-3);
	>* { margin-left: $spacing-3; }
}

.selection-deselection-buttons {
	margin-bottom: $vertical-spacing-when-stacked;
	padding-right: $spacing-4;
	padding-left: $spacing-4;
	justify-content: center;
	display: flex;
	flex-direction: column;

	.vertical-orientation { display: none; }
		
	.btn {
		z-index: 2;
	
		// active btn layered higher so that enabled border shows all the way around btn
		&[disabled] { z-index: 1; }
	}	

	.btn+.btn {
		margin-left: 0;
		margin-top: -1px;
	}

	@media screen and #{$media-query-mobile-and-small} {
		flex-direction: row; 

		.btn+.btn { margin-left: $spacing-4; margin-top:0; }
		.vertical-orientation {
			display: flex;
			justify-content: center;;
		}
		.horizontal-orientation, &.move-selection-buttons { display: none; }
	}
}