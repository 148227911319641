// ag grid
:root {
	--ag-borders-critical: 1px solid;
	--ag-borders: 1px solid;
	--ag-font-size: var(--font-size-default);
	--ag-font-family: var(--font-standard);

	& .ag-theme-alpine, & .ag-theme-alpine-dark {
		--custom-ag-bg-color: var(--custom-bg-secondary, var(--white));

		--ag-odd-row-background-color: var(--custom-ag-bg-color);
		--ag-even-row-background-color: var(--custom-ag-bg-color);
		--ag-control-panel-background-color: var(--white);

		--ag-background-color: var(--custom-ag-bg-color);
		--ag-header-background-color: var(--custom-ag-bg-color);
		--ag-foreground-color: var(--default-text-color);

		--ag-selected-row-background-color: var(--action-200);		
	}
}



.ag-theme-alpine {
	font-family: var(--font-standard);
	font-size: var(--font-size-default);

	.ag-root-wrapper {
		border: none;
	}

	.ag-row {
		font-family: var(--font-standard);
		font-size: var(--font-size-default);
	}
}

// AG Grid has a bug - It shows horizontal scroll(on dashboard/metrics/filters templates) on init even though space is enough
.ag-center-cols-viewport {
	overflow-x: hidden !important;
}

.cell-dirty {
	background-color: var(--warning-200);
}
