#dashboardContainer {
    overflow: hidden;

    @media #{$media-query-mobile} { overflow:visible; }
}

.loaded #primary-container { transition:transform .3s;}

$side-nav-item-horizontal-padding: $spacing-5;

/* rebrand side navigation */
$main-sidebar-width: 280px;
#main-sidebar {
    height:100%;
    position:absolute;
    width:$main-sidebar-width;
    cb-clearable-input {
      button, .search {
        display: flex;
        align-items: center;
      }
  }
}

    @media #{$media-query-mobile} {
        #main-sidebar.show-main-sidebar {
            /* for animation purposes, just set this to some number that we never expect to reach */
            max-height:10000%;
        }
    }


section.show-main-sidebar {
    transform: translate($main-sidebar-width);

    @media #{$media-query-mobile} {
        transform: translate(0);
    }
}

#primary-container {
    height:100%;
    position:absolute;
    left:0;
    z-index: 1; // prevent content from this section from layering above modals

    &.br-login {
        &, &.show-main-sidebar { transition:none; left:0; }
    }

    @media #{$media-query-mobile} {
        padding:0;
    }
}

$home-height:115px;
$filters-compact-height:30px;

// .br-home-widgets {
//     background:$white;
//     box-sizing:border-box;
//     height: $home-height;
//     padding:20px 30px 10px;
//     position:relative;
//     z-index:index($dashboardLayers, add-widget-toolbar);
// }

// .br-home-widgets .br-dash-search-bar {
//     float: right;
//     margin-top: 7px;
// }

.dashboard-save-cancel { margin-top:-8px; }

.br-gridster-container .checkbox-inline {
    font-size: var(--font-size-default);
    line-height: 32px;
}

.br-home-gridster {
    position:absolute;
    top:0;
    bottom:0;
    right:0;
    left:0;
    background-color:var(--gray-600);
    overflow: auto;
    overflow-x: hidden;
    transition: all 0.5s ease;
}

.filters-dashboard-top {
    .br-home-gridster {
        top: $filters-compact-height;
    }
    &.edit .br-home-gridster {
        top: $filters-compact-height + $home-height;
    }
}

.book-tab-top-panel {
	.br-home-gridster {
		top: 0;
		padding-top: 20px;
	}
}

.pdf .br-home-gridster, .pdf-export .br-home-gridster {
	top: 0;
	overflow: hidden;
}

.br-gridster-actions {
    padding: 10px;
    background-color: $white;
    border-bottom: solid var(--gray-900) 1px;
}

.br-gridster-actions>label {
    font-size: var(--font-size-default);
    color: var(--gray-900);
    padding-right: 5px;
    line-height: 30px;
}


.gridster-preview-holder, .gridster-placeholder {
    background:none;
    border-style:dashed;
    border-width:2px;
    opacity:.7;
}

.gridster-preview-line {
    display: none;
    border-style: solid;
    border-width: 2px;
    opacity: 0.7;
    z-index: 4; // moving item has 3, need to be above it
    margin-top: -2px; // to center it due to border shift
}

.push-on-drop-active .gridster-item-moving {
    opacity: 0.7;
}
.widgetTypeElement {
	display: inline-block;
	overflow: hidden;
    margin-right:25px;
	cursor: move;
	white-space: nowrap;
}

.widgetTypeElement.ui-draggable-dragging {
    // transform:scale(1.4);

    .br-template-name {display:none;}
}

.br-widget-template-hover {
    display:none;
    font-size: var(--font-size-1_675rem);
    position:absolute;
    width:100%;
    top:calc(30% - .5em);

    .widgetTypeElement:hover &, .widgetTypeElement.ui-draggable-dragging & { display:block;}
}

.br-gridster-limit {
    position: absolute;
    left: 1%;
    right: 1%;
    font-size: var(--font-size-default);
}

.dashboard-header-tabs {
	height: auto;
	z-index: 1000;
	position: relative;
}

.dashboard-tab-view {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	display: none;

	&.active {
		display: block;
	}

	.dashboard-tab-message {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
	}

	#dashboardContainer {
		border-top-width: $standard-border-width;
	}

	filters-panel, .dashboard-edit-btn {
		z-index: 10;
	}

}

.copy-strike-through {
	@include strike-through;
	.can-copy-selected & {
		text-decoration: inherit;
	}
}

#home-page-title {
	h1 { line-height: 2; }
}

.custom-branding #home-page-title h1 { color: var(--custom-bg-primary-contrast) }

global-header {
	z-index: 1031;

	history-mode-header + & {
		z-index: auto;
	}
}
