


$table-alternate-row-color: var(--gray-100);
$table-hover-row-color: var(--action-200);
$table-border-color: var(--gray-400);

$swim-lane-color-1: #FEFF8F;
$swim-lane-color-2: #FFBF4F;
$swim-lane-color-3: #FFBFFC;
$swim-lane-color-4: #f00;

$sentiment-strong-positive: var(--pos-sentiment-800);
$sentiment-positive: var(--pos-sentiment-600);
$sentiment-neutral: var(--gray-500);
$sentiment-negative: var(--neg-sentiment-600);
$sentiment-strong-negative: var(--neg-sentiment-800);


/* shadows */

$box-shadow-offset-x: 0;
$box-shadow-offset-y: 2px;
$box-shadow-blur-radius: 4px;
$box-shadow-spread-radius: 0;
$box-shadow-color: var(--gray-200);

$standard-box-shadow: $box-shadow-offset-x $box-shadow-offset-y $box-shadow-blur-radius $box-shadow-spread-radius $box-shadow-color;













.color-inherit { color: inherit; }
.bg-none { background: transparent !important; }







$input-inactive-border-color: var(--gray-500);
// $input-focus-border-color: var(--gray-900); //using branding color, currently. see brand.scss
$input-placeholder-text-color: var(--gray-600);
$input-inactive-text-color: var(--gray-900);
$input-focus-text-color: var(--gray-900);
$input-disabled-text-color: inherit;
$input-disabled-bg-color: var(--gray-200);
$input-disabled-border-color: inherit;





/* buttons */
$button-selected-bg-color: var(--gray-900);
$button-selected-border-color: var(--gray-900);
$button-selected-text-color: $white;


$button-secondary-inactive-text-color: var(--gray-900);
$button-secondary-inactive-border-color: var(--gray-900);
$button-secondary-inactive-bg-color: $white;







$disabled-cursor: not-allowed;



/* overwrite bootstrap alert colors */
$alertTypes : (danger:var(--danger-700), warning:var(--warning-600), info:var(--gray-900), success:var(--success-600));
$messageStripTypes : (danger:var(--danger-200), warning:var(--warning-200), info:var(--action-200), success:var(--success-200));
$messageStripColors : (danger:var(--danger-900), warning:var(--warning-900), info:var(--action-900), success:var(--success-600));
