.item-list { max-height:60vh; overflow:auto; }

.grouping-selector {
	.asset-clear-button {
		padding-bottom: 2px;
		margin-left: 5px;
	}
}

grouping-selector.has-asset {
	width: calc(100% - 50px);
}

.alert-trigger-copy-to {
	float: right;
	width: 230px;
	> li { display: block; }
}

.tooltip-top {
	top: -9px !important;
}
