.br-grid {
	.br-rating-bar {
		width: 80px;
		display: inline-block;
		text-align: left;
		height: 100%;
	}

	.br-rating-count {
		display: inline-block;
	}

	.br-rating-star {
		margin: 1px;
	}

	.br-rating-bar.active {
		cursor: pointer;
	}
}
.br-rating-star {
	color: var(--warning-600);
}

.br-left-dashboard-items > i { margin-right: $spacing-3; }

.tile{
    border-bottom:1px solid $grid-border-color;
}

.tile .dashboard-name{
    width: calc(100% - 25px);
}

.mob-rating-bar {
  display: inline-block;
  vertical-align: middle;
}

$preview-height: 190px;
$preview-row-padding: $spacing-4;

.dashboard-preview-list {
	min-height:($preview-height + $preview-row-padding);
	overflow-y: auto;
	overflow-x: hidden;
	white-space:nowrap;

	> div { padding-bottom: $preview-row-padding; }
}

.dashboard-preview  {
	box-sizing:content-box;
	display: inline-flex;
	padding:0 $spacing-5;
	width:150px;

	dashboard-preview-image:first-child & { padding-left:0; };
}

.br-list-box.recent-favorite-dashboard-tabs {
	padding: $spacing-5 $spacing-5 ($spacing-5 - $preview-row-padding);
}



/* filter grid updates */

.grid-button-align { margin-top:10px; }

.dashboard-schedule-icon { line-height:16px; padding:12px 0; position:absolute; top:-1px; }

.scrollable-tabset {
	display: flex;
	flex-direction: column;
	@include flex-fill;
	.tab-content {
		display: flex;
		flex-direction: column;
		@include flex-fill;
		overflow: hidden;
	}
	.tab-pane {
		@include flex-fill;
		flex-direction: column;
		&.active {
			display: flex;
			overflow: hidden;
		}
	}
}
