sampled-audits {

    .select-all-sessions {
        font-size: var(--font-size-default);
        position: absolute;
        top: 9px;
        z-index: 2;
        left: 8px;
    }
    
}