/* use this file to set variables for various measurements like widths, heights, etc */
$baseline-measurement: 8px;

$standard-border-width: 1px;
$standard-border-radius: 4px;
$standard-grid-padding: $baseline-measurement;
$standard-border-color: var(--gray-400);
$standard-border: $standard-border-width solid $standard-border-color;

$double-standard-padding: (2 * $standard-grid-padding);
$double-padding: $double-standard-padding;

$half-standard-padding: ($standard-grid-padding * 0.5);
$half-padding: $half-standard-padding;


@mixin offset-margin($offsetDistance) {
  margin-left: (-1 * $offsetDistance);

    > * { margin-left: $offsetDistance }
}



/* margin/padding styles to make life easier */
$stops: (0,2px,4px,8px,16px,24px,32px,40px,48px);

// @each $stop in $stops {
//   .border-radius-#{index($stops, $stop) - 1} { border-radius: #{$stop} !important;}
// }


$spacingStops: length($stops);
@for $index from 2 through ($spacingStops - 2) {
  .l-margin-offset-#{$index - 1} {
    @include offset-margin(nth($stops, $index));
  }
}



$spacing-0 : nth($stops, 1);
$spacing-1 : nth($stops, 2);
$spacing-2 : nth($stops, 3);
$spacing-3 : nth($stops, 4);
$spacing-4 : nth($stops, 5);
$spacing-5 : nth($stops, 6);
$spacing-6 : nth($stops, 7);
$spacing-7 : nth($stops, 8);
$spacing-8 : nth($stops, 9);

// the width helpers are probably primarily for use in tables
@for $index from 1 through 10 {
  .width-#{$index} { width: $spacing-4 * ($index + 3)  }
}

.width-auto { width: auto !important; }


$panel-padding: $spacing-4;





$primary-header-height: 56px;
$impersonate-bar-height: 48px;










/* inputs */
$input-height: 2.5rem;

$input-padding-vertical: $spacing-3;  // we will subtract the width of the border from this number
$input-padding-horizontal: $spacing-3;

$input-padding: var(--input-padding);







/* buttons */
$button-height: 40px;
$button-sm-height: 36px; // should fix the filter add/remove button
$button-xs-height: 16px;
$button-spacing-between: $spacing-3;
$button-font-size: 16px;
$button-sm-font-size: $font-size-3;
$button-xs-font-size: $font-size-3;
$button-sm-h-padding: 12px;
$icon-button-width: 40px;



$css-animation-duration: .15s;

$fade-opacity: .3;
