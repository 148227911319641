// these are utility styles that are replaced in the unified-ui npm package in an upcoming version, as soon as we get there :)
// nothing else should go here, please.




.d-block { display: block !important; }



.overflow-y-auto { overflow-y: auto; }



.border-color-inherit { border-color: inherit !important}








/* to be replaced soon by a component in unified-angular */
.dropdown-item[badge]:before {
    height: 1.5em;
    margin-right: $spacing-3;
    content: attr(badge);
    display: inline-flex;
    color: var(--white);
    background: var(--gray-900);
    align-items: center;
    padding: $spacing-2;
    font-size: var(--font-size-default);
    font-weight: bold;
    text-transform: uppercase;
    border-radius: $spacing-1;
    padding-left: $spacing-3;
    padding-right: $spacing-3;
}

