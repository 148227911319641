$grid-border-color: var(--gray-200);
$grid-header-font-size: var(--font-size-default);
$grid-hamburger-size: $font-size-4;

.br-grid, .br-list-box {
	&, a { color: inherit; }
	.lighten-text { color: var(--gray-600); }
	.selected .lighten-text { color: var(--gray-200); }
}

.br-grid, .br-user-row {
	$sort-indicator-height: 5px;

	// right-aligned columns need some space for the sort arrow to appear
	.text-right {
		.slick-column-name { margin-right: $spacing-4; }
		.slick-sort-indicator { margin-left: #{$spacing-2 + (-1 * $spacing-4)} }
	}

	.slick-header-columns {
		border-bottom: 2px solid $grid-border-color;
	}

	.slick-header-column {
		border: 0;
		font-size: $grid-header-font-size;
		height: 40px;
		line-height:1;
		text-transform: capitalize;	// just in case
		padding-top: 13px;
		padding-bottom: 13px;

		// header hamburger needs slight adjustment for larger font size
		&.header-hamburger { padding-top: 12px; }
	}

	.slick-header-column-sorted { font-style:normal; }

	.slick-sort-indicator {
		margin-top:0;
		position:absolute;
		top:calc(50% - #{$sort-indicator-height * 0.5});
	}


	.q-icon-layer { font-size: $grid-hamburger-size; }
}

// .burger-wrap.q-icon-layer { font-size: $grid-hamburger-size }

.slick-header-column.slick-header-sortable {
	cursor: pointer;
}

$grid-row-v-padding: 0px;
$dropdown-cell-padding: 7px;
$column-h-padding: 0;
$target-row-height: 40px;
.br-grid .slick-cell {
	$font-size: var(--font-size-default);

	line-height: $target-row-height;
	font-size: $font-size;
	height: $target-row-height;
	padding: $grid-row-v-padding $column-h-padding;
	border-width: 0 0 1px;
	border-color: $grid-border-color;

	&.active {
		border-color: $grid-border-color;
		border-style: solid;
	}

	.br-folder.folder-name {
	    font-weight: 700;
	}

	.cell-title { padding-right: $spacing-7; }

	&.cell-dropdown.EDIT {
		padding: $dropdown-cell-padding $column-h-padding;

		.dropdown-cell-button {
			height: 25px;
			font-size: var(--font-size-default);
			padding-left: 6px;
			padding-top: 4px;

			width: 100% !important;
			max-width: fit-content;

			.value {
				width: 100%;
				text-overflow: ellipsis;
				display: inline-block;
				overflow-x: hidden;
				overflow-y: hidden;
				text-align: left;
			}

			.caret {
				display: inline-block;
    			margin-bottom: 4px;
			}
		}
	}
}

.br-folder[class*=q-icon] { width: 15px; margin-right: $spacing-2; }

.br-grid {

	.slick-column-name { font-weight:bold; }

	.slick-group { border-bottom: 2px solid $grid-border-color; }

	.slick-group-toggle {
		width: 9px;
		height: 9px;
		margin-right: 5px;
	}

	.slick-sortable-placeholder {
		background: var(--gray-200) !important;
	}

	.slick-row.loading {
		opacity: 0.5;
		filter: alpha(opacity = 50);
	}

	.slick-sort-indicator {
		float: none;
		margin-left: $spacing-2;
	}
}

.show-on-parent-hover, .show-on-parent-selected { visibility:hidden }
.visible { visibility: visible !important }

.hover-parent:hover, .hover-parent.focus-within, .hover-parent.hover {
	.show-on-parent-hover {
		visibility: visible;
	}
	.hide-on-parent-hover {
		visibility: hidden;
	}
	.show-border-on-parent-hover {
		border-color: var(--gray-900);
		color: var(--gray-900);
	}
}

// separate declaration so IE11 doesnt choke
.hover-parent:focus-within {
	.show-on-parent-hover {
		visibility: visible;
	}
	.hide-on-parent-hover {
		visibility: hidden;
	}
	.show-border-on-parent-hover {
		border-color: var(--gray-900);
		color: var(--gray-900);
	}
}

.selected {
	.show-on-parent-selected { visibility:visible;	}
}

$burger-height: 24px;
$burger-width: 24px;
.burger-wrap {
	@include flex-center;
	@include flex-justify-center;

	border:1px solid transparent;
	border-radius: $standard-border-radius;
	display:inline-flex;
	height: $burger-height;
	line-height: 1;
	padding:3px;
	text-align:center;
	width:$burger-width;


	.q-icon-layer {
	font-size: $grid-hamburger-size;
	padding: 8px;
	}

	&:hover {
		border-color: var(--gray-900);
		color: var(--gray-900);
		background: $white;
	}
}

.slick-row {
	&.selected .burger-wrap {
		background:$white;

		&:hover { background:none; }
	}

	.burger-wrap {
		position:absolute;
		right: calc(50% - #{$burger-width*0.5});
		top: calc(50% - #{$burger-height*0.5});
	}

	&.disabled .burger-wrap { display: none; }

	&.selected {
		.burger-wrap {
			border-color: var(--white);
			color: var(--white);
		}
	}

	&:hover {
		.burger-wrap {
			color: var(--default-text-color);
			border-color: var(--default-text-color);
		}
	}

	&.selected:hover {
		.burger-wrap {
			color: var(--white);
			border-color: var(--white);
		}
	}

	&.in-folder {
		.no-border-if-folder { border-bottom-color:transparent; }

		// add a top border to items that follow the last item in a folder
		+ :not(.in-folder) .no-border-if-folder {
			border-top: 1px solid $grid-border-color;
			padding-top: #{$grid-row-v-padding + 1px};
			margin-top: -1px;
			height: #{$target-row-height + 1px};
		}
	}
}

.br-grid:not(.scrollable).fix-port-size .slick-viewport {
	max-height: 2000px;
}

.br-grid:not(.scrollable) .slick-viewport {
	height: 100% !important;
	contain: content;
}

.br-grid.scrollable {
	.slick-viewport {
		overflow: hidden auto !important;
	}

	&.scrolled {
		.slick-header {
			box-shadow: 0 0 8px 0 var(--black-50-percent)
		}
	}
}

.folder-heading {
	padding: 5px;
	border-bottom: 1px solid $grid-border-color;


	h4 {
		margin: 0;
		padding: 0;
	}
}

.folder-expanded {
	&, .folder-collapsed {
		margin-bottom: 1px;
	}

	& .folder-content {
		display: block;
		padding-left: 20px;
	}

	& .folder-heading {
		background: $white;
		border-bottom: 1px solid $grid-border-color;
	}
}

.folder-expanded:first-child .folder-heading, .folder-collapsed:first-child .folder-heading  {
    border-top: 1px solid $grid-border-color;
}

.folder-collapsed .folder-content {
    display: none;
}

// buttons and filters at top

.grid-list-tools {
	display: flex;
	margin-bottom: $spacing-6;
	position: relative;
	@include flex-wrap;

	.tools {
		justify-content: space-between;
		margin-bottom: $spacing-3;
		flex: 1;
		@include flex-center;
		@include flex-multiline;
		align-items: flex-end;
	}
}

.grid-filters {
	@include flex-center;
	flex: 1;
	margin-right: $spacing-4;

	select { margin-right: $button-spacing-between; }

	.loading-spinner { margin-right: $spacing-2; }
	align-items: flex-end;
	.cb-select-cp {
		min-width: 9em;
	}
}

project-selector select { margin-right: $button-spacing-between; }

.grid-buttons {
	@include flex-center;
	@include hide-xs;
}

.grid-search-bar {
	flex: 1;
	max-width: 360px;
	min-width: 240px;
}

.grid-search-filter {
	margin-left: $button-spacing-between;
}

@mixin dropdown($minWidth) {
	.filter-dropdown {
		cursor: default;
		min-width: $minWidth * 1.5;
	}
}

.objects-filter {
	@include dropdown(550px)
}

.objects-sort {
	@include dropdown(600px)
}

.objects-filter-statement, .objects-sort-statement {
	.drag-filter {
		cursor: move;
		i {
			font-size: var(--font-size-default);
		}
	}
	.filter-value {
		width: 120px;
		social-cases-log-filter & {
			width: unset;
		}
	}
}

item-grid {
	display: block;


	button[class*=q-icon] {
		padding: 0;
		border: 0;
		background: transparent;
		color: inherit;
	}

	.br-list-box & a { color: inherit; }

	&:has(.cg-busy:not(.ng-hide)) {
		min-height: 72px;
	}
}
