.beta-features {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;	

	.beta-feature {		
		display: flex;
		flex: 1 1 15%;
		margin-right: $spacing-5;
		text-align:center;

		@media #{$media-query-mobile-and-small} { 
			flex-basis: 33%;
		}

		@media #{$media-query-mobile} {
			flex-basis: 100%;
		}

		.panel { flex: 1; }
	}
}