@mixin box-shadow($values) {
	box-shadow: $values;
}

@mixin no-select() {
	user-select: none
}


@function encodecolor($string) {
	@if type-of($string) == 'color' {
        $hex: str-slice(ie-hex-str($string), 4);
        $string:unquote("#{$hex}");
    }
    $string: '%23' + $string;
	@return $string;
}
