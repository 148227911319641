
#dsh-context-menu .searchable-hierarchy > .searchable-hierarchy-list {
	padding: 0;
}
#dsh-context-menu .searchable-hierarchy > .searchable-hierarchy-list .hierarchy-item {
	padding: $spacing-2 $spacing-3;
	line-height: 1;
	display: flex;
	align-items: center;
	height: 2em;
}


context-menu {
	// context-menu-option.expandable { padding-left: 0; }
	.expandable a { padding-left: $spacing-3 !important; }
	div.expandable {
		display: flex;
		align-items:center;

		// &:not(.no-children) {
		// 	padding-right: $spacing-3;
		// }

		> a { color: inherit; }
	}
}

context-menu-option {
    &, li, li a {
        display: flex;
        flex-direction: column;
        @include flex-fill;
        max-width: 100%;
    }
}

context-menu-option, copy-submenu {
	&.standalone {
		&, li, li a {
			display: flex;
			align-items: center;
			flex-direction: row;
		}

		.dropdown-submenu>a:after {
			display: inline;
			position: static;
		}
	}
}

.br-context-menu.interactions .menu-level-1 {
	max-width: 300px;
}
