// try not to use v-middle-wrapper
// use .flex.align-items-center instead
.display-table, .v-middle-wrapper {
	display: table;
	table-layout:fixed;

	&.row { width: calc(100% + 30px); }

	> .v-center, > .v-middle {
		display:table-cell;

		> * { vertical-align: middle; }
	}
}

.table-cell {
	display: table-cell;

	&.v-center, &.v-middle {
		> * { vertical-align: middle; }
	}
	&.v-top {
		> * { vertical-align: top; }
	}
	&.v-bottom {
		> * { vertical-align: bottom; }
	}
}
.v-center, .v-middle { vertical-align: middle; }
.v-top { vertical-align: top; }
.v-bottom { vertical-align: bottom; }



.input-height{ height: $input-height; } // just makes sure something is the same height as input fields


// borders
// .border { border: $standard-border; }
.no-round { border-radius: 0 !important; }
.border-round { border-radius: $standard-border-radius !important; }
.no-border {
	border: none !important;
	&:hover, &:focus {
		border: none !important;
	}
}
.border-transparent-soft {
	border-color: transparent;
}


$content-to-icon-spacing: $spacing-3;
$icon-to-edge-spacing: $spacing-3;
$icon-width: 1em;


@mixin icon-fixed {
	position: relative;

	[class*="q-icon"] {
		position: absolute;
		line-height: 1;
		top: calc(50% - .5em);
	}
}

@mixin icon-fixed-right {
	@include icon-fixed;

	padding-right: calc(#{$icon-width} + #{$content-to-icon-spacing + $icon-to-edge-spacing}) !important;
	[class*="q-icon"] { right: $icon-to-edge-spacing;	}
}

@mixin icon-fixed-left {
	@include icon-fixed;

	padding-left: calc(#{$icon-width} + #{$content-to-icon-spacing + $icon-to-edge-spacing}) !important;
	[class*="q-icon"] { left: $icon-to-edge-spacing;	}
}

.icon-fixed-right {	@include icon-fixed-right; }
.icon-fixed-left {	@include icon-fixed-left; }




// color inheritance or transparency
// do NOT put any hex colors here
.no-background, .no-background::-webkit-scrollbar-corner { background: transparent !important; }


// doesn't work if there are any blank spaces
.hide-if-empty:empty {
	display: none;
}

.ws-pre {
	white-space: pre;
}

.pointer-events-children-none > * {
	pointer-events: none;
}

.w-10-percent {
	width: 10% !important;
}

.w-8em { width: 8em !important; }
