$plot-band-column-width: 200px;
$plot-band-column-count: 3;

.reference-line-builder-dropdown {

  width: ($plot-band-column-width * $plot-band-column-count) + 10px;

  &.no-line-selected { width: auto; }

  svg { width: 100%; }

  .plot-line-column {
    float: left;
    width: $plot-band-column-width;
  }

  .close {
    right: $spacing-2;
    position: absolute;
    top: $spacing-2;
  }
}

plot-line-dropdown {
	.btn-plot-line-dropdown {
		plot-line-preview {
			width: 100%;
			overflow: hidden;
			display: inline-block;
		}
	}

	svg { height: 16px; }
}





reference-line-builder {
  .dropdown-toggle {
    max-width:100%;
  }
}
