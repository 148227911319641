:root {
	--conversation-turn-border-width: 2px;

    --bg-color-client: var(--white);
    --bg-color-agent: var(--white);
    --bg-color-bot: var(--white);

	--border-color-client: var(--gray-900);
	--border-color-agent: var(--blue-800);
	--border-color-bot: var(--aqua-800);

    --icon-color-client: var(--gray-900);
    --icon-color-agent: var(--blue-800);
    --icon-color-bot: var(--aqua-800);
}
