
.metric-neutral-box,.metric-top-box, .metric-bottom-box {
	box-shadow:inset 0 -1px 0 var(--black-50-percent);
	color:$white;
	float:left;
	font-size: var(--font-size-0_875rem);
	height:10px;
	height:20px;
	line-height:20px;
	text-align:center;
	width:33%;
}

.metric-neutral-box {
	background:var(--gray-500);
	color:$gray-900;
	width:66%;
	&.metric-neutral-full-options { width:33%;}
}

/* modal */
cb-color-grade-bar {
	.progress-bar {
		height: 20px;
	}
}

.grade-handler-wrap {
	background: var(--gray-600);
	top: 30px;
	width: 40px;
	height: 22px;
	z-index:1;

	&:hover {
		z-index:2;

		.add-remove-controls {
			box-sizing:content-box;
			margin-left:-20px;
			padding:3px 20px 10px;
		}

	}

	.grade-divider {
		top: -35px;
		height: 28px;
		background: var(--gray-400);
		opacity: 0.7;
		border-radius: 5px;
		width: 4px;
		left: calc(50% - 2px);
		position: absolute;
	}

	&.left-inclusive .grade-divider { left: calc(100% - 2px); }
	&.right-inclusive .grade-divider { left: calc(0% - 2px); }


	.input-arrow {
		left: 10px;
		top: -10px;
		z-index: 2;
		width: 20px;
		height: 10px;
		border: solid transparent 10px;
		border-top-width: 0;
		border-bottom-color: var(--gray-600);
	}

	.grade-handler-input input {
		width: 38px;
		height: 20px;
		margin: 1px;
		padding: 2px;
		font-size: var(--font-size-0_875rem);
		line-height: 14px;
		text-align: center;
	}
}

.select-all-metrics {
	font-size: $default-font-size;
	position: absolute;
	top: 9px;
	z-index: 2;
	left: 8px;
}

.slick-cell.cell-definition {
	padding-left: $spacing-3;
	padding-right: $spacing-3;
	display: flex;
	align-items: center;
}

.br-metric-dialog {
	tags-input { width:600px; }
}

.formula-input .error {
	font-family: monospace;
	border-bottom: 1px solid $danger-600-var;
}

.formula-input .warning {
	font-family: monospace;
	border-bottom: 1px solid $gray-700-var;
}

custom-math-editor {
	position: relative;

	.org-hierarchy-search-list {
		min-width: 146px;
	}

	.dropdown-menu li>a, .dropdown-menu li>a.dropdown-item {
		padding: 8px 16px;

		&.suggestion-with-submenu {
			padding: 8px 10px 8px 16px;
		}
	}

	.submenu-arrow {
		font-size: var(--font-size-0_75rem);
	}

	.aggregations-submenu {
		display: block;
	}
}

.math-button-bar {
	min-height: 40px;

	.btn { font-size: var(--font-size-0_875rem); }
}

.cb-input-arrow {
	z-index: 2;
	border: solid transparent 10px;

	.right-inclusive & {
		left: 0;
		top: -20px;
		border-left-width: 0;
	}

	.left-inclusive & {
		left: 30px;
		top: -20px;
		border-right-width: 0;
	}

	.right-endpoint & {
		left: 39px;
		top: 0;
	}

	.left-endpoint & {
		left: -20px;
		top: 0;
	}
}

.lane-container {
	position: relative;
	float: none;
	clear: both;
	margin: 0 0 $spacing-3 0;
	padding: #{$spacing-3 - $standard-border-width} 20px;
	border: solid var(--gray-600) $standard-border-width;
	border-radius: $standard-border-radius;
	width:100%;
	flex-wrap: wrap;

	.lane-name {
		height: 2.5rem;
		width: 25%;

		&.disabled {
			pointer-events: none;
		}
	}

	.lane-color {
		.color-text {
			width: 100px;
		}

		.colored-area.disabled {
			pointer-events: none;
		}
	}
}

.metric-color-grade {
	.color-grade-container.disabled {
		pointer-events: none;
		opacity: 0.5;
	}

	.progress-bar.disabled {
		pointer-events: none;
	}
}

.color-lane-header {
	padding: 0 20px;

	.show-buttons & {
		margin-right: 80px;
	}

	.name-label {
		width: 25%;
	}

	.color-label {
		width: 160px;
	}
}

.btn-clear-input {
	.lane-name & { display: none; }
	.lane-name:hover &:not(.disabled) { display: block; }
}

.math-object-tooltip {
	display: block;
	position: relative;
	max-width: 400px;
	left: -50%;
	width: fit-content;
	padding-top: 14px;
	padding-right: 16px;
	padding-left: 16px;
	padding-bottom: 18px;
	border-radius: 3px;
	box-shadow: 0 2px 10px 0 var(--black-50-percent);
	z-index: 9999;
}

.math-object-tooltip:before {
	content: ' ';
	height: 0;
	position: absolute;
	width: 0;
	top: -12px;
	left: calc(50% - 7px);
	border: 8px solid transparent;
}



@media screen and (max-width: 1200px) {
	color-grade-lane > div {
		align-items: flex-end;
	}

    .lane-threshold {
        flex-direction: column;
		span { margin-bottom: .5em }
    }
}
