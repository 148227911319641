$table-widget-border-color: $table-border-color;
$table-font-size: var(--font-size-0_875rem);

$standard-row-height: 32px;
$compact-row-height: 24px;
$total-vertical-border-width: 1px; // total of bottom border + top border

.br-widget-content {
	.slick-header-columns, .slick-header-column {
		border-bottom: 1px solid $table-widget-border-color;
		cursor: pointer;
	}

	.slick-header-column {
		border-width: 0 0 1px;
		font-size: var(--font-size-0_875rem);
		font-weight: 700;
		height: 40px;
		line-height: 40px;
	}

	.slick-header-columns .slick-header-column { border-bottom: 0; }

	.slick-headerrow-column {
		border-bottom: 0;
		height: 100%;
	}

	.slick-row {
		font-size: $table-font-size;
		line-height: 1;
		position: absolute;

		&.odd {
			border-top: 0;
			background: $table-alternate-row-color;
		}

		&.loading {
			opacity: 0.5;
			filter: alpha(opacity = 50);
		}

		&:hover { background: $table-hover-row-color; }
	}

	.slick-cell {
		padding: $spacing-2;
		border-width: 0;
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}

	.slick-group { border-bottom: 2px solid var(--gray-400); }

	.slick-group-toggle {
		width: 9px;
		height: 9px;
		margin-right: 5px;
	}

	.slick-cell.active {
		border-color: var(--gray-400);
		border-style: solid;
	}

	.slick-sort-indicator {
		float: none;
		margin-left: 10px;
	}
	.slick-header-column.ui-state-default{ padding:0; }
	div[id^="slickgrid_"],
	[id^="slickgrid_"],
	[class^="slickgrid_"] div,
	[class^="slickgrid_"] {
		-webkit-box-sizing: content-box;
		-moz-box-sizing: content-box;
		box-sizing: content-box;
	}
}





$first-column-left-padding: $spacing-3;

.slick-row .attribute_display_name {
	padding-left: $first-column-left-padding;
 }

 .slick-row .name {
	padding-left: $first-column-left-padding;
 }


 .br-attr-table {
	.slick-row {
		padding-top:0;
		padding-bottom:0;

		.r0 { padding-left: $first-column-left-padding; }
	}
}

.br-widget-view .slick-column-name {
	padding-left: $first-column-left-padding;
}

// small circle around icon
.icon-highlight {
	background: $white;
	border-radius: 100%;

	@include flex-center;
	@include flex-justify-center;
	@include flex-inline;

	// in some places we need to fill the space to maintain alignment, but there's no actual icon
	&.placeholder { background: transparent; }

	&.icon-highlight-small {
		height: 18px;
		width: 18px;
	}

	// some icons need a bit of finessing to align a centered
	.q-icon-thumb-down, .q-icon-twitter { position: relative; top:1px;}
	.q-icon-thumb-up { position: relative; top:-1px;}
}

.enrichment-pill-icon {position: relative; left: -3px;}

.widget-image-export .enrichment-pill .text-ellipsis {
    position: relative;
    z-index: 1;
}
