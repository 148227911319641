* {
    scrollbar-color: var(--gray-900) var(--gray-200);
    scrollbar-width: auto;
}

::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 16px;
}

::-webkit-scrollbar-thumb {
    background-color: var(--gray-900);
    margin-right: 1px;
    border: 3px solid var(--gray-200);
    border-radius: 20px;
}

::-webkit-scrollbar-track {
    background-color: var(--gray-200);
    -webkit-box-shadow: none;
    border-radius: 0;
}
