$horizontal-toolbar-height: 2.675rem;
$vertical-toolbar-width: 2.675rem;
$tile-square-side: 36px;
$toolbar-container-offset: 2.5em;
$drop-area-border: 8px;
$box-shadow-default: 0 0 8px rgba(0, 0, 0, 0.2);

@mixin horizontal-drop-area {
	height: $horizontal-toolbar-height;
	width: calc(100% - #{$vertical-toolbar-width * 2});
	margin-left: $vertical-toolbar-width;
	margin-right: $vertical-toolbar-width;
	z-index: 2;
}

.toolbar-drop-area {
	position: absolute;
	opacity: 0.9;
}

.top-drop-area {
	@include horizontal-drop-area;
	top: 0;
	border-top: $drop-area-border solid transparent;
}

.bottom-drop-area {
	@include horizontal-drop-area;
	bottom: 0;
	border-bottom: $drop-area-border solid transparent;
}

@mixin vertical-drop-area {
	width: $vertical-toolbar-width;
	height: inherit;
	z-index: 7;
}

.left-drop-area {
	@include vertical-drop-area;
	border-left: $drop-area-border solid transparent;
}

.right-drop-area {
	@include vertical-drop-area;
	right: 0;
	border-right: $drop-area-border solid transparent;
}

add-widget-toolbar {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}

.cx-add-widget-toolbar {
	box-sizing:border-box;
	position:fixed;
	z-index:index($dashboardLayers, add-widget-toolbar);
	width: 100%;
	opacity: 1;
}

.cx-add-widget-toolbar.dragging {
	opacity: 0.6;
}


.cx-add-widget-toolbar cb-drag-handle {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 2.5rem;
	width: 2.5rem;
	position: absolute;
	left: 0;
	font-size: var(--font-size-1_5rem);
}
.horizontal-toolbar {
	.cx-add-widget-toolbar {
		box-shadow: 1px 0 4px rgba(0, 0, 0, .5);
		cb-drag-handle { top: 0; }

	}

	.widget-tile {
		margin: 1px 0 0 0;
		line-height: $tile-square-side;
		text-align: center;
	}
	.template-tooltip-container {
		display:flex;
		flex-direction: row;
	}
}

.vertical-toolbar {
	.cx-add-widget-toolbar {
		z-index: 2;
		width: auto;
		box-shadow: 0 1px 4px rgba(0, 0, 0, .5);
	}
	cb-drag-handle {
		position: static;
		display: flex;
		justify-content: center;

		span { transform: rotate(90deg); }
	}
	.widget-tile {
		display: block;
		line-height: $tile-square-side;
		text-align: center;
	}
	.cx-widget-toolbar-container {
		right: 0;
		padding-right: 2px;
	}
	.template-tooltip-container {
		display:flex;
		flex-direction: column;
	}
}

.bottom-toolbar {
	.cx-add-widget-toolbar { bottom: 0; }
}

.top-toolbar {
	.cx-add-widget-toolbar {
		position: relative;
		display: block;
	}
}

.left-toolbar {
	.cx-add-widget-toolbar {
		left: 0;
		.cx-widget-toolbar-container {
			left: 1px;
		}
	}
}

.right-toolbar {
	.cx-add-widget-toolbar {
		right: 0;
		.cx-widget-toolbar-container {
			left: 0;
		}
	}
}

.cx-widget-toolbar-container {
	left:$toolbar-container-offset;
	position:relative;
	right:$toolbar-container-offset;
	top: 0;
}

.arrows-buttons {
	position: relative;
	z-index: 5;
	&.button-bottom.active {
		box-shadow: 0 -10px 8px -8px rgba(0,0,0,0.2);
	}
	&.button-top.active {
		box-shadow: 0 10px 8px -8px rgba(0,0,0,0.2);
	}
	&.disabled {
		opacity: .3;
	}
}

.widget-tile {
	font-size: var(--font-size-1_5rem);
	overflow: visible;

	.widget-name { display:none; }

	&:hover {
		.widget-name { display:table-cell; font-weight:bold; text-align:center; vertical-align:middle; width:100%; }
	}
	&:focus {
		outline: 2px solid var(--focus-outline-color);
		outline-offset: -2px;
	}

	/* need to make this one a little smaller than the others */
	&.page_break-widget { background-size:40%;}
}

.widget-tile.ui-draggable-dragging {
	border-radius:$standard-border-radius;
	font-size: var(--font-size-2_5rem);
	padding: .25em;
	height:auto;
	text-align:center;
	width:auto;
}

.cx-add-widget-toolbar .widget-tile {
	border-color:transparent;
	border-style: solid;
	border-radius:4px;
	border-width:1px;
	box-sizing: content-box;
	height: 2.5rem;
	width: 2.5rem;
	max-height: 2.5rem;
	max-width: 2.5rem;
	display: flex;
	align-items: center;
	justify-content: center;
	margin:0;

	&::after {
		content:'';
		position:absolute;
	}

	&:last-child::after {
		display:none;
	}
}

.cx-widget-icon-name {
	border-radius:3px;
	display:none;
	font-size:var(--font-size-0_75rem);
	padding:$spacing-3;
	position:absolute;
	top:calc(100% + 5px);
	z-index:index($dashboardLayers, widget-type-name);

	&::before {
		border-color:transparent;
		border-bottom-color:inherit;
		border-style:solid;
		border-width:0 5px 8px;
		content:'';
		height:0;
		left:10px;
		position:absolute;
		width:0;
	}
}

.filters-bar-shadow {
	height: 2.375rem;
	margin-top: -2.375rem;
	width: 100%;
	z-index: 3;
	position: absolute;
	box-shadow: 0 0 8px rgba(0, 0, 0, .5);
}