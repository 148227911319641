.tree-node {
	&.parent-node {
		.node-expand {
			display: none;
		}
		.node-collapse {
			display: block;
		}
		&.open {
			.node-expand {
				display: block;
			}
			.node-collapse {
				display: none;
			}
		}
	}
}

.vertical-tree {
	height: 100%;
	margin-bottom: -16px; // compensate additional reserved space at the bottom, see VerticalTreeRenderer::recalculateSize
}
