.schedules-page {
	.q-icon-search {
		float: left;
		margin: 10px;
	}
}

.schedule-runs-dialog {
	.schedule-runs-table {
		max-height: 400px;
		overflow: auto;
	}

	.schedule-row { border-bottom: 1px solid $standard-border-color; }
	.schedule-job-status.FAILED { color: var(--danger-600); }
}

.metric-alert-trigger-info {
	border: none;
	margin-left: 10px !important;
	margin-top: 6px !important;
}
