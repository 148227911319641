
// pagination is basically treated as a series of secondary buttons
// so it shares many variables


.pagination {
    >li>a, >li>span {
        background: none;
        color: var(--gray-900);

        &:hover, &:focus { background: none;}
	}
}
