$gradient-endpoint-input-width: 39px;

color-grade-bar {
	.progress, .progress-bar { @include box-shadow(none); }
}

.cell-progress-bar {
	.progress, .progress-bar { @include box-shadow(none); }
}

.gradient-endpoint {
	height: 20px;
	line-height: 18px;
	width: $gradient-endpoint-input-width;
	top: 0;

	&.left-endpoint { left: #{-1 * $gradient-endpoint-input-width}; }
	&.right-endpoint { right: #{-1 * $gradient-endpoint-input-width}; }

	> input {
		font-size: var(--font-size-0_875rem) !important;
		height: 100% !important;
	}
}

.gradient-input {
	width: $gradient-endpoint-input-width;
	height: 20px;
	margin: 0;
	padding: 2px;
	font-size: var(--font-size-0_875rem);
	line-height: 14px;
}

