.key-metric-list-items .searchable-hierarchy-list { height:170px; overflow:auto;}


/* until search is implemented */
	.key-metric-list-search { display:none;}
	.key-metric-list-items {border-top:1px solid $table-border-color; height:200px;}






// i think this is unnecessary
// .hierarchy-item.disabled .br-hierarchy-node-name { text-decoration:line-through; }



.draggable-items-table {
	.dragging { background:$white; opacity:.8; }
}

$grip-cell-width: 20px;
.grip-cell { position:relative; min-width: $grip-cell-width; width:$grip-cell-width; }

$table-widget-selections-horizontal-padding: $spacing-3;

.selected-metrics {
	width:100%;
	$target-height: $spacing-7;
	$font-size: $default-font-size;	

	$top-bottom-spacing: ($target-height * 0.5) - $font-size;

	tr { border-bottom:1px solid $table-border-color;} 
	thead tr { border-bottom-width:2px;} 
	> thead > tr > th, > tbody > tr > td { padding:($top-bottom-spacing - 1px) $table-widget-selections-horizontal-padding $top-bottom-spacing; }
}




.metric-rows {
	width:80px;
	>input { width: 72px; }
}

div.metric-rows {
	>input{width: 72px; }
}

.metric-min-volume {
	width:180px;
	>input { width:180px; }
}

.metric-options, .metric-remove { width: $icon-button-width + (2 * $table-widget-selections-horizontal-padding); }
.metric-options-pop { 
	.p-value {
		font-size: 125%;
		line-height: 0.6 !important;
	}
	.significance {
		line-height: 1.2 !important;
		font-size: 150%;
	}
}

.metric-defaults-checkbox { font-size:var(--font-size-0_875rem); padding-top:15px; }



searchable-hierarchy[disabled] {
	> * { opacity: .6; }
	* { cursor: default !important; }
	
	.hierarchy-item:hover {
		&, .action-color {
			background: inherit;
			color: inherit;	
		}	
	}
}


.searchable-hierarchy {
	> input + .searchable-hierarchy-list { border-top:0;}
	> .searchable-hierarchy-list { padding:$spacing-3;}
	.br-hierarchy-node-toggle {
		font-size: .85em;
		margin-right: $spacing-3;
	}
}


.searchable-hierarchy-list {
	height:250px;
	overflow:auto;

	> ul { padding-left:0;}
	ul ul{ padding-left:1em;}
	.dropdown-menu & { border: none; }
}

.hierarchy-folder { cursor:default; }




.dropdown-menu-hierarchy.dropdown-menu { width:100%; max-height:300px; overflow:auto; max-width: 500px;}
.dropdown-menu-hierarchy .searchable-hierarchy-list { border:0;height:auto; width:100%; }

select-from-hierarchy button > .button-text { 
	display:inline-block; 
	overflow:hidden; 
	text-overflow:ellipsis; 
	white-space:nowrap; 
}