:root{
	--cxs-brand-header-bg: var(--custom-bg-header, var(--white));

	--header-border-color: var(--gray-300);
}


.br-header {
	color: var(--default-text-color);
	background-color: var(--gray-100);
	border-color: var(--header-border-color);
}


.br-header-top {
	border-bottom-width:1px;
	border-bottom-style:solid;
	height: $primary-header-height;

	> ul, a { color:inherit; }
}

.versions-header, .preview-as-header {
	height: $primary-header-height;
	position: absolute;
	width: 100%;
	z-index: 1060;
}

%header-shadow { box-shadow: 0 0 8px rgba(0, 0, 0, .5); }

.page-content-header {
	height: 3.125rem; // 50px, 1rem is 16px by default
	box-sizing:border-box;
}
[class*="filters-bar"] { border-top:0;}




.header-shadow { @extend %header-shadow; }

.filters-bar {
	&, &.header-color-2 { border-top:$standard-border; }
	height:67px;
	padding:5px 15px 10px;

	li.not-shared > a {color:var(--gray-500); }
	li > a {color:var(--gray-900); }
	li.active > a {color:$white; }
	.option-chicklet {
		background:$white;
		border:1px solid var(--gray-500);
		border-radius: $standard-border-radius;
		margin-left:0;
	}

	multiselect .options-wrapper { max-height:75vh; }
}

.filters-bar-panel {
	padding-left: 15px;
	padding-right: 15px;
}

.filters-bar-compact-export {
	min-height: 30px;
	max-height: 45px;
	padding: 0 0 5px 15px;
        font-size: var(--font-size-default);
	color: var(--gray-900);
}

fullscreen-autorefresh-panel {
	position: relative;
	z-index: 1050;
}

.auto-refresh-box {
	$auto-refresh-top: 9px;

	position: fixed;
	top: $auto-refresh-top;
	left: 50%;
	transform: translateX(-50%);	
	z-index: 1000;

	.impersonation-mode & { top: $auto-refresh-top + $impersonate-bar-height }

	.time-bar-box {
		position: absolute;
		overflow: hidden;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 2px;
		font-size: 0;
		line-height: 0;
		text-align: center;
	}

	.time-bar {
		display: inline-block;
		overflow: hidden;
		height: 2px;
		background: var(--action-500);
		font-size: 0;
		line-height: 0;
		transition: width $css-animation-duration;
	}

	.dropdown-menu { width: 368px; }
}










.br-page-title {
	padding: 0 $spacing-4 0 0;
	display:flex;
	flex:1;
	flex-direction:row;
	align-items: center;
	justify-content: flex-start;

	.br-rating-bar {
		@include flex-fixed;
		margin-left: $spacing-4;

		@media #{$media-query-mobile} {
			display:none;
		}
	}

	.br-rating-star {
		color: var(--warning-500);
		margin: $spacing-1;
		font-size: var(--font-size-1_125rem);
	}
}


.br-dashboard-title-readonly {
	font-size: var(--font-size-1_675rem);
	overflow:hidden;
	text-overflow:ellipsis;
	vertical-align: middle;
	white-space:nowrap;
	max-width:100%;

	@media #{$media-query-mobile} {
		max-width:100%;
	}
}



.br-ma-selector .dropdown-menu {
	min-width: 240px;
	max-width: 320px;
	white-space:normal;
}

.br-ma-change-list {
	max-height:500px;
	overflow:auto;
	overflow-x: hidden;

	li>a {white-space:normal;}
}


.br-ma-logo {
	height:$primary-header-height;

	&.br-logo { display:inline-block; }

	@media #{$media-query-mobile} {
		width:150px;

		.br-logo {
			background-size:contain;
			width:100%;
		}
	}
}

.br-logo {
	display: block;
	padding: 0 5px;
	width:300px;
	height:100%;
	box-sizing: border-box;
	background-position: center;
}





.br-dashboard-nav, .br-dashboard-nav a { color:inherit; }



cb-spaces { border-color: var(--header-border-color); }
.br-header-right {
	height: $primary-header-height;
	border-color: var(--header-border-color);

	> * {
		border-color: inherit;
		display: flex !important;
		height: 100%;
	}

	.header-element {
		border-left:1px solid var(--header-border-color);
		border-color:inherit;
		display: flex !important;
		align-items: center;
		height: 100%;
		padding: 15px;
	}

	.dropdown-menu {
		right: 0;
		left: auto;
	}
}







.br-organization-panel {
	display: block;
	margin-right: 15px;

	.dropdown-header {
		margin: 0;
		margin-top: (-1 * $spacing-3);
		border-top-left-radius: inherit;
		border-top-right-radius: inherit;
	}
}

.br-defma-selector-panel .select2-container .select2-choice {
	min-width: 200px;
}








/* remove after Export PDF and Export All have icons applied */
.export_all .menu-icon {width:auto;}


.br-login-history-list {
	.table-scroll {
		max-height: 400px;
		overflow:auto;
	}

	.login-history-header {
		margin-bottom: 0;
		th { border-bottom-width: 1px; }
	}

	.cg-busy-animation { top: -20px; }
}


$main-header-height:70px;
$filter-expanded-height:67px;
$filter-compact-height:30px;

$fullscreen: ":-webkit-full-screen" ":-moz-full-screen" ":-ms-fullscreen" ":fullscreen";
@each $pseudoclass in $fullscreen {
	#{$pseudoclass} {
		primary-header, .hidden-fullscreen {
			display:none;
		}

		.header-compact .secondary-header {
			height: 0;
		}
	}
}

.br-user-props {
	ul.dropdown-menu {
		max-height: 300px;
		overflow-y: auto;
	}

	.br-dashboard-select a {
		min-height: 30px;
	}
}

.pin-slick-header {
	item-grid {
		padding-top: 42px; // compensating for slick header height
		.slick-header {
			position: fixed !important;
			z-index: 2;
			top: $primary-header-height;
			left: 48px;
			right: 56px;
			width: auto;
		}
		&.br-filter-grid, &.cx-admin-grid {
			.slick-header {
				right: 88px;
			}
		}
	}
	.select-all-checkbox, .select-all-drivers, .select-all-filters, .select-all-metrics {
		position: fixed;
		top: $primary-header-height;
		left: 48px;
		padding-top: 10px;
		padding-left: 10px;
		z-index: 3;
	}

	.impersonation-mode & {
		item-grid .slick-header,
		.select-all-checkbox,
		.select-all-drivers,
		.select-all-filters,
		.select-all-metrics { top: $primary-header-height + $impersonate-bar-height; }
	}
}


.command-palette-wrap { flex: 0 1 900px; }
.command-palette-wrap-2 { flex: 0 1 850px; }

#profile-button {
	border: none;
	background: none;
	padding-left: 0;
	padding-right: 0 !important;
}

// ensure that header menu layers over widget-level spinners
.z-index-header-dropdown {
	z-index: 1050;
}
