.ng-right-click-ul{
	min-width: 160px;
	z-index: 999;
	cursor: pointer;
	padding: 0;
	position: absolute;
	top: 80%;
	list-style: none;
	margin-left:0;
	border-left: 10px solid transparent;
}

.ng-right-click-ul li{
	padding: 5px;
	cursor: pointer;
	margin-left: 5px;
	font-size: var( --font-size-default);
	color: var(--gray-900);
	border: 1px solid var(--gray-600);
	box-shadow: 0 6px 12px var(--black-50-percent);
	background-color: $white;
}
