
// these values are chosen pretty arbitrarily at this point
// we may need to adjust

@function max-width-calculator($multiplier) {
	@return (24 * $multiplier * $spacing-3);
}

@for $index from 1 through 5 {
	.max-width-#{$index} { max-width: max-width-calculator($index) }
	.min-width-#{$index} { min-width: max-width-calculator($index) }
}