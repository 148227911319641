.btn-danger {
	--button-background: var(--danger-700);
	--button-border-color: var(--danger-700);
	--button-text-color: var(--white);

	--button-active-background: var(--danger-500);
	--button-active-text-color: var(--white);

	--button-hover-background: transparent;
	--button-hover-text-color: var(--danger-700);
	--button-hover-border-color: var(--danger-700);
}

.btn-success {
	--button-background: var(--success-700);
	--button-border-color: var(--success-700);
	--button-text-color: var(--white);

	--button-active-background: var(--success-500);
	--button-active-text-color: var(--white);

	--button-hover-background: transparent;
	--button-hover-text-color: var(--success-700);
	--button-hover-border-color: var(--success-700);
}

.btn-warning {
	--button-background: var(--warning-700);
	--button-border-color: var(--warning-700);
	--button-text-color: var(--white);

	--button-active-background: var(--warning-500);
	--button-active-text-color: var(--white);

	--button-hover-background: transparent;
	--button-hover-text-color: var(--warning-700);
	--button-hover-border-color: var(--warning-700);
}

.btn-icon:not(.btn-selected) {
	--button-background: transparent;
	--button-text-color: var(--gray-1000);
	--button-border-color: transparent;

	--button-hover-background: var(--action-100);
	--button-hover-text-color: var(--action-800);
	--button-hover-border-color: transparent;

	--button-focus-background: var(--action-100);
	--button-focus-text-color: var(--action-900);
	--button-focus-border-color: transparent;

	--button-active-background: var(--action-100);
	--button-active-text-color: var(--action-900);
	--button-active-border-color: transparent;
}

.btn-secondary, .btn-default {
	--button-background: transparent;
	--button-text-color: var(--action-700);
	--button-border-color: var(--action-700);

	--button-hover-background: var(--action-100);
	--button-hover-text-color: var(--action-800);
	--button-hover-border-color: var(--action-800);

	--button-focus-background: var(--action-100);
	--button-focus-text-color: var(--action-900);
	--button-focus-border-color: var(--action-900);

	--button-active-background: var(--action-100);
	--button-active-text-color: var(--action-900);
	--button-active-border-color: var(--action-900);
}

.btn-selected, .btn.active {
	--button-background: var(--action-900);
	--button-text-color: var(--white);
	--button-fill-color: var(--gray-200);
	--button-border-color: var(--action-900);

	--button-focus-background: var(--button-hover-background);
	--button-focus-border-color: var(--button-hover-border-color);
	--button-focus-text-color:var(--button-hover-text-color);
}

.btn-default {
	--button-border-color: var(--gray-900);
	--button-hover-border-color: var(--gray-900);
	--button-focus-border-color: var(--gray-900);
	--button-active-border-color: var(--gray-900);
}

.btn-group .btn:not(.btn-selected):not(.btn-secondary) {
	--button-border-color: var(--gray-900);
	--button-hover-border-color: var(--button-border-color);
	--button-hover-background: var(--action-100);
    --button-hover-text-color: var(--action-900);
}



// TODO: expand to apply to all buttons
// eventually may replace these selectors with just button or .btn
.btn-group .btn,
.btn-secondary, .btn-icon, .btn-selected, .btn.active, .btn-danger, .btn-success, .btn-warning {
	background-color: var(--button-background);
	border-color: var(--button-border-color);
	color: var(--button-text-color);
	fill: var(--button-fill-color, var(--button-text-color));

	&:hover {
		border-color: var(--button-hover-border-color, var(--button-border-color));
		background-color: var(--button-hover-background, var(--button-background));
		color: var(--button-hover-text-color, var(--button-text-color));
		fill: var(--button-hover-text-color, var(--button-fill-color, var(--button-text-color)));
	}

	&:focus {
		border-color: var(--button-focus-border-color, var(--button-border-color));
		background-color: var(--button-focus-background, var(--button-background));
		color: var(--button-focus-text-color, var(--button-text-color));
		fill: var(--button-focus-text-color, var(--button-fill-color, var(--button-text-color)));
	}

	&:active {
		border-color: var(--button-active-border-color, var(--button-border-color));
		background-color: var(--button-active-background, var(--button-background));
		color: var(--button-active-text-color, var(--button-text-color));
		fill: var(--button-active-text-color, var(--button-fill-color, var(--button-text-color)));
	}
}
