
/********** COMMON **********/
body, div, span, table, td, h1, h2, h3, h4, h5, h6, p {
	font-family: var(--font-standard);
}


menu { list-style: none; }




















.scrolling-list {
	max-height: 300px;
	overflow-y: auto;
}

/********** FORM **********/



.mandatory label, label.mandatory {
	&:after{ content: "*"; }
}


.group-label { font-weight: bold; }




/* TREE MAP */

.node {
	border-left: solid 1px var(--white);
	border-top: solid 1px var(--white);
	font: 10px var(--font-standard);
	line-height: 12px;
	overflow: hidden;
	position: absolute;
	text-indent: 2px;
}


.step-chart .y-label {
	fill: var(--gray-600);
	font-size: var(--font-size-0_75rem);
}

.step-chart .y-marker {
	stroke: var(--gray-600);
	stroke-width: 0.5px;
}

.main-content {
  position: relative;
  min-height: 100%;
}

.text-small {
  font-size: 0.8em;
}

.subdued {
  color: var(--gray-900) !important;
}

.child-wrap {
  overflow: hidden;
}

.tile-data-group{
	margin-top: 3px;
}
.tile-icon-group {
	margin-top: 3px;
	vertical-align: middle;

	.views { margin-top: 3px; }
	.dropdown .dropdown-menu {
		position: static;
		float: none;
	}
}

.tile-name-group .dropdown .dropdown-menu{
	position: static;
	float: none;
}
.rating-bar{
	width: auto;
}



.rating-star { color: var(--warning-600); }



.grayscale {
    /* Firefox 10+, Firefox on Android */
    filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#grayscale");

    /* IE 6-9 */
    filter: gray;

    /* Chrome 19+, Safari 6+, Safari 6+ iOS */
    filter: grayscale(100%);
}
