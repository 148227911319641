.experimental-ui {
	.br-widget-title {
		font-size: var(--font-size-1_125rem);
	}
	.br-widget-total, .br-widget-footer {
		font-size: var(--font-size-default);
	}
	.preview-pagination-container .pagination-page {
		>a, >span {
			font-size: var(--font-size-default);
		}
	}
}
