grid-context-menu {
    .grid-columns-menu {
        width: 300px;

        .scrollable-options {
            max-height: 300px;
            overflow-y: auto;

            .p-sticky { 
                top: 0;
                z-index: 1;
            }
        } 
    }
}