$panel-width: 375px;

.docked-panel {
    top: 40px;
    width: $panel-width;

    &:not(.popped-out){
        right:(-1 * $panel-width) !important;  
    }

    &.floating-widget {
        transition: none;
    }

    &.popped-out {
        right:0;
        z-index:index($dashboardLayers,popout-panel);
        
        /* if there are multiple floating widgets, the one you're currently dragging should get the top position */
        &.top-floating-widget { 
            z-index:index($dashboardLayers,active-popout-panel);
        }
    }    

    .panel {
        margin-bottom:0;
    }
}





.docked-trigger, .docked-panel {
    position:absolute;
    z-index: index($dashboardLayers,docked-panel);
}

.docked-trigger, .docked-panel, .docked-trigger .btn {
    transition: all 0.5s ease;
}



.docked-trigger {
    height: 15px;
    margin-top:15px;
    width:150px;
    overflow:hidden;
    transform: rotate(-90deg);
    transform-origin: 0 100%;

    .btn {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
}

.docked-trigger:hover, .popped-out .docked-trigger { height:30px; margin-top:0;}

.docked-trigger:hover .btn, .popped-out .docked-trigger  .btn {
    padding: 7px 16px;
    font-size:inherit;
    line-height:1em;
    height:30px;
    font-size: var(--font-size-default);
}

.docked-trigger:hover .trigger-text, .popped-out .docked-trigger .trigger-text {
    font-size:var(--font-size-0_875rem);
}
    
    
.docked-trigger .btn {
    display: block;
    font-size: var(--font-size-0_875rem);
    line-height:12px;
    padding:1px;
}
    
.docked-trigger .trigger-text { font-size:0; }




.br-versions-panel {
    >.docked-trigger { top:215px; }
    .panel-body { overflow:auto; }

    .br-version-item {
        label { cursor: pointer; }
        &.selected label { cursor: default; }
    }
}
