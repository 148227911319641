inline-help {
	position:relative;

	&:hover { z-index: 1051; } // 1051 layers inline-help above dropdown menu

	&:focus {
		.inline-help {
			outline: solid 2px var(--focus-outline-color);
		}
	}
}
