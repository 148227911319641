table-menu {
    display: flex;
    justify-content: space-between;
    height: 48px;
    background: var(--gray-200);
    border-radius: 2px;
    align-items: center;

    .items-selected {
        padding: 8px;
    }

    .options {
        display: flex;
        align-items: center;

        .option {
            padding: 0px 16px;
            text-align: center;
            vertical-align: middle;

            &.separated {
                border-left: 2px solid var(--gray-300);
            }
        }

        .dropdown-submenu {
            margin-right: 16px;

            >.dropdown-menu {
                top: 100%;
                left: auto;
                right: 0;
                margin: 0;
                max-width: 300px;
            }

            >a {
                min-height: auto;
            }
        }
    }
}
